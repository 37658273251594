import React, { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Route } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import useError from '@common/hooks/useError';
import { useSelector } from '@common/hooks/useSelector';
import ApplicationRouter from '@common/router/ApplicationRouter';
import { FlowSteps } from '@common/services/application';
import { ApiError, PageNotFoundError } from '@common/utils';
import { ErrorBoundaryPage } from '@monefit-ee/pages';

// TODO: add all flow steps used in monefit-ee application flow once pages are created for them
const FLOW_STEPS: FlowSteps[] = [
  FlowSteps.IDENTIFICATION,
  FlowSteps.EMAIL,
  FlowSteps.EMAIL_OTP,
  FlowSteps.ADDRESS,
  FlowSteps.FINANCES,
  FlowSteps.BANK,
  FlowSteps.DECLINED,
  FlowSteps.WITHDRAWAL,
  FlowSteps.DOCUMENTS,
  FlowSteps.PHONE,
  FlowSteps.PHONE_OTP,
  FlowSteps.END,
  FlowSteps.REDIRECT,
  FlowSteps.DECISION,
  FlowSteps.VALIDATION,
];

const Router: FC = () => {
  const { error, loading, data } = useSelector((st) => st.application.flowInstance);
  const { allowedLocales } = useLocale();
  const pageNotFoundError = useMemo(() => new PageNotFoundError(), []);
  const apiError = useMemo(() => (error ? new ApiError() : null), [error]);
  const { errors, notifyInstanceErrors, notifyApiError } = useError();

  if (!allowedLocales) {
    // Show blank page with loading message until allowedLocales (or fallback locales) are defined
    return (
      <FormattedMessage defaultMessage="Loading..." description="ErrorBoundaryPage: Loading" />
    );
  }

  if (errors.length && !loading) {
    notifyInstanceErrors();
  }

  if (apiError && !errors.length && !loading) {
    notifyApiError();
  }

  if (!data?.currentStepId && apiError && !loading) {
    return <ErrorBoundaryPage />;
  }

  return (
    <ApplicationRouter flowSteps={FLOW_STEPS}>
      <Route path="*" element={<ErrorBoundaryPage error={pageNotFoundError} />} />
    </ApplicationRouter>
  );
};

export default Router;
