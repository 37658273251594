import { useEffect, useState } from 'react';

import useApp, { AppId } from './useApp';
import useCountry, { Country } from './useCountry';

export enum SubAppId {
  MONEFIT_EE = 'monefit-ee',
  MONEFIT_ES = 'monefit-es',
  CREDITSTAR_ES = 'creditstar-es',
}

const useSubApp = () => {
  const { country } = useCountry();
  const { appId } = useApp();
  const [subAppId, setSubAppId] = useState<SubAppId | null | undefined>(undefined);

  useEffect(() => {
    if (appId === AppId.MONEFIT) {
      switch (country) {
        case Country.EE:
          setSubAppId(SubAppId.MONEFIT_EE);
          break;
        case Country.ES:
          setSubAppId(SubAppId.MONEFIT_ES);
          break;
        default:
          setSubAppId(null);
      }
    } else if (appId === AppId.CREDITSTAR) {
      switch (country) {
        case Country.ES:
          setSubAppId(SubAppId.CREDITSTAR_ES);
          break;
        default:
          setSubAppId(null);
      }
    }
  }, [country, appId]);

  return { subAppId };
};

export default useSubApp;
