import { useState } from 'react';

import InputLabel from '@common/components/input-label/InputLabel';
import { WidgetProps } from '@rjsf/utils';

const TextWidget = (props: WidgetProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <input
        className={`h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold 
        placeholder:font-normal placeholder:text-gray-400 focus:outline-none ${
          props.label ? 'pt-3' : ''
        } `}
        disabled={props.disabled}
        {...props}
        onChange={(e) => props.onChange(e.target.value)}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          props.onBlur(props.id, props.value);
        }}
        placeholder={props.label ? '' : props.placeholder ?? ''}
      />
      {!!props.label && <InputLabel text={props.label} shouldMinimize={isFocused || props.value} />}
    </div>
  );
};

export default TextWidget;
