import { default as Skeleton } from './Loader.Skeleton';
import { default as Card } from './LoadingCard';
import { default as Indicator } from './LoadingIndicator';
import { default as Main } from './MainLoader';

const Loader = {
  Card,
  Indicator,
  Main,
  Skeleton,
};

export default Loader;
