import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  backButtonTextStart: {
    defaultMessage: 'How much would you like to pay',
    description: '@monefit-es How much would you like to pay',
  },
  backButtonTextConfirmation: {
    defaultMessage: 'Pay invoice',
    description: '@monefit-es Pay invoice',
  },
  backButtonTextCheckout: {
    defaultMessage: 'Choose payment method',
    description: '@monefit-es Choose payment method',
  },
});
