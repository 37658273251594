import { FC, useMemo } from 'react';

import { getClassNames } from './FieldLabel.classes';

export interface FieldLabelProps {
  text: string;
}

export const FieldLabel: FC<FieldLabelProps> = ({ text }) => {
  const classNames = useMemo(() => getClassNames(), []);

  return <span className={classNames.label}>{text}</span>;
};
