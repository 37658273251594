import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ImageCard from '@common/components/image-card/ImageCard';
import useIntercomChat from '@common/hooks/useIntercomChat';
import monthioCardImg from '@src/assets/img/monefit-es/monthio-card.webp';

import m from './BankStatementPendingCardMessages';

const BankStatementPendingCard = () => {
  const { formatMessage } = useIntl();
  const { openChat } = useIntercomChat();
  return (
    <ImageCard
      imageSrc={monthioCardImg}
      smHeightClassName="sm:h-[500px]"
      lgHeightClassName="lg:h-[500px]"
      maxWClassName="max-w-[900px]"
    >
      <div className="flex size-full flex-col justify-normal gap-8 sm:justify-between sm:gap-6">
        <div className="font-heavy self-stretch text-4xl font-black leading-none text-black sm:leading-tight">
          {formatMessage(m.pendingBoldText)}
        </div>
        <div>{formatMessage(m.pendingText)}</div>
        <Button text={formatMessage(m.chatWithUs)} onClick={() => openChat()} />
      </div>
    </ImageCard>
  );
};

export default BankStatementPendingCard;
