import { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';
import { Control, UseControllerProps, useController, useFormContext } from 'react-hook-form';

import { FieldLabel } from '@common/components/field-label/FieldLabel';
import InputLabel from '@common/components/input-label/InputLabel';
import clsx from 'clsx';

import { DateInputField } from './DateInput.enums';

export interface DateInputProps extends UseControllerProps {
  label?: string;
  placeholders?: {
    day?: string;
    month?: string;
    year?: string;
  };
  messages?: {
    dayPlaceholder?: string;
    monthPlaceholder?: string;
    yearPlaceholder?: string;
    dayLabel?: string;
    monthLabel?: string;
    yearLabel?: string;
  };
  control?: Control;
}

const DateInput: FC<DateInputProps> = ({
  name,
  control,
  rules,
  defaultValue = '',
  disabled = false,
  messages = {
    dayPlaceholder: '',
    monthPlaceholder: '',
    yearPlaceholder: '',
    dayLabel: '',
    monthLabel: '',
    yearLabel: '',
  },
  label,
}) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');

  const {
    trigger,
    formState: { isSubmitting },
  } = useFormContext();

  const handleFieldChange = useCallback((fieldName: DateInputField, value: string) => {
    if (isNaN(Number(value))) {
      return;
    }

    switch (fieldName) {
      case DateInputField.DAY:
        setDay(value);
        break;
      case DateInputField.MONTH:
        setMonth(value);
        break;
      case DateInputField.YEAR:
        setYear(value);
        break;
    }
  }, []);

  const {
    field: { value, onChange, onBlur, ...registration },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  const renderField = useCallback(
    (fieldName: DateInputField) => {
      const maxLength = fieldName === DateInputField.YEAR ? 4 : 2;

      let placeholder;
      let label;
      let value = defaultValue ?? '';

      switch (fieldName) {
        case DateInputField.DAY:
          label = messages.dayLabel;
          placeholder = messages.dayPlaceholder;
          value = day;
          break;
        case DateInputField.MONTH:
          label = messages.monthLabel;
          placeholder = messages.monthPlaceholder;
          value = month;
          break;
        default:
          label = messages.yearLabel;
          placeholder = messages.yearPlaceholder;
          value = year;
          break;
      }

      const fieldClassNames = clsx(
        'h-16',
        'w-full',
        'rounded-xl',
        'border',
        'border-gray-300',
        'px-7',
        'font-semibold',
        'placeholder:font-normal',
        'placeholder:text-gray-400',
        'focus:outline-none',
        label && 'pt-3'
      );

      return (
        <>
          <input
            type="text"
            className={fieldClassNames}
            disabled={isSubmitting || disabled}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              handleFieldChange(fieldName, e.target.value)
            }
            onBlur={() => {
              trigger(name);
            }}
            maxLength={maxLength}
            value={value}
            placeholder={placeholder}
            {...registration}
          />
          {!!label && <InputLabel name={fieldName} text={label} shouldMinimize={!!value.length} />}
        </>
      );
    },
    [
      defaultValue,
      isSubmitting,
      disabled,
      registration,
      messages,
      day,
      month,
      year,
      handleFieldChange,
      trigger,
      name,
    ]
  );

  useEffect(() => {
    onChange(year && month && day ? `${year}-${month}-${day}` : '');
  }, [day, month, onChange, year]);

  useEffect(() => {
    if (defaultValue) {
      const [year, month, day] = defaultValue.split('-');
      setYear(year);
      setMonth(month);
      setDay(day);
    }
  }, [defaultValue]);

  return (
    <div className="relative">
      {!!label && <FieldLabel text={label} />}

      <div className="flex w-full flex-row gap-4">
        <div className="relative">{renderField(DateInputField.DAY)}</div>
        <div className="relative">{renderField(DateInputField.MONTH)}</div>
        <div className="relative">{renderField(DateInputField.YEAR)}</div>
      </div>

      {error?.message && (
        <div className="mt-2 text-xs font-semibold text-rose-400">{String(error.message)}</div>
      )}
    </div>
  );
};

export default DateInput;
