import { FC, JSX } from 'react';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';

import ErrorProvider from '@common/components/error-provider/ErrorProvider';
import { useLocale } from '@common/hooks';
import useMessages from '@common/hooks/useMessages';
import { store } from '@common/redux';
import Router from '@creditstar-es/router/Router';

const App: FC = (): JSX.Element | null => {
  const { locale } = useLocale();
  const { messages, messagesLoading } = useMessages();

  if (!messages || messagesLoading) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <Provider store={store}>
        <ErrorProvider>
          <div data-testid="creditstar-es-app">
            <Router />
          </div>
        </ErrorProvider>
      </Provider>
    </IntlProvider>
  );
};

export default App;
