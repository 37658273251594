import { FieldTemplateProps } from '@rjsf/utils';
import classNames from 'classnames';

const FieldTemplate = (props: FieldTemplateProps) => {
  const { description, id, help, style, errors, children } = props;
  const touchedFields = props?.uiSchema?.['ui:touched'];

  const isTouched = touchedFields?.[id] ?? false;
  return (
    <div className={classNames(props.classNames)} style={style}>
      {!!description && <div className="text-lg">{description}</div>}
      {!!help && <div className="my-2 text-sm text-neutral-500">{help}</div>}
      <div className="w-full">{children}</div>
      {isTouched && <div className="mt-2 w-full text-xs font-semibold text-rose-400">{errors}</div>}
    </div>
  );
};

export default FieldTemplate;
