import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  standard: {
    defaultMessage: 'Standard',
    description: '@monefit-es Standard',
  },
  subscription: {
    defaultMessage: 'Credit+',
    description: '@monefit-es Credit+',
  },
  included: {
    defaultMessage: 'What’s included:',
    description: '@monefit-es What’s included:',
  },
  bestValue: {
    defaultMessage: 'Best value',
    description: '@monefit-es Best value',
  },
  withdrawText: {
    defaultMessage: 'Withdraw up to {amount} €',
    description: '@monefit-es Withdraw up to {amount} €',
  },
  percentageText: {
    defaultMessage: '{percentage}% p.a.',
    description: '@monefit-es {percentage}% p.a.',
  },
  buttonTextStandard: {
    defaultMessage: 'Choose Standard',
    description: '@monefit-es Choose Standard',
  },
  buttonTextSubscription: {
    defaultMessage: 'Choose Credit+',
    description: '@monefit-es Choose Credit+',
  },
  subscriptionFeeText: {
    defaultMessage: '{fee} € monthly subscription applies',
    description: '@monefit-es {fee} € monthly subscription applies',
  },
  termsText: {
    defaultMessage: 'Subscription terms and conditions',
    description: '@monefit-es Subscription terms and conditions',
  },
});
