import { FC, useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import { useDispatch, useLocale, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import {
  selectActiveAgreement,
  selectBalance,
  selectBalanceError,
  selectBalanceLoading,
  selectLimitLoading,
  selectUserLimit,
} from '@common/redux/selectors/banking';
import { getAgreementBalance } from '@common/redux/thunks/banking';
import clsx from 'clsx';

import DashboardInvoiceInformation from '../dashboard-invoice-information/DashboardInvoiceInformation';
import { getClassNames } from '../dashboard/Dashboard.classes';
import m from '../dashboard/Dashboard.messages';

interface DashboardInvoiceSectionProps {
  hasSubscription: boolean;
}

const DashboardInvoiceSection: FC<DashboardInvoiceSectionProps> = ({ hasSubscription }) => {
  const dispatch = useDispatch<RootDispatch>();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const balanceLoading = useSelector(selectBalanceLoading);
  const balanceError = useSelector(selectBalanceError);
  const agreement = useSelector(selectActiveAgreement);
  const limitLoading = useSelector(selectLimitLoading);
  const balanceData = useSelector(selectBalance);
  const limitData = useSelector(selectUserLimit);
  const fetchBalance = useCallback(
    async (agreementId?: string) => {
      if (!agreementId || balanceLoading) {
        return;
      }
      await dispatch(getAgreementBalance(agreementId)).unwrap();
    },
    [dispatch, balanceLoading]
  );

  useEffect(() => {
    fetchBalance(agreement?.id);
  }, [agreement?.id]);

  const noBalanceData = useMemo(
    () => !balanceLoading && balanceError,
    [balanceError, balanceLoading]
  );

  const noInvoice = useMemo(() => limitData?.balance === 0, [limitData?.balance]);

  const disableActionButtons = useMemo(
    () => noInvoice || balanceLoading || limitLoading || noBalanceData,
    [noInvoice, balanceLoading, limitLoading, noBalanceData]
  );

  const classNames = useMemo(() => getClassNames(), []);

  const handleStartPayment = useCallback(() => {
    navigate(`/${localeWithCountry}/payment`);
  }, [navigate, localeWithCountry]);

  return (
    <div className={clsx('bg-[#E7E6E1]', classNames.card)}>
      <div className="flex flex-col items-start justify-start gap-3 self-stretch">
        <div className="inline-flex items-start justify-between gap-2 self-stretch">
          {balanceLoading || limitLoading ? (
            <BlackDotsLoader style={{ height: 60 }} />
          ) : (
            <DashboardInvoiceInformation
              disableDetailsButton={disableActionButtons}
              hasSubscription={hasSubscription}
            />
          )}
        </div>
      </div>
      <Button
        onClick={handleStartPayment}
        disabled={disableActionButtons}
        className="w-full"
        buttonNode={
          <div
            className={clsx(
              'border-transparent bg-white px-3 py-4 text-xl text-black',
              classNames.button,
              {
                [classNames.invoiceButtonsHover]: !disableActionButtons,
                [classNames.disabledInvoiceButtonText]: disableActionButtons,
              }
            )}
          >
            {formatMessage(m.paymentOptionsBtnText)}
          </div>
        }
      />
    </div>
  );
};

export default DashboardInvoiceSection;
