import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import { FooterProps } from '@common/components/footer/Footer';
import FooterLogo from '@common/components/icons/FooterLogo';

const Footer: FC<FooterProps> = () => {
  return (
    <div className="flex w-full flex-col items-center gap-5">
      <div className="flex flex-col items-center p-2 opacity-50 sm:h-9 sm:flex-row sm:justify-center">
        <div className="flex flex-col items-center gap-2.5 sm:flex-row sm:gap-5">
          <FooterLogo />
          <span className="text-center font-normal text-black text-opacity-50 sm:text-base">
            <FormattedMessage defaultMessage="© Creditstar Group. All Rights Reserved." />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
