import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import Layout from '@common/components/layout/Layout';
import { useDispatch } from '@common/hooks';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { RootDispatch } from '@common/redux';
import { selectActiveAgreement, selectMainLoading } from '@common/redux/selectors/banking';
import { selectDocuments, selectDocumentsLoading } from '@common/redux/selectors/documents';
import { getActiveAgreement } from '@common/redux/thunks/banking';
import { getUserDocuments } from '@common/redux/thunks/documents';
import { AgreementOriginator } from '@common/types/agreement';
import CreditDetails from '@monefit-es/components/credit-details/CreditDetails';

import m from './CreditDetailsPage.messages';

const CreditDetailsPage = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const { userSubscriptions, fetchUserSubscriptions } = useSubsciptions();
  const activeAgreement = useSelector(selectActiveAgreement);
  const agreementLoading = useSelector(selectMainLoading);
  const documents = useSelector(selectDocuments);
  const documentsLoading = useSelector(selectDocumentsLoading);

  const fetchCreditData = useCallback(async () => {
    if (activeAgreement || agreementLoading) {
      return;
    }

    await dispatch(getActiveAgreement({ originaror: AgreementOriginator.MONEFIT_ES })).unwrap();
  }, [dispatch, activeAgreement, agreementLoading]);

  const fetchDocuments = useCallback(async () => {
    if (documents || documentsLoading) {
      return;
    }
    await dispatch(getUserDocuments()).unwrap();
  }, [dispatch, documents, documentsLoading]);

  const fetchUserSubscriptionsData = useCallback(async () => {
    if (userSubscriptions !== null) {
      return;
    }

    await fetchUserSubscriptions();
  }, []);

  useEffect(() => {
    fetchCreditData();
  }, []);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useEffect(() => {
    fetchUserSubscriptionsData();
  }, []);

  return (
    <Layout.WithNavigation
      loadingProp={documentsLoading || agreementLoading}
      maxWDesktop={600}
      backButtonText={formatMessage(m.backButtonText)}
      hideProgressBar
    >
      <CreditDetails />
    </Layout.WithNavigation>
  );
};

export default CreditDetailsPage;
