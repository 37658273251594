import { useCallback } from 'react';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import SuccessIcon from '@common/components/icons/SuccessIcon';
import { Currency } from '@common/constants';
import { useLocale, useSelector } from '@common/hooks';

import m from './DrawdownCompleteCard.messages';

const DrawdownCompleteCard = () => {
  const { data } = useSelector((st) => st.application.flowInstance);
  const { localeWithCountry } = useLocale();
  const navigate = useNavigate();

  const handleDoneClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`, { replace: true });
  }, [navigate, localeWithCountry]);

  const { formatMessage } = useIntl();
  return (
    <div className="flex w-full flex-col items-center justify-start gap-2.5">
      <div className="flex w-full flex-col items-start justify-start gap-2.5">
        <div className="flex h-96 flex-col items-start justify-end self-stretch">
          <div className="flex flex-col items-start justify-start gap-1 self-stretch rounded-t-3xl bg-stone-100 p-7 backdrop-blur-lg">
            <div className="inline-flex items-center justify-between self-stretch">
              <div className="text-base font-medium leading-normal text-black text-opacity-60">
                {formatMessage(m.subTitle1)}
              </div>
              <div className="text-sm font-semibold leading-snug  tracking-tight text-black">
                <FormattedNumber
                  // eslint-disable-next-line react/style-prop-object
                  style="currency"
                  currency={Currency.EUR}
                  currencyDisplay="symbol"
                  minimumFractionDigits={2}
                  maximumFractionDigits={2}
                  value={data?.responseData?.available}
                />
              </div>
            </div>
            {!!data?.responseData?.nextInvoiceData && (
              <div className="inline-flex items-center justify-between self-stretch">
                <div className="text-base font-medium leading-normal text-black text-opacity-60">
                  {formatMessage(m.subTitle2)}
                </div>
                <div className="text-sm font-semibold leading-tight tracking-tight text-black">
                  <FormattedDate
                    value={data?.responseData?.nextInvoiceData?.date ?? null}
                    day="2-digit"
                    month="short"
                    year="numeric"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="-mt-4 flex h-80 flex-col items-start justify-between self-stretch rounded-3xl bg-white p-7 backdrop-blur-lg">
            <div className="flex h-40 flex-col items-center justify-center gap-5 self-stretch">
              <SuccessIcon />
              <div className="flex h-16 flex-col items-center justify-center gap-2.5 self-stretch">
                <div className="self-stretch text-center text-base font-medium leading-tight  tracking-tight text-black">
                  {formatMessage(m.amountSent, { amount: data?.responseData?.amount })}
                </div>
                <div className="inline-flex items-center justify-center gap-1 rounded-lg bg-stone-100/40 p-3 backdrop-blur-lg">
                  <div>
                    <span className="text-xs font-semibold leading-tight  tracking-tight text-black">
                      {data?.responseData?.bankAccount}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <Button onClick={handleDoneClick} fullWidth text={formatMessage(m.doneButtonText)} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawdownCompleteCard;
