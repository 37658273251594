import { useEffect, useRef } from 'react';

/**
 * Custom hook that calls the provided callback function at a specified interval.
 *
 * @param callback The callback function to be called at the specified interval.
 * @param delay The interval in milliseconds at which the callback function should be called or null to stop the interval.
 */
export const useInterval = (callback: () => Promise<void>, delay: number | null) => {
  const savedCallback = useRef<() => Promise<void>>();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    if (delay === null) {
      return;
    }

    let isMounted = true;
    let id: NodeJS.Timeout;

    const tick = async () => {
      if (savedCallback.current) {
        await savedCallback.current();
      }
      if (isMounted && delay !== null) {
        id = setTimeout(tick, delay);
      }
    };

    id = setTimeout(tick, delay);

    return () => {
      isMounted = false;
      clearTimeout(id);
    };
  }, [delay]);
};
