import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  pendingBoldText: {
    defaultMessage: 'Verifying your bank information',
    description: '@monefit-es Verifying your bank information',
  },
  pendingText: {
    defaultMessage:
      'This may take up to 2 minutes. Please keep this tab open while we complete the process',
    description:
      '@monefit-es This may take up to 2 minutes. Please keep this tab open while we complete the process',
  },
  chatWithUs: {
    defaultMessage: 'Chat with us',
  },
});
