import { PropsWithoutRef, ReactNode, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import BackButton from '../button/BackButton';
import Button from '../button/Button';
import { FormFieldToTrackOnChange, handleFormFieldsEventTracking } from './Form.helpers';

export interface FormProps
  extends Omit<PropsWithoutRef<JSX.IntrinsicElements['form']>, 'onSubmit'> {
  children?: ReactNode;
  submitText?: string;
  schema?: yup.ObjectSchema<any>;
  onSubmit?: (values: any) => Promise<void> | void;
  initialValues?: any;
  gapClass?: string;
  extraText?: string;
  submitTrackEventName?: TrackEvent;
  fieldsToTrackOnChange?: FormFieldToTrackOnChange[];
}

const Form = ({
  children,
  submitText,
  schema,
  initialValues,
  gapClass,
  onSubmit,
  extraText,
  submitTrackEventName,
  fieldsToTrackOnChange = [],
  ...props
}: FormProps) => {
  const ctx = useForm({
    mode: 'onBlur',
    resolver: schema ? yupResolver(schema) : undefined,
    defaultValues: initialValues,
  });

  const { formatMessage } = useIntl();

  const {
    watch,
    formState: { isSubmitting, isValid },
  } = ctx;

  const { trackEvent } = useTracker({});

  useEffect(() => {
    const subscription = watch((values) => {
      // Track changes if needed
      handleFormFieldsEventTracking(values, fieldsToTrackOnChange, trackEvent);
    });
    return () => subscription.unsubscribe();
  }, [watch, trackEvent, fieldsToTrackOnChange]);

  return (
    <>
      <FormProvider {...ctx}>
        <form
          onSubmit={ctx.handleSubmit(async (values) => {
            try {
              if (onSubmit) {
                await onSubmit(values);
              }
              submitTrackEventName && trackEvent(submitTrackEventName);
            } catch (e) {
              console.warn(e);
            }
          })}
          {...props}
        >
          <div className={`flex size-full flex-col justify-center ${gapClass ?? 'gap-3'}`}>
            {children}
            <div className="flex w-full flex-col gap-3">
              <div className="flex w-full gap-3">
                <BackButton />
                <Button
                  disabled={isSubmitting || !isValid}
                  color="black"
                  fullWidth
                  text={
                    submitText ??
                    formatMessage({
                      defaultMessage: 'Continue',
                      description: 'Continue button text',
                    })
                  }
                  type="submit"
                />
              </div>
              {!!extraText && (
                <div className="text-center text-[15px] font-normal text-black text-opacity-60">
                  {extraText}
                </div>
              )}
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default Form;
