import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';

import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useFlowIdFromUrl from '@common/hooks/useFlowIdFromUrl';
import DeclinedCard from '@monefit-es/components/declined-card/DeclinedCard';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import TransactionRejectedCard from '@monefit-es/components/transaction-rejected-card/TransactionRejectedCard';
import tm from '@monefit-es/components/transaction-rejected-card/TransactionRejectedCard.messages';

import m from './DeclinedPage.messages';

const DeclinedPage = () => {
  const { flowId } = useFlowIdFromUrl();
  const { localeWithCountry } = useLocale();
  const { formatMessage } = useIntl();

  const renderDeclinedPage = useCallback(() => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID, REACT_APP_ES_DRAWDOWN_FLOW_ID } = process.env;

    switch (flowId) {
      case REACT_APP_ES_APPLICATION_FLOW_ID:
        return (
          <MainLayout>
            <DeclinedCard />
          </MainLayout>
        );
      case REACT_APP_ES_DRAWDOWN_FLOW_ID:
        return (
          <Layout.WithNavigation
            maxWDesktop={545}
            hideProgressBar
            backButtonText={formatMessage(tm.headingText)}
          >
            <TransactionRejectedCard descriptionText={formatMessage(m.descriptionText)} />
          </Layout.WithNavigation>
        );
      default:
        return <Navigate replace to={`/${localeWithCountry}/user`} />;
    }
  }, [flowId, formatMessage, localeWithCountry]);

  return <>{renderDeclinedPage()}</>;
};

export default DeclinedPage;
