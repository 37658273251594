import { useSelector } from '@common/hooks/useSelector';

import ProgressBar from './ProgressBar';

const FlowProgressBar = () => {
  const { data, error, meta, loading } = useSelector((st) => st.application.flowInstance);

  if (error || !data?.progressPercentage || (loading && meta.showLoader)) {
    return null;
  }

  return (
    <div className="w-full max-w-full sm:max-w-[610px]">
      <ProgressBar progress={data?.progressPercentage} />
    </div>
  );
};

export default FlowProgressBar;
