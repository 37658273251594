import { FC, useCallback, useEffect, useState } from 'react';

import { FlowSteps } from '@common/services/application';
import { kebabCase, startCase } from 'lodash';

import useSubApp from './useSubApp';

export interface ApplicationRoutingPageMap {
  flowStepId: FlowSteps;
  path: string;
  element: FC;
}

export interface PageMap {
  [key: string]: FC;
}

/**
 * Dynamically load page map for application flow routing based on country (from URL)
 * Page map should be defined under src/project-id/pages/index.tsx
 */
export const useApplicationRoutingPageMap = (
  flowSteps: FlowSteps[]
): [ApplicationRoutingPageMap[], boolean] => {
  const [loading, setLoading] = useState<boolean>(false);
  const [applicationRoutingPageMap, setApplicationRoutingPageMap] = useState<
    ApplicationRoutingPageMap[]
  >([]);

  const { subAppId } = useSubApp();
  const initPageMap = useCallback(async () => {
    if (!subAppId) {
      return {};
    }
    setLoading(true);
    const CommonPages: PageMap = await import(`@common/pages`);
    const SubAppPages: PageMap = await import(`@src/${subAppId}/pages`);
    const Pages = { ...CommonPages, ...SubAppPages };

    const map: ApplicationRoutingPageMap[] = Object.entries(flowSteps).map((fs, i) => {
      const flowStepId = fs[1] as FlowSteps;
      const path = `${kebabCase(fs[1])}`.replace(' ', '');
      const componentName: string = `${startCase(fs[1])}Page`.replace(' ', '');
      const element = Pages[componentName];

      return {
        flowStepId,
        path,
        element,
      };
    });

    setApplicationRoutingPageMap(map);
    setLoading(false);
  }, [flowSteps, subAppId]);

  useEffect(() => {
    initPageMap();
  }, [flowSteps, initPageMap]);

  return [applicationRoutingPageMap, loading];
};
