import { useEffect, useMemo, useState } from 'react';

import useSubApp, { SubAppId } from '@common/hooks/useSubApp';
import classNames from 'classnames';

interface ProgressBarProps {
  progress?: number;
}

const ProgressBar = ({ progress = 0 }: ProgressBarProps) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const { subAppId } = useSubApp();

  const countryBasedClassNames = useMemo(() => {
    switch (subAppId) {
      case SubAppId.MONEFIT_ES:
        return {
          wrapper: 'p-0.5',
          progress: 'h-0.5 bg-black',
        };

      default:
        return {
          wrapper: 'p-1',
          progress: 'h-3 bg-blue-brand',
        };
    }
  }, [subAppId]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoaded(true);
    }, 100);

    return () => clearTimeout(timeout);
  }, []);

  const width = useMemo(() => {
    if (progress > 1) {
      return 1;
    }
    if (progress < 0) {
      return 0;
    }
    return progress;
  }, [progress]);

  const widthToStartTransition = useMemo(() => {
    if (width === 0 || width === 1) {
      return width;
    }
    return width - 0.05;
  }, [width]);

  return (
    <div
      className={classNames(
        countryBasedClassNames.wrapper,
        'w-full overflow-hidden rounded-full bg-white  shadow-sm'
      )}
    >
      <div
        className={classNames(countryBasedClassNames.progress, 'rounded-full')}
        style={{
          width: `${isLoaded ? width * 100 : widthToStartTransition * 100}%`,
          transition: 'width 0.5s ease-in-out',
        }}
      ></div>
    </div>
  );
};

export default ProgressBar;
