import { FC, JSX } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IntlProvider, useIntl } from 'react-intl';
import { Provider } from 'react-redux';

import MetaTags from '@common/components/meta-tags/MetaTags';
import { useLocale } from '@common/hooks';
import useMessages from '@common/hooks/useMessages';
import { store } from '@common/redux';
import Router from '@src/monefit-ee/router/Router';

const AppContent: FC = (): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title={formatMessage({
            defaultMessage: 'Monefit Creditline',
            description: 'Monefit Creditline',
          })}
          description={formatMessage({
            defaultMessage: 'Interest-free creditline',
            description: 'Interest-free creditline',
          })}
        />
      </HelmetProvider>
      <Provider store={store}>
        <div data-testid="monefit-ee-app">
          <Router />
        </div>
      </Provider>
    </>
  );
};

const App: FC = (): JSX.Element | null => {
  const { locale } = useLocale();
  const { messages, messagesLoading } = useMessages();
  if (!messages || messagesLoading) {
    return null;
  }

  return (
    <IntlProvider locale={locale} messages={messages}>
      <AppContent />
    </IntlProvider>
  );
};

export default App;
