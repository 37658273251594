import { defineMessages } from 'react-intl';

export default defineMessages({
  approvedCredit: {
    defaultMessage: 'Approved credit',
    description: '@monefit-es Approved credit',
  },
  usedCredit: {
    defaultMessage: 'Used credit',
    description: '@monefit-es Used credit',
  },
  secci: {
    defaultMessage: 'SECCI',
    description: 'SECCI',
  },
  loanAgreement: {
    defaultMessage: 'Loan agreement',
    description: '@monefit-es Loan agreement',
  },
  subscription: {
    defaultMessage: 'Credit+',
    description: '@monefit-es Credit+',
  },
  download: {
    defaultMessage: 'Download',
  },
  downloadError: {
    defaultMessage: 'Unable to download document',
  },
  upgradeBtnText: {
    defaultMessage: 'Contact us to upgrade',
  },
  downgradeButton: {
    defaultMessage: 'Contact us to downgrade',
  },
});
