import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import ArrowDownIcon from '@common/components/icons/ArrowDownIcon';
import SelectInput from '@common/components/select-input/SelectInput';
import { Currency, CurrencySymbol, LocalStorageKeys } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { reset } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import m from './DrawdownConfirmForm.messages';

interface FormValues {
  bankAccount: string;
}

interface BankAccount {
  iban: string;
  name: string;
}

const DrawdownConfirmForm = () => {
  const { formatMessage, formatNumber } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const { localeWithCountry } = useLocale();
  const navigate = useNavigate();
  const [instanceId] = useApplicationFlowInstance();
  const { isDevOrStagingEnv } = useEnvironment();
  const { data } = useSelector((st) => st.application.flowInstance);
  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      bankAccount: yup.string().required(formatMessage(m.validationText)),
    });
  }, [formatMessage]);

  const handleCancel = useCallback(() => {
    navigate(`/${localeWithCountry}/user`);
    sessionStorage.removeItem(LocalStorageKeys.APPLICATION_FLOW_INSTANCE_ID);
    dispatch(reset());
  }, [dispatch, localeWithCountry, navigate]);

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: { bankAccount: v.bankAccount },
        })
      );
    },
    [dispatch, instanceId]
  );

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      bankAccount: data?.responseData.bankAccounts?.[0]?.iban,
    },
  });

  const {
    formState: { isSubmitting, isValid },
  } = ctx;

  const bankAccountOptions = useMemo(() => {
    const bankAccounts: BankAccount[] = data?.responseData?.bankAccounts ?? [];
    // TODO: Remove if statement with hardcoded data
    if (!bankAccounts?.length && isDevOrStagingEnv) {
      return [{ label: 'TEST ACC, NO RESPONSE FROM BE', value: 'ES9121000418450200051332' }];
    }

    return bankAccounts.map((x) => ({ label: x.iban, value: x.iban }));
  }, [data?.responseData?.bankAccounts, isDevOrStagingEnv]);

  return (
    <form
      autoComplete="off"
      className="flex size-full"
      onSubmit={ctx.handleSubmit(async (v: FormValues) => {
        try {
          await handleFormSubmit(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-4">
          <div className="inline-flex w-full flex-col items-center justify-start gap-3.5 rounded-2xl p-7">
            <div className="flex flex-col items-center justify-start gap-1">
              <div className="w-full text-center text-base font-medium leading-none tracking-tight text-neutral-500">
                {formatMessage(m.subTitle)}
              </div>
              <div>
                <span className="text-4xl font-bold leading-loose  text-black">
                  {formatNumber(data?.responseData?.amount, {
                    currency: Currency.EUR,
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
                </span>{' '}
                <span className="text-2xl font-bold leading-loose ">{CurrencySymbol.EUR}</span>
                <div className="flex w-full justify-center">
                  <ArrowDownIcon />
                </div>
              </div>
            </div>
            <div className="w-full">
              <SelectInput
                label={formatMessage(m.inputLabel)}
                name="bankAccount"
                options={bankAccountOptions}
              />
            </div>
          </div>
          <div className="mt-[70px] flex w-full gap-2">
            <Button
              onClick={handleCancel}
              color="transparent"
              type="button"
              fullWidth
              hoverClassName="hover:bg-black hover:text-white"
              borderClassName="border-2 border-black"
              text={formatMessage(m.cancel)}
            />
            <Button
              disabled={isSubmitting || !isValid}
              type="submit"
              fullWidth
              text={formatMessage(m.subTitle)}
            />
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default DrawdownConfirmForm;
