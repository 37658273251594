import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import loader from '@src/assets/img/monefit-ee/main-loader.webp';

import { removeBankSubStepFromStorage } from '../bank-page/bankPageHelpers';
import ErrorBoundaryPage from '../error-boundary-page/ErrorBoundaryPage';

const DecisionPage = () => {
  useTracker({
    initEventName: TrackEvent.DECISION_PAGE_INIT,
    errorTrackEventName: TrackEvent.DECISION_PAGE_ERROR,
  });
  const { error } = useSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();

  useEffect(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.SUBMIT, instanceId }))
      .unwrap()
      .finally(() => {
        removeBankSubStepFromStorage(instanceId);
        dispatch(setShowLoader(true));
      });
  }, []);

  return error ? (
    <ErrorBoundaryPage />
  ) : (
    <SignupLayout>
      <LoadingCard
        imgSrcProp={loader}
        text={formatMessage({
          defaultMessage: 'A few more moments!',
          description: 'Decision: "A few more moments!"',
        })}
        extraText={
          <FormattedMessage
            defaultMessage={
              '<p>This will take just a few minutes.{br} Please do not close the page.</p>'
            }
            values={{
              p: (...chunks) => <p>{chunks}</p>,
              br: <br />,
            }}
          />
        }
      />
    </SignupLayout>
  );
};

export default DecisionPage;
