import { FC } from 'react';

import blackDots from '@src/assets/animations/Dots-animation-black.json';
import Lottie, { LottieComponentProps } from 'lottie-react';

interface BlackDotsLoaderProps extends Omit<LottieComponentProps, 'animationData'> {}

const BlackDotsLoader: FC<BlackDotsLoaderProps> = (props) => {
  return <Lottie {...props} animationData={blackDots} />;
};

export default BlackDotsLoader;
