import { FC, ReactNode } from 'react';

import LoadingCard from '@common/components/loader/LoadingCard';
import main_loader from '@src/assets/img/monefit-es/main_loader.webp';

interface MainLoaderProps {
  showIndicator?: boolean;
  extraClassNames?: string;
  imageSrc?: string;
  text?: string;
  gradientOpacity?: number;
  hasTextShadow?: boolean;
  textNode?: ReactNode;
}

const MainLoader: FC<MainLoaderProps> = ({
  showIndicator = true,
  extraClassNames,
  imageSrc,
  text,
  gradientOpacity,
  hasTextShadow = true,
  textNode,
}) => {
  return (
    <div className="flex w-full items-center justify-center text-black">
      <LoadingCard
        imgSrcProp={imageSrc ?? main_loader}
        {...{ extraClassNames, text, gradientOpacity, showIndicator, hasTextShadow, textNode }}
      />
    </div>
  );
};

export default MainLoader;
