import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '@common/components/form/Form';
import { formatPhoneValue } from '@common/components/form/Form.helpers';
import PhoneInput from '@common/components/phone-input/PhoneInput';
import { Country, useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import useUserSessionData from '@common/hooks/useUserSessionData';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import * as yup from 'yup';

interface FormValues {
  phone: string;
}

const PhoneSubmitForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const { saveToStorage } = useUserSessionData();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        phone: yup
          .string()
          .min(
            11,
            formatMessage({
              defaultMessage: 'Please enter a valid phone number with 7 to 8 digits.',
              description: 'Phone: "Please enter a valid phone number with 7 to 8 digits."',
            })
          ) // +372 is automatically added by phoneInput, that's why 11 instead of 7
          .max(
            12,
            formatMessage({
              defaultMessage: 'Please enter a valid phone number with 7 to 8 digits.',
              description: 'Phone: "Please enter a valid phone number with 7 to 8 digits."',
            })
          ) // +372 is automatically added by phoneInput, that's why 11 instead of 8
          .matches(
            /^\+372[58]/,
            formatMessage({
              defaultMessage: 'Oops! Phone numbers must start with either "5" or "8".',
              description: 'Phone: "Oops! Phone numbers must start with either "5" or "8""',
            })
          )
          .required(
            formatMessage({
              defaultMessage: 'Please enter a valid phone number with 7 to 8 digits.',
              description: 'Phone: "Please enter a valid phone number with 7 to 8 digits."',
            })
          ),
      }),
    [formatMessage]
  );

  return (
    <div className="w-full">
      <Form
        schema={schema}
        submitTrackEventName={TrackEvent.PHONE_PAGE_SUBMIT}
        fieldsToTrackOnChange={[
          { fieldName: 'phone', event: TrackEvent.PHONE_PAGE_NUMBER_ENTERED },
        ]}
        onSubmit={(values: FormValues) => {
          dispatch(
            performApplicationFlowAction({
              action: FlowActions.SUBMIT,
              instanceId,
              data: { phone: formatPhoneValue({ phone: values.phone, country: Country.EE }) },
            })
          )
            .unwrap()
            .then(() => saveToStorage({ phone: values.phone }));
        }}
        gapClass="gap-8"
      >
        <PhoneInput
          country="EE"
          name="phone"
          label={formatMessage({
            defaultMessage: 'Mobile phone',
            description: 'Phone: "Mobile phone" (input label)',
          })}
          description={formatMessage({
            defaultMessage: 'We will send you secure code to verify your phone.',
            description:
              'Phone: "We will send you secure code to verify your phone." (input description)',
          })}
        />
      </Form>
    </div>
  );
};

export default PhoneSubmitForm;
