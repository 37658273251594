import { defineMessages } from 'react-intl';

export default defineMessages({
  heading1: {
    defaultMessage: 'Processing your application',
    description: '@monefit-es Processing your application',
  },
  heading2: {
    defaultMessage: 'We’re on it!',
    description: '@monefit-es We’re on it!',
  },
  headingDescription: {
    defaultMessage:
      ' Your application is under review, and we’re working to process it as quickly as possible',
    description:
      '@monefit-es Your application is under review, and we’re working to process it as quickly as possible ',
  },
  subTitle1: {
    defaultMessage: 'What’s happening now?',
    description: '@monefit-es  What’s happening now?',
  },
  subTitle2: {
    defaultMessage: 'Next steps',
    description: '@monefit-es  Next steps',
  },
  subText1: {
    defaultMessage: 'Our team is carefully checking your information to make the best decision',
    description:
      '@monefit-es Our team is carefully checking your information to make the best decision',
  },
  subText2: {
    defaultMessage: 'We’ll notify you as soon as we have an update on your application.',
    description: '@monefit-es We’ll notify you as soon as we have an update on your application.',
  },
  subText3: {
    defaultMessage: 'Thanks for your patience. We’re excited to have you with us!',
    description: '@monefit-es Thanks for your patience. We’re excited to have you with us!',
  },
});
