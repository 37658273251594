export enum AgreementStatus {
  NEW = 'New',
  ACTIVE = 'Active',
  PENDING_WITHDRAWAL = 'PendingWithdrawal',
  CLOSED = 'Closed',
  TERMINATED = 'Terminated',
}

// TODO: add values for other originators
export enum AgreementOriginator {
  MONEFIT_ES = 'MONEFIT_ES',
}

export interface Agreement {
  id: string;
  identifier: string;
  contractNumber: string;
  originatorIdentifier: AgreementOriginator;
  typeId: string;
  type: string;
  statusId: string;
  status: AgreementStatus;
  userId: string;
  currency: string;
  config: unknown;
  createdAt: string;
  updatedAt: string;
  terminatedAt: string;
}

export interface AgreementsGetResponse {
  data: Agreement[];
  _links: {
    self: {
      href: string;
    };
    first: {
      href: string;
    };
    last: {
      href: string;
    };
  };
  _meta: {
    totalCount: number;
    pageCount: number;
    currentPage: number;
    perPage: number;
  };
}

// partial /agreement/{id}/info/balance response object, add more properties if needed
export interface AgreementBalance {
  unpaidInvoicedTotalBalance: number | null;
  invoiceDueDate: string | null;
  firstUnpaidInvoiceDueDate: string | null;
  nextInvoiceTargetDate: string;
  invoiceDueDateTerm: number;
}
