import { FC, InputHTMLAttributes, useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';
import InputLabel from '@common/components/input-label/InputLabel';
import clsx from 'clsx';

import styles from './TextInput.styles';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: 'text' | 'password' | 'email' | 'number' | 'tel';
  label?: string;
  desciption?: string;
  step?: string;
  styling?: 'regular' | 'account';
}

const TextInput: FC<TextInputProps> = ({
  defaultValue,
  disabled,
  name,
  label,
  type,
  step = '0.01',
  desciption,
  styling = 'regular',
  ...props
}) => {
  const {
    fieldState: { error },
  } = useController({
    defaultValue,
    name,
    rules: {},
  });

  const {
    register,
    formState: { isSubmitting },
    getValues,
    trigger,
  } = useFormContext();

  const { onChange, ...registration } = register(name);
  const [canShowError, setCanShowError] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative">
      <input
        className={clsx(styles.base, styles[styling ?? 'regular'], { 'pt-3': !!label })}
        disabled={isSubmitting || disabled}
        {...registration}
        {...props}
        {...{ type, step }}
        onChange={(e) => onChange(e)}
        onFocus={() => setIsFocused(true)}
        defaultValue={defaultValue}
        onBlur={() => {
          setIsFocused(false);
          setCanShowError(true);
          trigger(name);
        }}
        placeholder={label ? '' : (props.placeholder ?? '')}
      />
      {!!label && (
        <InputLabel
          name={name}
          text={label}
          shouldMinimize={isFocused || props.value || defaultValue || getValues(name)}
        />
      )}
      {!!desciption && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {desciption}
        </div>
      )}
      {error?.message && canShowError && (
        <div className="mt-2 text-xs font-semibold text-rose-400">{error?.message?.toString()}</div>
      )}
    </div>
  );
};

export default TextInput;
