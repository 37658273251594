import { BankStatementInvitationStatus } from '@common/enums/core-services';
import { fetchApplicationFlowInstance } from '@common/redux/thunks/application';
import { handleBankStatementCallback } from '@common/redux/thunks/bank-statement';
import { FlowSteps } from '@common/services/application';
import { SubmitCallbackResponse } from '@common/services/bank-statement.types';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface BankStatementState {
  invitation: {
    id: string | null;
    referenceId: string | null;
    redirectUrl: string | null;
  };
  callback: {
    status: BankStatementInvitationStatus | null;
  };
  loading: boolean;
  error: boolean;
}

export const initialState: BankStatementState = {
  invitation: {
    id: null,
    referenceId: null,
    redirectUrl: null,
  },
  callback: {
    status: null,
  },
  loading: false,
  error: false,
};

/**
 * Bank statement state handling
 */
const bankStatementSlice = createSlice({
  name: 'bankStatement',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(handleBankStatementCallback.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));
    builder.addCase(
      handleBankStatementCallback.fulfilled,
      (state, { payload }: PayloadAction<SubmitCallbackResponse>) => ({
        ...state,
        callback: {
          status: payload.status,
        },
        loading: false,
        error: false,
      })
    );
    builder.addCase(handleBankStatementCallback.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));
    builder.addCase(fetchApplicationFlowInstance.fulfilled, (state, { payload }) => ({
      ...state,
      invitation: {
        ...state.invitation,
        ...(payload?.currentStepId === FlowSteps.BANK && {
          id: payload.responseData?.invitationId,
          referenceId: payload.responseData?.referenceId,
          redirectUrl: payload.responseData?.redirectUrl,
        }),
      },
    }));
  },
});

export const { reset } = bankStatementSlice.actions;

export default bankStatementSlice.reducer;
