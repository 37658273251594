import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import m from './QuestionnaireFormEnums.messages';

export type EnumValue =
  | 'part_time'
  | 'full_time'
  | 'self_employed'
  | 'social_benefits'
  | 'interest_from_Investment'
  | 'rent'
  | 'pension'
  | 'unemployed';

const useQuestionnaireForm = () => {
  const { formatMessage } = useIntl();

  const enumMapper = useCallback(
    (value: EnumValue) => {
      return m[value] ? formatMessage(m[value]) : value;
    },
    [formatMessage]
  );

  return { enumMapper };
};

export default useQuestionnaireForm;
