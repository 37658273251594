import { subscriptionService } from '@common/services/subscription';
import { Subscription, UserSubscriptionGetResponse } from '@common/types/subscription';
import { ApiError, getUserData } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getUserSubscriptions = createAsyncThunk<Subscription[] | null>(
  'subscription/user-subscription',
  async (_, { rejectWithValue }) => {
    const { user } = getUserData();
    if (!user.id) {
      return rejectWithValue('User ID is missing');
    }
    try {
      const searchParams = new URLSearchParams({
        userId: user.id,
        active: 'true',
      });
      const { data } = await subscriptionService.get<UserSubscriptionGetResponse>(
        `/user-subscription?${searchParams.toString()}`
      );
      return data?.data ?? null;
    } catch (e) {
      throw new ApiError(String(e));
    }
  }
);
