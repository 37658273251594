import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';

import AddressInput from '@common/components/address-input/AddressInput';
import { Address } from '@common/components/address-input/AddressInput.Maaamet.types';
import Form from '@common/components/form/Form';
import SelectInput, { IOption } from '@common/components/select-input/SelectInput';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import * as yup from 'yup';

interface FormValues {
  residentialDuration: number;
  address: Address;
}

const FIELDS_TO_LOG_ON_CHANGE = [
  {
    fieldName: 'residentialDuration',
    event: TrackEvent.ADDRESS_PAGE_YEARS_OF_RES_SELECT,
  },
];

const AddressForm = () => {
  const { formatMessage } = useIntl();
  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        address: yup.object({
          region: yup.string().optional().nullable(),
          city: yup.string().required(
            formatMessage({
              defaultMessage: 'Start with your street name.',
              description: 'Address: "Start with your street name."',
            })
          ),
          street: yup.string().optional().nullable(),
          houseName: yup.string().optional().nullable(),
          houseNo: yup.string().optional().nullable(),
          postCode: yup.string().optional().nullable(),
          hasApartments: yup.bool().optional().nullable(),
          apartmentNo: yup.string().when('hasApartments', {
            is: true,
            then: () =>
              yup.string().required(
                formatMessage({
                  defaultMessage: 'Oops! A required field is empty.',
                  description: 'Address: "Oops! A required field is empty."',
                })
              ),
            otherwise: () => yup.string().optional().nullable(),
          }),
        }),
        residentialDuration: yup.number().required(
          formatMessage({
            defaultMessage: 'The field is mandatory',
            description: 'Address: "The field is mandatory"',
          })
        ),
      }),
    [formatMessage]
  );

  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const residentialDurationOptions = useMemo(
    () =>
      [
        {
          label: formatMessage({
            defaultMessage: '1 year',
            description: 'Finances: employmentDurationOptions.1year',
          }),
          value: 1,
        },
        {
          label: formatMessage({
            defaultMessage: '2 years',
            description: 'Finances: employmentDurationOptions.2years',
          }),
          value: 2,
        },
        {
          label: formatMessage({
            defaultMessage: '3+ years',
            description: 'Finances: employmentDurationOptions.3+years',
          }),
          value: 3,
        },
      ] as IOption[],

    [formatMessage]
  );

  const onSubmit = useCallback(
    (values: FormValues) => {
      const { address, residentialDuration } = values;
      delete address.hasApartments;
      dispatch(
        performApplicationFlowAction({
          instanceId,
          data: {
            ...address,
            residentialDuration,
          },
          action: FlowActions.SUBMIT,
        })
      );
    },
    [dispatch, instanceId]
  );

  return (
    <SignupBaseCard
      headerText={formatMessage({
        defaultMessage: 'Your residency',
        description: 'Address: "Your residency"',
      })}
      subHeaderText={formatMessage({
        defaultMessage:
          'Please begin by typing your street address and number to locate your address.',
        description:
          'Address: Please begin by typing your street address and number to locate your address.',
      })}
    >
      <div className="flex w-full flex-col">
        <Form
          gapClass="gap-2"
          schema={schema}
          onSubmit={onSubmit}
          submitTrackEventName={TrackEvent.ADDRESS_PAGE_SUBMIT}
          fieldsToTrackOnChange={FIELDS_TO_LOG_ON_CHANGE}
        >
          <AddressInput.Maaamet name="address" />
          <SelectInput
            name="residentialDuration"
            options={residentialDurationOptions}
            label={formatMessage({
              defaultMessage: 'Years of residency',
              description: 'Address: "Years of residency"',
            })}
          />
        </Form>
      </div>
    </SignupBaseCard>
  );
};

export default AddressForm;
