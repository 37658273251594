import { useCallback } from 'react';
import useIntl from 'react-intl/src/components/useIntl';

import Button from '@common/components/button/Button';
import AlertIcon from '@common/components/icons/AlertIcon';
import useIntercomChat from '@common/hooks/useIntercomChat';

import m from './TransactionRejectedCard.messages';

interface TransactionRejectedCardProps {
  headingText?: string;
  descriptionText?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const TransactionRejectedCard = ({
  headingText,
  descriptionText,
  buttonText,
  buttonOnClick,
}: TransactionRejectedCardProps) => {
  const { formatMessage } = useIntl();
  const { openChat } = useIntercomChat();

  const handleClick = useCallback(() => {
    if (buttonOnClick) {
      buttonOnClick();
    } else {
      openChat();
    }
  }, [buttonOnClick, openChat]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
      <div className="mt-10 flex flex-col items-center justify-end gap-5 self-stretch">
        <AlertIcon />
        <div className="self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
          {descriptionText ?? formatMessage(m.descriptionText)}
        </div>
      </div>
      <div className="mt-24 w-full">
        <Button fullWidth onClick={handleClick} text={buttonText ?? formatMessage(m.buttonText)} />
      </div>
    </div>
  );
};

export default TransactionRejectedCard;
