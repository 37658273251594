import { FC, useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

export interface SliderProps {
  name: string;
  max: number;
  min: number;
  step: number;
  color?: 'blue' | 'green';
  externalHandler?: () => void;
}

const Slider: FC<SliderProps> = ({ name, max, min, step, color = 'blue', externalHandler }) => {
  const { register, trigger, setValue, getValues } = useFormContext();
  const { onChange, ...registration } = register(name);

  /*
    input type=[range] issue with min value and step
    https://stackoverflow.com/questions/68618465/unable-to-reach-input-type-range-to-max
  */
  const correctValue = useCallback(
    (nValue: number, nMin: number, nStep: number, nMax: number, nCorrectStep: number) => {
      //console.log('Input: ' + nValue); // DEBUG
      if (nValue === nMin + Math.floor((nMax - nMin) / nStep) * nStep) {
        // pull up to max value that is a valid value after html attributes but not a correct value after the else block below
        // console.log('Output: ' + nMax); // DEBUG
        return nMax;
      } else {
        // console.log('Output: ' + (nMin + Math.floor((nValue - nMin) / nCorrectStep) * nCorrectStep)); // DEBUG
        return nMin + Math.floor((nValue - nMin) / nCorrectStep) * nCorrectStep; // round down to next valid step by multiply nCorrectStep again after downward adjusted dividing with nCorrectStep
      }
    },
    []
  );

  const handleChange = useCallback(
    ({ target: { value: newValue } }: React.ChangeEvent<HTMLInputElement>) => {
      const currentValue = getValues(name);
      const corrected = correctValue(parseInt(newValue), step, step, max, step);
      if (corrected < min) {
        setValue(name, currentValue);
        return;
      }
      setValue(name, corrected);
      trigger(name);
    },
    [setValue, trigger, step, correctValue, max, min, name, getValues]
  );

  useEffect(() => {
    externalHandler && externalHandler();
  }, [externalHandler]);

  useEffect(() => {
    const loadStyle = async () => {
      switch (color) {
        case 'blue':
          await import('@src/common/components/slider/slider-blue.css');
          break;
        case 'green':
          await import('@src/common/components/slider/slider-green.css');
          break;
        default:
          await import('@src/common/components/slider/slider-blue.css');
          break;
      }
    };

    loadStyle();
  }, [color]);

  return (
    <input
      type="range"
      step={step}
      max={max}
      {...registration}
      onChange={handleChange}
      id="slider"
    />
  );
};

export default Slider;
