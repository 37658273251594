import clsx from 'clsx';

export interface GetClassNamesProps {
  showHeaderLoader?: boolean;
}

export const getClassNames = ({ showHeaderLoader = false }: GetClassNamesProps) => ({
  container:
    'inline-flex w-full flex-col items-start justify-start gap-12 rounded-3xl bg-white px-[13px] py-7 sm:w-[610px] sm:p-12',
  headerWrapper: 'flex items-start justify-start self-stretch pt-2',
  headerLeft: clsx('flex flex-col gap-3.5', {
    'w-2/3': showHeaderLoader,
    'w-full': !showHeaderLoader,
  }),
  headerRight: clsx('flex flex-col items-end', {
    'w-1/3': showHeaderLoader,
    hidden: !showHeaderLoader,
  }),
  title: 'flex-1 self-stretch text-2xl font-semibold leading-tight tracking-normal text-stone-950',
  subTitle: 'flex-1 self-stretch text-lg font-medium leading-normal text-black',
  cancelButton: 'bg-[rgb(190,228,239)] py-3.5 px-6 rounded-full'
});
