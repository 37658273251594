import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  footerText: {
    defaultMessage:
      'You’ll sign the document online through DocuSign. This trusted service ensures your personal information is protected and your signature is secure. ',
    description:
      '@monefit-es You’ll sign the document online through DocuSign. This trusted service ensures your personal information is protected and your signature is secure. ',
  },
});
