export enum DocumentType {
  SECCI = 'core_credit_line/SECCI',
  CREDIT_AGREEMENT = 'core_credit_line/consumer_credit_agreement',
}

export interface SessionStorageDocumentItem {
  type: DocumentType;
  id: string;
  isSigned: boolean;
}
