import { FC, useMemo } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { getClassNames } from './AgreementInput.classes';

export interface AgreementInputProps {
  name: string;
  label?: string;
  contentHtml: string;
  checkboxText: string;
  isBase64?: boolean;
  defaultValue?: boolean;
  disabled?: boolean;
}

const AgreementInput: FC<AgreementInputProps> = ({
  name,
  label,
  contentHtml,
  checkboxText,
  disabled = false,
  isBase64 = false,
}) => {
  const {
    field: { value, onChange },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: false, // default value
  });

  const c = useMemo(() => getClassNames(), []);
  const html = useMemo(
    () => (isBase64 ? decodeURIComponent(escape(atob(contentHtml))) : contentHtml),
    [contentHtml, isBase64]
  );

  return (
    <div className={c.wrapper}>
      {!!label && <div className={c.label}>{label}</div>}
      <div className={c.main}>
        <div className={c.content} dangerouslySetInnerHTML={{ __html: html }}></div>
        <div className={c.footer}>
          <input
            type="checkbox"
            className={c.checkbox}
            checked={value}
            onChange={(e) => {
              onChange(e);
            }}
            disabled={disabled}
          />
          <label htmlFor={name}>{checkboxText}</label>
        </div>
      </div>
      {error && <div className={c.error}>{error.message}</div>}
    </div>
  );
};

export default AgreementInput;
