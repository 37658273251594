import { defineMessages } from 'react-intl';

export default defineMessages({
  emailLabel: {
    defaultMessage: 'E-mail',
  },
  phoneLabel: {
    defaultMessage: 'Mobile',
  },
  btnText: {
    defaultMessage: 'Contact to edit',
  },
  tooltipText: {
    defaultMessage: 'Contact support to edit details',
  },
});
