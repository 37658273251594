import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  personalDetails: {
    defaultMessage: 'Personal details',
  },
  accountSettings: {
    defaultMessage: 'Account settings',
  },
  aboutUs: {
    defaultMessage: 'About us',
  },
  getHelp: {
    defaultMessage: 'Get help',
  },
  logout: {
    defaultMessage: 'Log out',
  },
});
