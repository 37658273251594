import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'What’s your phone number?',
    description: '@monefit-es What’s your phone number?',
  },
  labelText: {
    defaultMessage: 'Phone number',
  },
  inputDescriptionText: {
    defaultMessage: 'We’ll send a verification code to this phone number',
    description: '@monefit-es We’ll send a verification code to this phone number',
  },
  submitButtonText: {
    defaultMessage: 'Continue',
  },
  formMinValidationText: {
    defaultMessage: 'Your phone number should be 9 digits long',
    description: '@monefit-es Your phone number should be 9 digits long',
  },
  formMaxValidationText: {
    defaultMessage: 'Your phone number should be 9 digits long',
    description: '@monefit-es Your phone number should be 9 digits long',
  },
  formMatchValidationText: {
    defaultMessage: 'Phone numbers need to start with “6” or “7”',
    description: '@monefit-es Phone numbers need to start with “6” or “7”',
  },
  formRequiredValidationText: {
    defaultMessage: 'Please enter your phone number',
    description: '@monefit-es Please enter your phone number',
  },
});
