import { Country } from '@common/hooks';

import { MissingImplementationError } from './errors';

/**
 * Calculates the check letter for a given number
 *
 * @param number - the number to calculate the check letter for
 * @returns the check letter
 */
export const calculateNIFCheckLetter = (number: number): string => {
  const letters = 'TRWAGMYFPDXBNJZSQVHLCKE';
  return letters.charAt(number % 23);
};

/**
 * Validates Spanish personal code (NIF or NIE)
 *
 * @param personalCode - the personal code to validate
 * @returns true if the personal code is valid, false otherwise
 */
export const validateNIF = (nif: string): boolean => {
  // Regular expressions for NIF (Spanish nationals) and NIE (foreign nationals)
  const nifRegex = /^[0-9]{8}[A-Z]$/;
  const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;

  // Character map for NIE
  const nieCharMap: { [key: string]: string } = { X: '0', Y: '1', Z: '2' };

  // Validate NIF format
  if (nifRegex.test(nif)) {
    const numberPart = parseInt(nif.slice(0, 8), 10);
    const letterPart = nif.charAt(8);
    return calculateNIFCheckLetter(numberPart) === letterPart;
  }

  // Validate NIE format
  if (nieRegex.test(nif)) {
    const niePrefix = nif.charAt(0);
    const numberPart = parseInt(nieCharMap[niePrefix] + nif.slice(1, 8), 10);
    const letterPart = nif.charAt(8);
    return calculateNIFCheckLetter(numberPart) === letterPart;
  }

  return false;
};

/**
 * Validates a personal code based on the country
 *
 * @param personalCode - the personal code to validate
 * @param country - the country to validate the personal code for
 * @returns true if the personal code is valid, false otherwise
 * @throws MissingImplementationError if the country is not supported
 */
export const validatePersonalCode = (personalCode: string, country: Country): boolean => {
  if (country === Country.ES) {
    return validateNIF(personalCode);
  }

  throw new MissingImplementationError(
    'Personal code validation is not implemented for this country'
  );
};
