import { SubscriptionName } from '@common/enums';
import useSubsciptions from '@common/hooks/useSubscriptions';
import { TRANSITION_ANIMATION } from '@src/assets/animations/motion.animations';
import { motion } from 'framer-motion';

import DashboardBalance from '../dashboard-balance/DashboardBalance';
import DashboardFAQ from '../dashboard-faq/DashboardFaq';
import DashboardInvoiceSection from '../dashboard-invoice-section/DashboardInvoiceSection';

const Dashboard = () => {
  const { getHasActiveSubsctiption } = useSubsciptions();
  const subscriptionEnabled = getHasActiveSubsctiption(SubscriptionName.CREDIT_LINE_PREMIUM);
  // const showUpgradeSubscriptionItems = useMemo(() => hasSubscription === false, [hasSubscription]);

  return (
    <motion.div
      {...TRANSITION_ANIMATION}
      className="my-3 flex w-full flex-col gap-2 sm:max-w-[735px]"
    >
      {/* {showUpgradeSubscriptionItems && (
        <div className="inline-flex flex-col items-start justify-start gap-[5px] rounded-t-[28px] bg-blue-alt px-6 pb-7 pt-3.5 sm:px-12 sm:py-6">
          <div className="mb-4 inline-flex items-center justify-between gap-3 self-stretch sm:gap-5">
            <div className="text-lg font-semibold sm:text-xl">{formatMessage(m.headTitle)}</div>
            <Button
              className="group rounded-full border border-transparent bg-black px-4 py-3 hover:border hover:border-black hover:bg-white hover:text-black"
              buttonNode={
                <div className="bg-gradient-to-r from-cyan-200 to-white bg-clip-text text-transparent group-hover:text-black">
                  {formatMessage(m.upgadeBtnText)}
                </div>
              }
            />
          </div>
        </div>
      )} */}
      <DashboardBalance />
      <DashboardInvoiceSection hasSubscription={subscriptionEnabled} />
      <DashboardFAQ />

      <div className="flex w-full flex-col gap-2 sm:flex-row">
        {/* {showUpgradeSubscriptionItems && (
          <div className="flex w-full flex-col items-start justify-between gap-14 rounded-3xl bg-white px-6 py-7 sm:p-12 ">
            <div className="flex h-20 flex-col items-start justify-start gap-5 self-stretch">
              <div className="inline-flex items-start justify-start gap-5 self-stretch">
                <div className="inline-flex flex-col items-start justify-start gap-2.5">
                  <div className="text-lg font-semibold leading-normal text-black">
                    {formatMessage(m.upgradeToPremium)}
                  </div>
                  <div className="h-11 overflow-hidden text-ellipsis text-base font-normal text-black">
                    {formatMessage(m.premiumDescription)}
                  </div>
                </div>
              </div>
            </div>
            <Button
              extraClassNames="text-lg"
              color="gray-alt"
              fullWidth
              text={formatMessage(m.upgadeBtnText)}
            />
          </div>
        )} */}
      </div>
    </motion.div>
  );
};

export default Dashboard;
