import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import BackButton from '@common/components/button/BackButton';
import Button from '@common/components/button/Button';
import {
  getOtpRemaingTimeFromStorage,
  saveOtpRemaingTimeToStorage,
} from '@common/components/otp-code-form/OtpCodeForm.helpers';
import OtpCodeInput from '@common/components/otp-code-input/OtpCodeInput';
import Timer from '@common/components/otp-code-input/Timer';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';

const RESEND_TIME = 5 * 60; // 5 min

export enum OtpFormType {
  EMAIL = 'email',
  PHONE = 'phone',
}
const SUBMIT_OPTIONS = {
  [OtpFormType.EMAIL]: {
    key: 'emailOtpCode',
    submitTrackEvent: TrackEvent.EMAIL_OTP_PAGE_SUBMIT,
    resendTrackEvent: TrackEvent.EMAIL_OTP_PAGE_CODE_RESEND,
    errorEvent: TrackEvent.EMAIL_OTP_PAGE_ERROR,
    codeEnteredEvent: TrackEvent.EMAIL_OTP_PAGE_CODE_ENTERED,
  },
  [OtpFormType.PHONE]: {
    key: 'phoneOtpCode',
    submitTrackEvent: TrackEvent.PHONE_OTP_PAGE_SUBMIT,
    resendTrackEvent: TrackEvent.PHONE_OTP_PAGE_CODE_RESEND,
    errorEvent: TrackEvent.PHONE_OTP_PAGE_ERROR,
    codeEnteredEvent: TrackEvent.PHONE_OTP_PAGE_CODE_ENTERED,
  },
};

interface IProps {
  emailOrPhone: string;
  type: OtpFormType;
  resendTime?: number;
}

/**
 * OtpCodeForm component
 * TODO: delete and replace usage with "common/components/otp-code-form/OtpCodeForm"
 */
const OtpCodeForm = ({ emailOrPhone, type, resendTime = RESEND_TIME }: IProps) => {
  const { data } = useSelector((st) => st.application.flowInstance);
  const { trackEvent } = useTracker({});
  const [instanceId] = useApplicationFlowInstance();
  const intl = useIntl();
  const submitOptions = SUBMIT_OPTIONS[type];
  const { key, submitTrackEvent, resendTrackEvent, errorEvent, codeEnteredEvent } = submitOptions;

  const [otpCode, setOtpCode] = useState('');
  const [resendTimeRemaining, setResendTimeRemaining] = useState(0);
  const dispatch = useDispatch<RootDispatch>();
  const storedTime = getOtpRemaingTimeFromStorage(type);

  useEffect(() => {
    const time = storedTime === undefined ? resendTime : storedTime;
    setResendTimeRemaining(time);
  }, [storedTime, resendTime]);
  const { REACT_APP_ENVIRONMENT } = process.env;

  const codeLength = useMemo(() => (type === OtpFormType.EMAIL ? 4 : 5), [type]);

  const handleCodeSubmit = useCallback(async () => {
    try {
      dispatch(
        performApplicationFlowAction({
          instanceId,
          data: { [key]: otpCode },
          action: FlowActions.SUBMIT,
        })
      );
      setResendTimeRemaining(resendTime);
      saveOtpRemaingTimeToStorage(resendTime, type);
      trackEvent(submitTrackEvent);
    } catch (_) {
      trackEvent(errorEvent);
    }
  }, [
    otpCode,
    dispatch,
    errorEvent,
    submitTrackEvent,
    instanceId,
    resendTime,
    key,
    type,
    trackEvent,
  ]);

  const handleResend = useCallback(() => {
    try {
      dispatch(
        performApplicationFlowAction({
          instanceId,
          action: FlowActions.RESEND,
        })
      ).then(() => {
        const message =
          type === OtpFormType.EMAIL
            ? intl.formatMessage({
                defaultMessage: 'We’ve sent a new code to your email',
                description: 'OTP code resend message email',
              })
            : intl.formatMessage({
                defaultMessage: 'We’ve sent a new code to your phone',
                description: 'OTP code resend message phone',
              });
        toast(message, { type: 'success', toastId: message });
      });
      trackEvent(resendTrackEvent);
    } catch (_) {
      trackEvent(errorEvent);
    }
  }, [dispatch, instanceId, trackEvent, resendTrackEvent, errorEvent, type, intl]);

  const handleCodeChange = useCallback(
    (val: string) => {
      setOtpCode(val);
      if (val.length === codeLength) {
        trackEvent(codeEnteredEvent);
      }
    },
    [trackEvent, codeEnteredEvent, codeLength]
  );

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleCodeSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleCodeSubmit]);

  return (
    <SignupBaseCard
      headerText={intl.formatMessage({
        defaultMessage: 'Verification code',
        description: 'OtpCode: "Verification code"',
      })}
      subHeaderText={intl.formatMessage(
        {
          defaultMessage: 'We sent four digit code to {emailOrPhone}',
          description: 'OtpCode: "We sent four digit code to"',
        },
        { emailOrPhone }
      )}
    >
      {['development', 'staging'].includes(REACT_APP_ENVIRONMENT) && (
        <pre className="text-sm">Code: {(data?.responseData as any)?.otpCode}</pre>
      )}
      <div className="flex w-full flex-col gap-3">
        <OtpCodeInput {...{ otpCode, handleCodeChange }} codeLength={codeLength} />
        <Timer
          limit={resendTime}
          timeRemaining={resendTimeRemaining}
          setTimeRemaining={setResendTimeRemaining}
          handleResend={handleResend}
          otpFormType={type}
        />
      </div>

      <div className="flex w-full gap-3">
        <BackButton />
        <Button
          text={intl.formatMessage({
            defaultMessage: 'Continue',
            description: 'OtpCode: Continue button',
          })}
          disabled={otpCode.length < codeLength}
          fullWidth
          onClick={handleCodeSubmit}
        />
      </div>
    </SignupBaseCard>
  );
};

export default OtpCodeForm;
