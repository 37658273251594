import { useCallback, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

import { LINKS } from '@common/constants';

import { useCookieConsents } from './useCookieConsents';
import useEnvironment from './useEnvironment';

const useIntercomChat = () => {
  const { isDevOrStagingEnv } = useEnvironment();
  const { showNewMessage } = useIntercom();

  const { consents, loaded: consentsLoaded } = useCookieConsents();

  const canOpenIntercom = useMemo(
    () => (isDevOrStagingEnv ? true : consentsLoaded && consents.necessary),
    [isDevOrStagingEnv, consentsLoaded, consents.necessary]
  );

  const openChat = useCallback(
    (prePopulatedContent?: string) => {
      if (canOpenIntercom) {
        showNewMessage(prePopulatedContent);
      } else {
        window.location.href = LINKS.ES_MAILTO;
      }
    },
    [canOpenIntercom, showNewMessage]
  );

  return {
    canOpenIntercom,
    openChat,
  };
};

export default useIntercomChat;
