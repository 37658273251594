import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import { CurrencySymbol } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useLocale } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getUserData, stepIdToPath } from '@common/utils';
import { MIN_WITHDRAWAL_AMOUNT } from '@monefit-es/constants/withdrawal-limit';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import whiteDots from '@src/assets/animations/Dots-animation.json';
import clsx from 'clsx';
import Lottie from 'lottie-react';

import { getClassNames } from '../dashboard/Dashboard.classes';
import m from '../dashboard/Dashboard.messages';

interface BalanceDisplayProps {
  noData?: boolean;
  value?: number;
}

const DashboardBalance = () => {
  const { formatMessage } = useIntl();

  const dispatch = useDispatch<RootDispatch>();
  const navigate = useNavigate();
  const { limitData, limitError, limitLoading } = useLimitAndBalance({
    disableAgreementFetch: true,
    disableBalanceFetch: true,
  });
  const [, setStoredInstanceId] = useApplicationFlowInstance();
  const {
    user: { id },
  } = getUserData();
  const { localeWithCountry } = useLocale();

  const noLimitData = useMemo(
    () =>
      (limitError || limitData?.available === undefined || limitData.available === null) &&
      !limitLoading,
    [limitError, limitData, limitLoading]
  );

  const disableWithdrawalBtn = useMemo(
    () => noLimitData || limitLoading || (limitData?.available ?? 0) < MIN_WITHDRAWAL_AMOUNT,
    [limitData?.available, noLimitData, limitLoading]
  );

  const disableDetailsBtn = useMemo(() => limitLoading, [limitLoading]);

  const handleDrawdownStart = useCallback(async () => {
    const { REACT_APP_ES_DRAWDOWN_FLOW_ID } = process.env;
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        flowId: REACT_APP_ES_DRAWDOWN_FLOW_ID,

        data: {
          userId: id,
          locale: localeWithCountry,
        },
      })
    )
      .unwrap()
      .then((r) => {
        setStoredInstanceId(r.id);
        navigate(
          `/${localeWithCountry}/${REACT_APP_ES_DRAWDOWN_FLOW_ID}/${stepIdToPath(r.currentStepId)}`
        );
      });
  }, [id, localeWithCountry, navigate, dispatch, setStoredInstanceId]);

  const handleCreditDetailsClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user/credit-details`);
  }, [navigate, localeWithCountry]);

  const classNames = useMemo(() => getClassNames(), []);

  return (
    <div className={clsx('-mt-7 bg-black', classNames.card)}>
      <div className="flex w-full items-start justify-between">
        <div className="flex flex-col items-start justify-start gap-1 ">
          <div className="text-lg font-semibold leading-tight tracking-wide text-white">
            {formatMessage(m.availableCredit)}
          </div>
          <div className="font-heavy text-3xl font-black leading-10 text-white sm:text-6xl">
            {limitLoading ? (
              <Lottie animationData={whiteDots} style={{ height: 60 }} />
            ) : (
              <BalanceDisplay noData={noLimitData} value={limitData?.available} />
            )}
          </div>
        </div>
        <Button
          onClick={handleCreditDetailsClick}
          className={clsx('px-5 py-3 text-black ', classNames.button, {
            [classNames.buttonDisabledBg]: disableDetailsBtn,
            'bg-white': !disableDetailsBtn,
            [classNames.balanceButtonsHover]: !disableDetailsBtn,
          })}
          buttonNode={<>{formatMessage(m.detailsBtnText)}</>}
        />
      </div>
      <Button
        onClick={handleDrawdownStart}
        disabled={disableWithdrawalBtn}
        className="w-full"
        buttonNode={
          <div
            className={clsx('w-full  px-3 py-4 text-xl text-black', classNames.button, {
              [classNames.buttonDisabledBg]: disableWithdrawalBtn,
              'bg-white': !disableWithdrawalBtn,
              [classNames.balanceButtonsHover]: !disableWithdrawalBtn,
            })}
          >
            {formatMessage(m.withdrawBtnText)}
          </div>
        }
      />
    </div>
  );
};

export default DashboardBalance;

const BalanceDisplay: FC<BalanceDisplayProps> = ({ value, noData }) => {
  const { formatMessage, formatNumber } = useIntl();
  const classNames = useMemo(() => getClassNames(), []);
  const separator = ',';

  const formattedValue = useMemo(
    () =>
      formatNumber(value ?? 0, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    [formatNumber, value]
  );

  const [integerPart, decimalPart] = useMemo(
    () => formattedValue.split(separator),
    [separator, formattedValue]
  );

  if (noData) {
    return (
      <div className={classNames.balanceDisplayContainer}>
        {formatMessage({ defaultMessage: 'N/A' })}
      </div>
    );
  }

  return (
    <div className={classNames.balanceDisplayContainer}>
      {integerPart}
      <span className="text-xl sm:text-4xl">
        {`${separator}${decimalPart} ${CurrencySymbol.EUR}`}
      </span>
    </div>
  );
};
