import AgreementInput from '@common/components/agreement-input/AgreementInput';
import ButtonGroupInput from '@common/components/button-group-input/ButtonGroupInput';
import Checkbox from '@common/components/checkbox/Checkbox';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import DateInput from '@common/components/date-input/DateInput';
import PhoneInput from '@common/components/phone-input/PhoneInput';
import SelectInput from '@common/components/select-input/SelectInput';
import TextInput from '@common/components/text-input/TextInput';
import YesNoInput from '@common/components/yes-no-input/YesNoInput';
import { FormFieldConfig } from '@common/types';

export interface RenderFormFieldProps {
  field: FormFieldConfig;
}

/**
 * Helper for dynamically generating form fields based on the provided configuration.
 */
export const renderFormField = ({ field }: RenderFormFieldProps): JSX.Element | null => {
  switch (field.fieldType) {
    case 'text':
      return <TextInput {...field} />;
    case 'select':
      return <SelectInput {...field} />;
    case 'buttonGroup':
      return <ButtonGroupInput {...field} />;
    case 'checkbox':
      return <Checkbox {...field} />;
    case 'currency':
      return <CurrencyInput {...field} />;
    case 'yesNo':
      return <YesNoInput {...field} />;
    case 'date':
      return <DateInput {...field} />;
    case 'agreement':
      return <AgreementInput {...field} />;
    case 'phone':
      return <PhoneInput {...field} />;
    default:
      return null;
  }
};
