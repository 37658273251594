import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';
import loader from '@src/assets/img/monefit-ee/bank_loader.webp';

const DecisionLoadingView = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const { loading, data } = useSelector((st) => st.application.flowInstance);
  const [canCheck, setCanCheck] = useState(true);

  const checkBank = useCallback(async () => {
    dispatch(setShowLoader(false));
    if (loading || data?.currentStepId !== FlowSteps.BANK || !canCheck) {
      return;
    }
    await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CHECK,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.currentStepId === FlowSteps.DECISION) {
          setCanCheck(false);
        }
      });
  }, [dispatch, instanceId, canCheck, data?.currentStepId]);

  useEffect(() => {
    const intervalId = setInterval(checkBank, 3000);
    return () => clearInterval(intervalId);
  }, [checkBank]);

  return (
    <LoadingCard
      imgSrcProp={loader}
      text={formatMessage({
        defaultMessage: 'We are processing your data.',
        description: 'Decision: "We are processing your data."',
      })}
      extraText={
        <FormattedMessage
          defaultMessage={
            '<p>This will take just a few minutes.{br} Please do not close the page.</p>'
          }
          values={{
            p: (...chunks) => <p>{chunks}</p>,
            br: <br />,
          }}
        />
      }
    />
  );
};

export default DecisionLoadingView;
