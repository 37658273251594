import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { userServiceBaseUrl } = getCoreServiceBaseUrls();
export const userService = axios.create({
  baseURL: userServiceBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});
