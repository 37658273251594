import { defineMessages } from 'react-intl';

export default defineMessages({
  part_time: {
    defaultMessage: 'I work part time',
  },
  full_time: {
    defaultMessage: 'I work full time',
  },
  self_employed: {
    defaultMessage: 'I’m self-employed',
  },
  social_benefits: {
    defaultMessage: 'Social benefits',
  },
  interest_from_Investment: {
    defaultMessage: 'Interest from an investment',
  },
  rent: {
    defaultMessage: 'Rent',
  },
  pension: {
    defaultMessage: 'Pension',
  },
  unemployed: {
    defaultMessage: 'I’m unemployed',
  },
});
