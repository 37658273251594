import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  backToDashboard: {
    defaultMessage: 'Account',
  },
  personalDetails: {
    defaultMessage: 'Personal details',
  },
});
