import { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import clsx from 'clsx';

import CopyIcon2 from '../icons/CopyIcon2';
import styles from '../text-input/TextInput.styles';

interface AccountIdFieldProps {
  value: string;
  label: string;
}

const AccountIdField: FC<AccountIdFieldProps> = ({ value, label }) => {
  const { formatMessage } = useIntl();

  const handleCopyClick = useCallback(async () => {
    await navigator.clipboard.writeText(value);
    toast.success(
      formatMessage({ defaultMessage: 'ID copied', description: '@monefit-es ID copied' })
    );
  }, [value, formatMessage]);

  return (
    <div className={clsx(styles.base, styles.account, 'flex items-center justify-between')}>
      <div className="flex flex-col text-neutral-400">
        <span className="text-xs text-gray-500">{label}</span>
        <span className="max-w-[220px] truncate md:max-w-[315px]">{value}</span>
      </div>
      <div className="shrink-0">
        <button onClick={handleCopyClick}>
          <CopyIcon2 />
        </button>
      </div>
    </div>
  );
};

export default AccountIdField;
