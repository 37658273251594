import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/user';

export const makeSelectUser = (state: RootState) => ('user' in state ? state.user : initialState);

export const selectUserLoading = createSelector(makeSelectUser, (user) => user.loading);
export const selectUserError = createSelector(makeSelectUser, (user) => user.error);

export const selectUserData = createSelector(makeSelectUser, (user) => user.data?.user);
