import { ReactNode, useCallback, useEffect, useMemo, useRef } from 'react';
import { useIntl } from 'react-intl';
import ReactModal, { Props } from 'react-modal';
import { useSelector } from 'react-redux';

import Button from '@common/components/button/Button';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
import { selectFlowInstanceLoading } from '@common/redux/selectors';
import blackDots from '@src/assets/animations/Dots-animation-black.json';
import Lottie from 'lottie-react';

interface ModalProps extends Props {
  children: ReactNode;
  onBackClick: () => void;
  onAcceptClick: () => void;
}

// TODO: fallback for storybook, later can be just => ReactModal.setAppElement('#root');
const rootDivExisting = document.getElementById('root');
if (!rootDivExisting) {
  const rootDivCreated = document.createElement('div');
  rootDivCreated.id = 'root';
  ReactModal.setAppElement(rootDivCreated);
} else {
  ReactModal.setAppElement(rootDivExisting);
}

const DocumentsModal = ({ children, onBackClick, onAcceptClick, ...props }: ModalProps) => {
  const breakpoint = useTailwindBreakpoints();
  const applicationLoading = useSelector(selectFlowInstanceLoading);
  const { formatMessage } = useIntl();
  useEffect(() => {
    props?.isOpen
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto');
  }, [props.isOpen]);

  const isMobile = useMemo(
    () => !!breakpoint && [TWBreakPoint.SM, TWBreakPoint.XS].includes(breakpoint),
    [breakpoint]
  );

  const scrollToRef = useRef<HTMLDivElement | null>(null);

  const scrollToAccept = useCallback(() => {
    if (scrollToRef?.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  return (
    <ReactModal
      className="max-h-[80vh] w-full max-w-[610px] overflow-y-auto rounded-3xl bg-white  outline-none"
      overlayClassName="modal-overlay"
      overlayElement={(props, contentEl) => (
        <div {...props} className="modal-overlay flex flex-col gap-6 p-2">
          <div className="flex w-full flex-col items-center">
            {applicationLoading ? (
              <div>
                <Lottie animationData={blackDots} style={{ height: 60 }} />
              </div>
            ) : (
              <>
                <div className="mb-3 flex w-full max-w-[610px] justify-between">
                  <Button
                    onClick={onBackClick}
                    type="button"
                    color="gray-alt"
                    text={formatMessage({
                      defaultMessage: 'Back',
                      description: 'Back button text',
                    })}
                  />
                  {!isMobile && (
                    <Button
                      onClick={onAcceptClick}
                      text={formatMessage({ defaultMessage: 'Accept' })}
                    />
                  )}
                  {isMobile && (
                    <Button
                      onClick={scrollToAccept}
                      text={formatMessage({ defaultMessage: 'Next' })}
                    />
                  )}
                </div>
                {contentEl}
              </>
            )}
          </div>
        </div>
      )}
      {...props}
    >
      <div className="flex w-full flex-col gap-3">
        {children}
        {isMobile && (
          <div ref={scrollToRef} className="flex w-full p-12">
            <Button
              fullWidth
              onClick={onAcceptClick}
              text={formatMessage({ defaultMessage: 'Accept' })}
            />
          </div>
        )}
      </div>
    </ReactModal>
  );
};

export default DocumentsModal;
