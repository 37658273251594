import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/authentication';

const makeSelectAuthentication = (state: RootState) =>
  'authentication' in state ? state.authentication : initialState;

export const selectAuthentication = createSelector(
  makeSelectAuthentication,
  (authentication) => authentication
);

export const selectAuthenticationLoading = createSelector(
  makeSelectAuthentication,
  (authentication) => authentication.loading
);
