import { useCallback, useEffect, useMemo } from 'react';

import { useApplicationFlowInstance, useDispatch } from '@common/hooks';
import useError from '@common/hooks/useError';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { ErrorBoundaryPage } from '@monefit-ee/pages';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';

const DrawdownDecisionPage = () => {
  const { errors } = useError();
  const hasError = useMemo(() => !!errors.length, [errors]);
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();

  const submitDecision = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({ action: FlowActions.SUBMIT, instanceId })
    ).unwrap();
  }, [instanceId, dispatch]);

  useEffect(() => {
    submitDecision();
  }, []);

  return (
    <MainLayout hideHeaderAndFooter hideProgressBar>
      {hasError ? <ErrorBoundaryPage hasSignupLayout={true} /> : <MainLoader />}
    </MainLayout>
  );
};

export default DrawdownDecisionPage;
