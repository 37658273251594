import React, { ReactNode, useMemo } from 'react';

import classNames from 'classnames';

export enum ButtonSpacing {
  NONE = 'none',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  TAB_SPACING = 'tab-spacing',
  BUTTON_GROUP = 'button-group',
  UNDEFINED = 'undefined',
}

const TEXT_SIZE_CLASSES = {
  xs: 'text-xs',
  sm: 'text-sm',
  base: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
};

const COLOR_CLASSES = {
  blue: 'bg-blue-brand text-black',
  'blue-light': 'bg-blue-brand-light text-black',
  black: 'bg-black text-white disabled:text-gray-500',
  transparent: 'bg-transparent text-black',
  'underlined-black': 'font-medium leading-tight tracking-tight underline disabled:text-gray-500',
  gray: 'bg-stone-100 text-black',
  green: 'bg-green-brand  text-black',
  'dark-gray': 'bg-[#EEE6DB] text-black',
  'blue-alt': 'bg-blue-alt text-black',
  'gray-alt': 'bg-gray-alt text-black',
  white: 'bg-white text-black disabled:text-opacity-50',
};

const PADDING_CLASSES = {
  [ButtonSpacing.UNDEFINED]: '',
  [ButtonSpacing.NONE]: 'px-0 py-0',
  [ButtonSpacing.SM]: 'px-6 py-2.5',
  [ButtonSpacing.MD]: 'px-7 py-4',
  [ButtonSpacing.LG]: 'px-9 py-4',
  [ButtonSpacing.XL]: 'px-8 py-7',
  [ButtonSpacing.TAB_SPACING]: 'px-3 py-2.5 md:px-6 md:py-3.5',
  [ButtonSpacing.BUTTON_GROUP]: 'px-[2px] py-0 sm:px-6 sm:py-3.5',
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  spacing?: ButtonSpacing;
  fullWidth?: boolean;
  color?:
    | 'black'
    | 'blue'
    | 'blue-light'
    | 'transparent'
    | 'underlined-black'
    | 'gray'
    | 'green'
    | 'dark-gray'
    | 'blue-alt'
    | 'gray-alt'
    | 'white';

  roundedFull?: boolean;
  extraClassNames?: string;
  icon?: ReactNode;
  textSize?: 'xs' | 'sm' | 'base' | 'lg' | 'xl';
  borderClassName?: string;
  hoverClassName?: string;
  buttonNode?: ReactNode;
}

function Button({
  text,
  color = 'black',
  fullWidth = false,
  roundedFull = true,
  spacing = ButtonSpacing.MD,
  extraClassNames,
  icon,
  buttonNode,
  borderClassName,
  hoverClassName,
  textSize = 'base',
  ...restProps
}: ButtonProps): JSX.Element {
  const colorClass = COLOR_CLASSES[color];
  const paddingClass = PADDING_CLASSES[spacing];
  const textClass = TEXT_SIZE_CLASSES[textSize];

  const defaultHoverClassName = useMemo(() => {
    switch (color) {
      case 'black':
        return 'hover:bg-white hover:border-2 hover:border-black hover:text-black';
      case 'gray-alt':
        return 'hover:bg-black hover:text-white';
      default:
        return 'transition-transform transform hover:scale-105 active:scale-100';
    }
  }, [color]);

  return buttonNode ? (
    <button {...{ ...restProps }}>{buttonNode}</button>
  ) : (
    <button
      className={classNames(
        roundedFull ? 'rounded-full' : 'rounded-2xl',
        fullWidth ? 'w-full' : '',
        borderClassName ?? 'border-2 border-transparent',
        restProps?.disabled ? '' : hoverClassName || defaultHoverClassName,
        paddingClass,
        colorClass,
        textClass,
        extraClassNames ?? ''
      )}
      {...{ ...restProps }}
    >
      <div className="flex size-full items-center justify-center gap-2">
        {icon && icon}
        <span>{text}</span>
      </div>
    </button>
  );
}

export default Button;
