import { Route } from 'react-router-dom';

import ApplicationRouter from '@common/router/ApplicationRouter';
import { FlowSteps } from '@common/services/application';

const FLOW_STEPS: FlowSteps[] = [
  FlowSteps.EMAIL,
  FlowSteps.EMAIL_OTP,
  FlowSteps.PURPOSE,
  FlowSteps.BASIC_INFO,
  FlowSteps.ADDRESS,
  FlowSteps.FINANCES,
  FlowSteps.BANK,
  FlowSteps.DECISION,
  FlowSteps.DECLINED,
  FlowSteps.WITHDRAWAL,
  FlowSteps.DOCUMENTS,
  FlowSteps.PHONE,
  FlowSteps.PHONE_OTP,
  FlowSteps.PASSWORD,
  FlowSteps.END,
];

const Router = () => {
  return (
    <ApplicationRouter flowSteps={FLOW_STEPS}>
      <Route path="*" element={<div>Page not found</div>} />
    </ApplicationRouter>
  );
};

export default Router;
