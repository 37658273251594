import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';

import useError from '@common/hooks/useError';
import { selectFlowInstance } from '@common/redux/selectors';
import { ApiError } from '@common/utils';

export interface ErrorProviderProps {
  children: React.ReactNode;
}

const ErrorProvider: FC<ErrorProviderProps> = ({ children }) => {
  const { error, loading } = useSelector(selectFlowInstance);
  const apiError = useMemo(() => (error ? new ApiError() : null), [error]);
  const { errors, notifyInstanceErrors, notifyApiError } = useError();

  if (errors.length && !loading) {
    notifyInstanceErrors();
  }

  if (apiError && !errors.length && !loading) {
    notifyApiError();
  }

  return <>{children}</>;
};

export default ErrorProvider;
