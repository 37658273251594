import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import SuccessIcon from '@common/components/icons/SuccessIcon';
import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useMonthioUrlParams from '@common/hooks/useMonthioUrlParams';

import m from './PaymentSuccessPage.messages';

const PaymentSuccessPage = () => {
  const { paymentId, paymentSessionId } = useMonthioUrlParams();
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const navigate = useNavigate();

  const handleBackClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`);
  }, [navigate, localeWithCountry]);

  if (!paymentId && !paymentSessionId) {
    return <Navigate to={`/${localeWithCountry}/user`} />;
  }

  return (
    <Layout.WithNavigation
      maxWDesktop={545}
      backButtonText={formatMessage(m.headingText)}
      onBackButtonClick={handleBackClick}
    >
      <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
        <div className="mt-10 flex flex-col items-center justify-end gap-5 self-stretch">
          <SuccessIcon />
          <div className="self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
            {formatMessage(m.descriptionText)}
          </div>
        </div>
        <div className="mt-24 w-full">
          <Button fullWidth onClick={handleBackClick} text={formatMessage(m.buttonText)} />
        </div>
      </div>
    </Layout.WithNavigation>
  );
};

export default PaymentSuccessPage;
