import { FC, JSX, useCallback, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { useApplicationFlowInstance, useApplicationRoutingPageMap, useLocale } from '@common/hooks';
import { useDispatch, useSelector } from '@common/hooks';
import { ApplicationFlowStartPage } from '@common/pages';
import ApplicationFlowDrawdownStartPage from '@common/pages/application-flow-drawdown-start-page/ApplicationFlowDrawdownStartPage';
import { RootDispatch } from '@common/redux';
import {
  fetchApplicationFlowInstance,
  performApplicationFlowAction,
} from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';
import { isUUID } from '@common/utils/uuid';

import ApplicationRouteHandler from './ApplicationRouteHandler';

export interface ApplicationRouterProps {
  flowSteps: FlowSteps[];
  children?: JSX.Element;
  basePath?: string;
}

const ApplicationRouter: FC<ApplicationRouterProps> = ({ children, flowSteps = [] }) => {
  const { REACT_APP_ENVIRONMENT } = process.env;
  const { localeWithCountry: localeParamWithCountry } = useLocale();
  const dispatch = useDispatch<RootDispatch>();
  const [pageMap] = useApplicationRoutingPageMap(flowSteps);
  const {
    loading,
    data,
    meta: { currentSubStepId, enableGetRequest },
  } = useSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const location = useLocation();

  const getInstanceIdFromUrl = useCallback(() => {
    const url = location.pathname;
    const paths = url.split('/');
    const possibleInstanceId = paths[paths.length - 1];
    return isUUID(possibleInstanceId) ? possibleInstanceId : null;
  }, [location.pathname]);

  useEffect(() => {
    const instanceIdToQuery = getInstanceIdFromUrl() ?? instanceId ?? null;
    if (instanceIdToQuery && !loading && enableGetRequest) {
      dispatch(fetchApplicationFlowInstance(instanceIdToQuery))
        .unwrap()
        .then(({ currentStepId }) => {
          // Start flow by requesting providers
          if (currentStepId === FlowSteps.IDENTIFICATION && !currentSubStepId) {
            dispatch(
              performApplicationFlowAction({
                instanceId: instanceIdToQuery,
                action: FlowActions.PROVIDERS,
              })
            );
          }
        })
        // Reset stored instanceId
        .catch((e) => console.error(e));
    }
  }, [dispatch, instanceId, currentSubStepId, enableGetRequest, getInstanceIdFromUrl]);

  const handleHotJarIdentify = useCallback(() => {
    if (window?.hj && typeof window?.hj === 'function') {
      window.hj('identify', instanceId, {
        session_id: data?.sessionId ?? null,
        instance_id: instanceId,
      });
    }
  }, [instanceId, data]);

  useEffect(() => {
    handleHotJarIdentify();
  }, [handleHotJarIdentify]);

  return (
    <Routes>
      <Route
        path={`${localeParamWithCountry}/application/start/:instanceId?`} // TODO: url params enum definition
        element={
          <ApplicationRouteHandler localePath={localeParamWithCountry ?? ''} flowSteps={flowSteps}>
            {['development', 'staging'].includes(REACT_APP_ENVIRONMENT) ? (
              <ApplicationFlowStartPage />
            ) : undefined}
          </ApplicationRouteHandler>
        }
      />
      <Route
        path={`${localeParamWithCountry}/drawdown/start/:instanceId?`}
        element={
          <ApplicationRouteHandler localePath={localeParamWithCountry ?? ''} flowSteps={flowSteps}>
            {['development'].includes(REACT_APP_ENVIRONMENT) ? (
              <ApplicationFlowDrawdownStartPage />
            ) : undefined}
          </ApplicationRouteHandler>
        }
      />
      <Route
        path={`${localeParamWithCountry}/:flowIdOrType/start/:instanceId?`} // TODO: url params enum definition
        element={
          <ApplicationRouteHandler localePath={localeParamWithCountry ?? ''} flowSteps={flowSteps}>
            {['development', 'staging'].includes(REACT_APP_ENVIRONMENT) ? (
              <ApplicationFlowStartPage />
            ) : undefined}
          </ApplicationRouteHandler>
        }
      />
      <Route
        path={`${localeParamWithCountry}/:flowIdOrType/start/:instanceId?`}
        element={
          <ApplicationRouteHandler localePath={localeParamWithCountry ?? ''} flowSteps={flowSteps}>
            {['development'].includes(REACT_APP_ENVIRONMENT) ? (
              <ApplicationFlowDrawdownStartPage />
            ) : undefined}
          </ApplicationRouteHandler>
        }
      />
      {!!instanceId &&
        pageMap
          .map((rc) => {
            const PageElement = rc.element;
            return PageElement ? (
              <Route
                key={rc.flowStepId}
                path={`${localeParamWithCountry}/:flowIdOrType/${rc.path}`}
                element={
                  <ApplicationRouteHandler
                    localePath={localeParamWithCountry ?? ''}
                    flowSteps={flowSteps}
                  >
                    <PageElement />
                  </ApplicationRouteHandler>
                }
              />
            ) : null;
          })
          .filter((r) => r !== null)}
    </Routes>
  );
};

export default ApplicationRouter;
