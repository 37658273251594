import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  view: {
    defaultMessage: 'View',
  },
  viewed: {
    defaultMessage: 'Viewed',
  },
  sign: {
    defaultMessage: 'Sign',
  },
  signed: {
    defaultMessage: 'Signed',
  },
  accept: {
    defaultMessage: 'Accept',
  },
});
