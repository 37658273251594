import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'Tell us about your finances',
    description: '@monefit-es Tell us about your finances',
  },
  subHeaderText: {
    defaultMessage: 'We will use this information to calculate your likelihood of acceptance.',
    description:
      '@monefit-es We will use this information to calculate your likelihood of acceptance.',
  },
  continue: {
    defaultMessage: 'Check my eligibility',
    description: '@monefit-es Check my eligibility',
  },
  mainSourceIncomeText: {
    defaultMessage: 'What’s your main source of income?',
    description: '@monefit-es What’s your main source of income?',
  },
  employmentTypeLabel: {
    defaultMessage: 'Employment type',
    description: 'Employment type',
  },
  employerLabel: {
    defaultMessage: 'Employer name',
    description: '@monefit-es Employer name',
  },
  monthlyIncomeLablel: {
    defaultMessage: 'Your monthly income after tax (€)',
    description: '@monefit-es Your monthly income after tax (€)',
  },
  taxIdentificationNumberLabel: {
    defaultMessage: 'Your registration number',
    description: '@monefit-es Your registration number',
  },
  hasAdditionalIncomeLabel: {
    defaultMessage: 'Do you have any additional regular income?',
    description: '@monefit-es Do you have any additional regular income?',
  },
  additionalIncomeSourceLabel: {
    defaultMessage: 'Source',
    description: 'Source',
  },
  additionalMonthlyNetIncome: {
    defaultMessage: 'Your income after tax (€)',
    description: '@monefit-es Your income after tax (€)',
  },
  addButtonText: {
    defaultMessage: 'Add more',
    description: 'Add more',
  },
  totalMonthlyExpensesText: {
    defaultMessage: 'How much do you spend monthly?',
    description: '@monefit-es How much do you spend monthly?',
  },
  monthlyExpensesHelpText: {
    defaultMessage: 'Rent, utilities, mobile, car etc.',
    description: '@monefit-es Rent, utilities, mobile, car etc.',
  },
  monthlyExpensesLabel: {
    defaultMessage: 'Regular household expenses (€)',
    description: '@monefit-es Regular household expenses (€)',
  },
  hasActiveCreditsLabel: {
    defaultMessage: 'Do you have any active loans or credit cards?',
    description: 'Do you have any active loans or credit cards?',
  },
  activeCreditAmountLabel: {
    defaultMessage: 'Regular credit expenses (€)',
  },
  mandatoryFieldError: {
    defaultMessage: 'This field cannot be empty',
  },
  minimumFieldError: {
    defaultMessage: ' The amount must be 1 or higher',
  },
  dropdownSelectLabel: {
    defaultMessage: 'Please choose',
  },
});
