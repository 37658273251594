import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Navigate, useNavigate } from 'react-router-dom';

import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useMonthioUrlParams from '@common/hooks/useMonthioUrlParams';
import TransactionRejectedCard from '@monefit-es/components/transaction-rejected-card/TransactionRejectedCard';

import m from './PaymentErrorPage.messages';

const PaymentErrorPage = () => {
  const { paymentId, paymentSessionId } = useMonthioUrlParams();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();

  const handleBackClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`, { replace: true });
  }, [navigate, localeWithCountry]);

  if (!paymentId && !paymentSessionId) {
    return <Navigate to={`/${localeWithCountry}/user`} />;
  }

  return (
    <Layout.WithNavigation onBackButtonClick={handleBackClick}>
      <TransactionRejectedCard descriptionText={formatMessage(m.descriptionText)} />
    </Layout.WithNavigation>
  );
};

export default PaymentErrorPage;
