import { useEffect } from 'react';

import MainLoader from '@common/components/loader/MainLoader';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';

const ValidationPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();

  useEffect(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.SUBMIT, instanceId }));
  }, []);

  return (
    <SignupLayout>
      <MainLoader />
    </SignupLayout>
  );
};

export default ValidationPage;
