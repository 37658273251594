import { useCallback, useEffect, useMemo, useState } from 'react';

import MainLoader from '@common/components/loader/MainLoader';
import { useApplicationFlowInstance } from '@common/hooks';
import { Locales, useLocale } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { SubStep } from '@common/services/application';

import { saveBankSubStepToStorage } from './bankPageHelpers';

interface AccountScoringProps {
  clientId: string;
  invitationId: string;
}

const AccountScoringView = ({ clientId, invitationId }: AccountScoringProps) => {
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const [scriptLoading, setScriptLoading] = useState(false);
  const { locale } = useLocale();
  const { REACT_APP_ENVIRONMENT } = process.env;

  const scriptUrl = useMemo(
    () =>
      REACT_APP_ENVIRONMENT === 'production'
        ? 'https://accountscoring.com/static/asc-embed-v2.js'
        : 'https://prelive.accountscoring.com/static/asc-embed-v2.js',
    [REACT_APP_ENVIRONMENT]
  );

  const widgetLocale = useMemo(() => {
    // Default value is et_EE. Possible values are et_EE, ru_RU, lv_LV, lt_LT, en_GB.
    switch (locale) {
      case Locales.ET:
        return 'et_EE';
      case Locales.RU:
        return 'ru_RU';
      case Locales.EN:
        return 'en_GB';
      default:
        return 'et_EE';
    }
  }, [locale]);

  const handleAllDone = useCallback(() => {
    dispatch(setCurrentSubstepId(SubStep.BANK_PENDING));
    saveBankSubStepToStorage(instanceId, SubStep.BANK_PENDING);
  }, [instanceId, dispatch]);

  const endScriptLoad = useCallback(() => {
    setScriptLoading(false);
  }, []);

  const initWidget = useCallback(() => {
    setTimeout(() => {
      //@ts-ignore
      window.ASCEMBED?.initialize({
        container_id: 'ascContainer',
        invitation_id: invitationId,
        client_id: clientId,
        locale: widgetLocale,
        is_modal: false,
        onConfirmAllDone: function (status: string) {
          console.log('onConfirmAllDone');
          handleAllDone();
        },
      });
      endScriptLoad();
    }, 100);
  }, [invitationId, clientId, handleAllDone, widgetLocale, endScriptLoad]);

  useEffect(() => {
    setScriptLoading(true);
    const script = document.createElement('script');
    script.src = scriptUrl;
    script.type = 'text/javascript';

    document.head.appendChild(script);
    script.onload = initWidget;
    script.onerror = endScriptLoad;

    return () => {
      document.head.removeChild(script);
    };
  }, [initWidget, scriptUrl, endScriptLoad]);

  return scriptLoading ? (
    <MainLoader />
  ) : (
    <div className="size-full sm:w-[610px]">
      <div id="ascContainer"></div>
    </div>
  );
};

export default AccountScoringView;
