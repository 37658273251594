import { FC, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import { HeaderProps } from '@common/components/header/Header';
import LogoIcon from '@common/components/icons/LogoIcon';
import ProfileIcon from '@common/components/icons/ProfileIcon';
import FlowProgressBar from '@common/components/progress-bar/FlowProgressBar';
import { useLocale, useSelector } from '@common/hooks';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
import { getIsLoggedIn, getUserDisplayName, handleLogout } from '@common/utils';
import classNames from 'classnames';
import clsx from 'clsx';

const Header: FC<HeaderProps> = ({
  userDisplayNameNode,
  isWaitingList,
  hideProgressBar,
  type,
  loadingProp,
}) => {
  const { formatMessage } = useIntl();
  const { flowInstance } = useSelector((st) => st.application);
  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();
  const isLoggedIn = getIsLoggedIn();
  const showProgressBar = !hideProgressBar && !!flowInstance?.data?.progressPercentage;
  const loading = flowInstance.loading || loadingProp;
  const breakpoint = useTailwindBreakpoints();

  const isMobile = breakpoint && [TWBreakPoint.XS, TWBreakPoint.SM].includes(breakpoint);
  const handleLogoClick = useCallback(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate(`/${localeWithCountry}/user`);
  }, [navigate, isLoggedIn, localeWithCountry]);

  const handleAccountClick = useCallback(() => {
    if (!isLoggedIn) {
      return;
    }

    navigate(`/${localeWithCountry}/account`);
  }, [navigate, isLoggedIn, localeWithCountry]);

  if (type === 'dashboard') {
    return (
      <div className="flex w-full  items-center  justify-between gap-3  p-2 lg:gap-0">
        <div className={classNames('flex items-center justify-start gap-5')}>
          <button disabled={!isLoggedIn} onClick={handleLogoClick} type="button">
            <LogoIcon height={isMobile ? '40' : '70'} width={isMobile ? '40' : '70'} />
          </button>
        </div>
        {isLoggedIn && !loading && (
          <div>
            <Button
              buttonNode={
                <div
                  className={clsx(
                    'flex cursor-pointer items-center justify-center rounded-full bg-[#EEE6DB]',
                    { 'size-[40px]': isMobile, 'size-[70px]': !isMobile }
                  )}
                >
                  <ProfileIcon />
                </div>
              }
              onClick={handleAccountClick}
            />
          </div>
        )}
      </div>
    );
  }

  if (isWaitingList) {
    return (
      <div className="flex w-full flex-col items-center justify-center gap-3 p-2 lg:flex-row lg:justify-between lg:gap-0">
        <div className={classNames('flex items-center justify-start gap-5')}>
          <button disabled={!isLoggedIn} onClick={handleLogoClick} type="button">
            <LogoIcon />
          </button>
        </div>
        {isLoggedIn && !loading && (
          <>
            {userDisplayNameNode ?? (
              <div className="font-secondary text-2xl">
                <FormattedMessage
                  defaultMessage="Hey, {userName}"
                  values={{ userName: getUserDisplayName() }}
                />
              </div>
            )}
            <div className="hidden lg:flex">
              {!loading && (
                <Button
                  extraClassNames="font-bold font-secondary"
                  color="dark-gray"
                  onClick={() => handleLogout(localeWithCountry)}
                  text={formatMessage({
                    defaultMessage: 'Log out',
                    description: 'Monefit-ES: logout',
                  })}
                />
              )}
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex w-full flex-col items-center justify-center gap-3 p-2 lg:flex-row lg:justify-between lg:gap-0">
      <div
        className={classNames(
          showProgressBar && 'flex-[0.5]',
          'flex items-center justify-start gap-5'
        )}
      >
        <button disabled={!isLoggedIn} onClick={handleLogoClick} type="button">
          <LogoIcon />
        </button>
      </div>

      {showProgressBar && (
        <>
          <FlowProgressBar />
          <div className="w-full flex-[0.5]"></div>
        </>
      )}
    </div>
  );
};

export default Header;
