import { useEffect, useState } from 'react';

export enum Environment {
  PRODUCTION = 'production',
  STAGING = 'staging',
  DEVELOPMENT = 'development',
}

const useEnvironment = () => {
  const definedEnv = process.env.REACT_APP_ENVIRONMENT as Environment;
  const [environment, setEnvironment] = useState<Environment | null>(null);

  useEffect(() => {
    if (Object.values(Environment).includes(definedEnv)) {
      setEnvironment(definedEnv);
    }
  }, [definedEnv]);

  const isDevEnv = environment ? environment === Environment.DEVELOPMENT : undefined;
  const isProduction = environment ? environment === Environment.PRODUCTION : undefined;
  const isDevOrStagingEnv = environment
    ? [Environment.DEVELOPMENT, Environment.STAGING].includes(environment)
    : undefined;

  return { environment, isDevEnv, isDevOrStagingEnv, isProduction };
};

export default useEnvironment;
