import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'How much would you like to withdraw?',
    description: 'How much would you like to withdraw?',
  },
  subTitle: {
    defaultMessage: 'Available',
    description: 'Available',
  },
  toWithdraw: {
    defaultMessage: 'I’d like to withdraw:',
    description: 'I’d like to withdraw:',
  },
  totalBorrowed: {
    defaultMessage: 'Total borrowed credit',
    description: 'Total borrowed credit',
  },
  minMonthlyPayment: {
    defaultMessage: 'Min monthly payment',
    description: 'Min monthly payment',
  },
  confirm: {
    defaultMessage: 'Confirm',
    description: 'Confirm',
  },
  bankAccountInputLabel: {
    defaultMessage: 'Bank account',
    description: 'Bank account',
  },
  bankAccountRequired: {
    defaultMessage: 'Bank account is required',
    description: 'Bank account is required',
  },
});
