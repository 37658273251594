import { useCallback, useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';

import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import useError from '@common/hooks/useError';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setCurrentSubstepId, setShowLoader } from '@common/redux/slices/application';
import {
  fetchApplicationFlowInstance,
  performApplicationFlowAction,
} from '@common/redux/thunks/application';
import {
  FlowActions,
  FlowSteps,
  IdentificationProvider,
  IdentificationReason,
  IdentificationSubmitResponse,
} from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import usePendingIdentification from '@monefit-ee/hooks/usePendingIdentification';

interface PinCardProps {
  data: IdentificationSubmitResponse;
  headerText?: string;
  subHeaderText?: string;
}

const PinCard = ({ data, headerText, subHeaderText }: PinCardProps) => {
  const dispatch = useDispatch<RootDispatch>();
  const { formatMessage } = useIntl();
  useTracker({
    initEventName: TrackEvent.SIGNUP_PROCESSING_INIT,
    errorTrackEventName: TrackEvent.SIGNUP_PROCESSING_ERROR,
  });
  const [instanceId] = useApplicationFlowInstance();
  const { loading, error, data: appData } = useSelector((st) => st.application.flowInstance);
  const { errors } = useError();

  const { pendingIdentification, saveToIdentificationStorage, clearIdentificationStorage } =
    usePendingIdentification();

  const checkData = useMemo(() => {
    switch (data.provider) {
      case IdentificationProvider.SMART_ID:
        return {
          provider: data.provider,
          hash: data.hash,
          reason: IdentificationReason.REGISTER,
          identificator: data.identificator,
        };
      case IdentificationProvider.MOBILE_ID:
        return {
          provider: data.provider,
          hash: data.hash,
          reason: IdentificationReason.REGISTER,
          phone: data.phone,
          perosnalCode: data.personalCode,
        };
      default:
        return {};
    }
  }, [data]);

  const clearStorageAndResetState = useCallback(() => {
    clearIdentificationStorage();
    dispatch(setShowLoader(true));
    dispatch(setCurrentSubstepId(null));
  }, [clearIdentificationStorage, dispatch]);

  // Handle identification check if no pending identification exists
  const handleIdentificationCheck = useCallback(() => {
    if (loading || appData?.currentStepId !== FlowSteps.IDENTIFICATION) {
      return;
    }
    // Save pending identification to session storage
    saveToIdentificationStorage({
      code: data.code,
      provider: data.provider,
    });
    dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CHECK,
        data: checkData,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.currentStepId === FlowSteps.EMAIL) {
          dispatch(setShowLoader(true));
        }
      })
      .catch(() => {
        dispatch(setShowLoader(true));
      })
      .finally(() => {
        dispatch(setCurrentSubstepId(null));
        clearIdentificationStorage();
      });
  }, [
    dispatch,
    instanceId,
    loading,
    checkData,
    clearIdentificationStorage,
    saveToIdentificationStorage,
    data.code,
    data.provider,
    appData?.currentStepId,
  ]);

  // handle FETCH if pending identification exists
  const handleFetch = useCallback(() => {
    dispatch(fetchApplicationFlowInstance(instanceId ?? undefined))
      .unwrap()
      .then((res) => {
        //
        if ((res.errors as any)?.length || error || errors.length) {
          clearStorageAndResetState();
          window.location.reload();
        }
        if (res.currentStepId === FlowSteps.EMAIL) {
          clearStorageAndResetState();
        }
      });
  }, [dispatch, clearStorageAndResetState, error, errors, instanceId]);

  useEffect(() => {
    dispatch(setShowLoader(false));
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timer;
    const shouldSkipCheck = !!pendingIdentification;

    if (!shouldSkipCheck) {
      // Check Identification if no pending identifications exist
      handleIdentificationCheck();
    } else {
      // If pending identification exists fetch every X seconds to get flowInstance latest state
      intervalId = setInterval(handleFetch, 3000);
    }
    return () => clearInterval(intervalId ?? 0);
  }, []);

  const providerTextValue = useMemo(() => {
    const provider = data?.provider ?? (appData?.prefilledData as any)?.provider ?? '';
    switch (provider) {
      case IdentificationProvider.SMART_ID:
        return formatMessage({
          defaultMessage: 'Smart-ID',
          description: 'Identification: "Smart-ID"',
        });

      case IdentificationProvider.MOBILE_ID:
        return formatMessage({
          defaultMessage: 'Mobile-ID',
          description: 'Identification: "Mobile-ID"',
        });
      case IdentificationProvider.ID_CARD:
        return formatMessage({
          defaultMessage: 'ID-Card',
          description: 'Identification: "ID-Card"',
        });
      default:
        return '';
    }
  }, [formatMessage, data.provider, appData?.prefilledData]);

  return (
    <SignupBaseCard
      headerText={
        headerText ??
        formatMessage({ defaultMessage: 'Enter PIN1', description: 'Identification: "Enter PIN1"' })
      }
      subHeaderText={
        subHeaderText ??
        formatMessage(
          {
            defaultMessage:
              'Enter your {provider} PIN1 on your device, if you are convinced the control code shown on your device matches the one shown here.',
            description:
              'Identification: Enter "Enter your Smart-ID PIN1 on your device, if you are convinced the control code shown on your device matches the one shown here."',
          },
          { provider: providerTextValue }
        )
      }
    >
      <div className="self-stretch text-center text-2xl font-semibold text-stone-950">
        {data?.code ?? pendingIdentification?.code}
      </div>
    </SignupBaseCard>
  );
};

export default PinCard;

// Enter PIN1
