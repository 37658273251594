export function getCoreServiceBaseUrls() {
  const baseUrl = process.env.REACT_APP_CORE_SERVICE_BASEURL;
  const authenticationBaseUrl = `${baseUrl}/authentication/v1`;
  const applicationBaseUrl = `${baseUrl}/application/v1`;
  const bankStatementBaseUrl = `${baseUrl}/bank-statement/v1`;
  const paymentServiceBaseUrl = `${baseUrl}/payment/v1`;
  const userServiceBaseUrl = `${baseUrl}/user/v1`;
  const bankingServiceBaseUrl = `${baseUrl}/banking/v1`;
  const documentServiceBaseUrl = `${baseUrl}/document/v1`;
  const subscriptionServiceBaseUrl = `${baseUrl}/subscription/v1`;
  const passwordUrl = `${baseUrl}/password/v1`;

  return {
    authenticationBaseUrl,
    applicationBaseUrl,
    bankStatementBaseUrl,
    paymentServiceBaseUrl,
    userServiceBaseUrl,
    bankingServiceBaseUrl,
    documentServiceBaseUrl,
    subscriptionServiceBaseUrl,
    passwordUrl
  };
}
