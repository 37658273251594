export const getClassNames = () => ({
  infoCardContainer:
    'flex flex-col items-start justify-start gap-3 self-stretch rounded-t-[28px] bg-[#F2EEE9] px-7 pb-7 pt-4',
  infoSectionItemWrapper: 'inline-flex items-center justify-between self-stretch',
  infoSectionItemTitle: 'text-base font-medium leading-normal text-black  text-opacity-60',
  infoSectionItemValue: 'text-sm font-semibold leading-snug tracking-tight text-black',
  formContainer:
    '-mt-4 flex flex-col items-start justify-between self-stretch rounded-[28px] bg-white p-7',
});

export type PaymentStartFormClasses = ReturnType<typeof getClassNames>;
