import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { bankStatementBaseUrl } = getCoreServiceBaseUrls();

export const bankStatementService = axios.create({
  baseURL: bankStatementBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});
