import { ReactNode, useMemo } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useApplicationFlowInstance, useDispatch, useLocale, useSelector } from '@common/hooks';
import useFlowIdFromUrl from '@common/hooks/useFlowIdFromUrl';
import { RootDispatch } from '@common/redux';
import { selectUserData } from '@common/redux/selectors/user';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { fetchUser } from '@common/redux/thunks/user';
import { FlowActions, FlowSteps } from '@common/services/application';
import { getUserData, stepIdToPath } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';
import { ErrorBoundaryPage } from '@monefit-es/pages';

export enum OnboardingCheckStatus {
  PENDING = 'Pending',
  FAILED = 'Failed',
  CHECKED = 'Checked',
}

const OnboardingProvider = ({ children }: { children: ReactNode }) => {
  const { localeWithCountry } = useLocale();
  const { flowId: possibleFlowIdOrTypeFromLocation } = useFlowIdFromUrl();
  const userData = useSelector(selectUserData);
  const [onboardingRedirectUrl, setOnboardingRedirectUrl] = useState<string | null>(null);

  const [onBoardingCheckStatus, setOnboardingCheckStatus] = useState<OnboardingCheckStatus>(
    OnboardingCheckStatus.PENDING
  );

  const dispatch = useDispatch<RootDispatch>();
  const [, setStoredInstanceId] = useApplicationFlowInstance();

  const handleOnboardingFlowStart = useCallback(async () => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;
    const {
      user: { id },
    } = getUserData();

    // No need to start flow if we are already on onboarding flow route
    if (possibleFlowIdOrTypeFromLocation === REACT_APP_ES_APPLICATION_FLOW_ID) {
      return;
    }

    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        flowId: REACT_APP_ES_APPLICATION_FLOW_ID,
        data: {
          userId: id,
          locale: localeWithCountry,
        },
      })
    )
      .unwrap()
      .then(async (r) => {
        const currentStepIdPath = stepIdToPath(r.currentStepId);
        setStoredInstanceId(r.id);
        const isResumed = r.isResumed;
        const baseRedirectUrl = `/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/${currentStepIdPath}`;
        if (!isResumed && r.currentStepId === FlowSteps.PHONE) {
          setOnboardingRedirectUrl(`${baseRedirectUrl}?verified=1`);
        } else {
          setOnboardingRedirectUrl(baseRedirectUrl);
        }
      });
  }, [setStoredInstanceId, dispatch, localeWithCountry, possibleFlowIdOrTypeFromLocation]);

  const hasOnboarding = useMemo(
    () => !!userData?.tags.find((x) => x.value === 'onboardingCompleted'),
    [userData?.tags]
  );

  const checkOnboarding = useCallback(async () => {
    if (userData) {
      return;
    }
    await dispatch(fetchUser())
      .unwrap()
      .catch(() => {
        setOnboardingCheckStatus(OnboardingCheckStatus.FAILED);
      });
  }, [dispatch, userData]);

  useEffect(() => {
    checkOnboarding();
  }, []);

  useEffect(() => {
    if (hasOnboarding) {
      setOnboardingCheckStatus(OnboardingCheckStatus.CHECKED);
    }

    if (!hasOnboarding && !!userData) {
      handleOnboardingFlowStart();
    }
  }, [hasOnboarding, userData, handleOnboardingFlowStart]);

  const componentToRender = useMemo(() => {
    if (onboardingRedirectUrl) {
      return <Navigate to={onboardingRedirectUrl} replace />;
    }

    switch (onBoardingCheckStatus) {
      case OnboardingCheckStatus.PENDING:
        return (
          <MainLayout hideProgressBar hideHeaderAndFooter>
            <MainLoader />
          </MainLayout>
        );
      case OnboardingCheckStatus.FAILED:
        return <ErrorBoundaryPage />;
      case OnboardingCheckStatus.CHECKED:
        return children;
    }
  }, [children, onboardingRedirectUrl, onBoardingCheckStatus]);

  return <>{componentToRender}</>;
};

export default OnboardingProvider;
