import { Country } from '@common/hooks';
import { TrackEvent } from '@common/utils/amplitude/events';

export interface FormFieldToTrackOnChange {
  fieldName: string;
  event: TrackEvent;
}

export const formatPhoneValue = ({ phone, country }: { phone: string; country: Country }) => {
  switch (country) {
    case Country.EE:
      return phone.replace(/\+372/g, '');
    case Country.ES:
      return phone.replace(/\+34/g, '');

    default:
      return phone;
  }
};

export const handleFormFieldsEventTracking = (
  values: any,
  fieldsToTrackOnChange: FormFieldToTrackOnChange[],
  trackEventHandler: (event: TrackEvent, properties?: Record<string, any> | undefined) => void
) => {
  for (const field of fieldsToTrackOnChange) {
    const key = field.fieldName;
    const value = values[key];
    value !== null && value !== undefined && trackEventHandler(field.event);
  }
};

export const emailValidationRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
