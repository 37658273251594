import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  accountIdLabel: {
    defaultMessage: 'Account ID',
  },
  idNumberLabel: {
    defaultMessage: 'ID number',
  },
  firstNameLabel: {
    defaultMessage: 'First name',
  },
  lastNameLabel: {
    defaultMessage: 'Last name',
  },
  addressLabel: {
    defaultMessage: 'Address',
  },
  taxResidencyLabel: {
    defaultMessage: 'Tax residency',
  },
  btnText: {
    defaultMessage: 'Contact to edit',
  },
  tooltipText: {
    defaultMessage: 'Contact support to edit details',
  },
});
