import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { bankingServiceBaseUrl } = getCoreServiceBaseUrls();

export const bankingService = axios.create({
  baseURL: bankingServiceBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});
