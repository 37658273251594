const DrawdownPendingIcon = () => {
  return (
    <svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.95987"
        y="2.35245"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        fill="#D9D8DC"
        fillOpacity="0.07"
      />
      <rect
        x="1.95987"
        y="2.35245"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        stroke="#D9D8DC"
        strokeWidth="3.67364"
        strokeLinecap="square"
      />
      <path
        d="M36.3545 44.1641L36.3545 33.0391"
        stroke="#D9D8DC"
        strokeWidth="2.78125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.6462 33.0391L36.3545 33.0391"
        stroke="#D9D8DC"
        strokeWidth="2.78125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.3545 27.4753L36.3545 25.6211"
        stroke="#D9D8DC"
        strokeWidth="2.78125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DrawdownPendingIcon;
