import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import { useSelector } from '@common/hooks/useSelector';
import { selectFlowInstance } from '@common/redux/selectors';
import { isArrayOfStringErrors } from '@common/utils/helpers';

import m from './useError.messages';

interface Error {
  [key: string]: string[] | string;
}

const useError = () => {
  const { data } = useSelector(selectFlowInstance);
  const errors = data?.errors;
  const { formatMessage } = useIntl();

  const instanceErrorMessagesMap: { [key: string]: string } = useMemo(
    () => ({
      'Invalid phone OTP code': formatMessage(m.invalidOtpCode),
    }),
    [formatMessage]
  );

  const errorsResponse: string[] = useMemo(() => {
    if (typeof errors === 'object' && !Array.isArray(errors)) {
      const typedErrorObject = (errors as Error) || {};
      const errorsRaw = Object.values(typedErrorObject);
      if (isArrayOfStringErrors(errorsRaw)) {
        return errorsRaw;
      }

      if ('code' in typedErrorObject || 'status' in typedErrorObject) {
        return [formatMessage(m.fallBackMessage)];
      }

      const resultErrors = [];
      for (const categorizedErrors of errorsRaw) {
        for (const errorString of categorizedErrors) {
          resultErrors.push(errorString);
        }
      }
      return resultErrors;
    } else if (Array.isArray(errors)) {
      return errors;
    } else {
      return [];
    }
  }, [errors, formatMessage]);

  const notifyInstanceErrors = useCallback(() => {
    errorsResponse
      .filter((x) => x !== 'Undefined action.')
      .map((error) =>
        toast(instanceErrorMessagesMap?.[error] ?? error ?? formatMessage(m.fallBackMessage), {
          type: 'error',
          toastId: error || m.fallBackMessage.defaultMessage,
        })
      );
  }, [errorsResponse, instanceErrorMessagesMap, formatMessage]);

  const notifyApiError = useCallback(
    (message?: string) => {
      toast(formatMessage(m.fallBackMessage), {
        type: 'error',
        toastId: m.fallBackMessage.defaultMessage,
      });
    },
    [formatMessage]
  );

  return {
    errors: errorsResponse,
    notifyInstanceErrors,
    notifyApiError,
  };
};

export default useError;
