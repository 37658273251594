import { useEffect, useMemo } from 'react';
import { useIntercom } from 'react-use-intercom';

import { useLocale } from '@common/hooks';
import { useCookieConsents } from '@common/hooks/useCookieConsents';
import useEnvironment from '@common/hooks/useEnvironment';
import { getUserDisplayName } from '@common/utils';

const Intercom = () => {
  const { consents, loaded: constentsLoaded } = useCookieConsents();
  const { isDevOrStagingEnv } = useEnvironment();
  const { boot, update } = useIntercom();

  const userName = getUserDisplayName();
  const { locale } = useLocale();
  const canLoadIntercom = useMemo(
    () => (isDevOrStagingEnv ? true : constentsLoaded && consents.necessary),
    [consents, constentsLoaded, isDevOrStagingEnv]
  );

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    boot({ languageOverride: locale, name: userName ?? '' });
  }, [locale, boot, userName, canLoadIntercom]);

  useEffect(() => {
    if (!canLoadIntercom) {
      return;
    }
    update({ languageOverride: locale, name: userName ?? '' });
  }, [locale, update, userName, canLoadIntercom]);

  return <></>;
};

export default Intercom;
