import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

import { WidgetProps } from '@rjsf/utils';

import Button, { ButtonSpacing } from '../button/Button';

interface YesNoWidgetProps extends WidgetProps {
  yesText?: string;
  noText?: string;
  isBoldLabel?: boolean;
  labelComponent?: ReactNode;
  externalYesHandler?: () => void;
  externalNoHandler?: () => void;
}

const YesNoWidget = ({
  value,
  label,
  onChange,
  yesText,
  noText,
  labelComponent,
  externalNoHandler,
  externalYesHandler,
  isBoldLabel,
}: YesNoWidgetProps) => {
  const isYesSelected = value === true;
  const hasValue = value !== undefined;
  const extraClassNames = 'font-medium w-36 md:w-full';
  const { formatMessage } = useIntl();

  const handleYesSelect = useCallback(() => {
    onChange(true);
    if (externalYesHandler) {
      externalYesHandler();
    }
  }, [onChange, externalYesHandler]);

  const handleNoSelect = useCallback(() => {
    onChange(false);
    if (externalNoHandler) {
      externalNoHandler();
    }
  }, [onChange, externalNoHandler]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2.5 rounded-md bg-white">
      {labelComponent ? (
        labelComponent
      ) : (
        <div
          className={`text-sm font-medium leading-normal text-black ${
            isBoldLabel ? 'text-[15px] text-opacity-100' : 'text-opacity-50'
          }`}
        >
          {label}
        </div>
      )}
      <div className="flex h-20 w-full gap-4">
        <Button
          fullWidth
          type="button"
          color={isYesSelected && hasValue ? 'blue' : 'gray'}
          text={yesText ?? formatMessage({ defaultMessage: 'Yes', description: 'YesNoInput' })}
          spacing={ButtonSpacing.XL}
          onClick={handleYesSelect}
          roundedFull={false}
          extraClassNames={extraClassNames}
        />

        <Button
          fullWidth
          type="button"
          color={!isYesSelected && hasValue ? 'blue' : 'gray'}
          text={noText ?? formatMessage({ defaultMessage: 'No', description: 'YesNoInput' })}
          onClick={handleNoSelect}
          spacing={ButtonSpacing.XL}
          roundedFull={false}
          extraClassNames={extraClassNames}
        />
      </div>
    </div>
  );
};

export default YesNoWidget;
