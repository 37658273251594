const BackButtonIcon = () => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_b_331_7135)">
        <rect
          width="29.1667"
          height="29.1667"
          rx="14.5833"
          transform="matrix(1 0 0 -1 0 29.168)"
          fill="#EFEFEF"
          fillOpacity="0.88"
        />
        <rect
          x="0.5"
          y="-0.5"
          width="28.1667"
          height="28.1667"
          rx="14.0833"
          transform="matrix(1 0 0 -1 0 28.168)"
          stroke="black"
          strokeOpacity="0.48"
        />
      </g>
      <path
        d="M16.0416 18.9609L12.6978 15.6171C12.1283 15.0476 12.1283 14.1243 12.6978 13.5547L16.0416 10.2109"
        stroke="black"
        strokeWidth="2.1875"
        strokeLinecap="round"
      />
      <defs>
        <filter
          id="filter0_b_331_7135"
          x="-18"
          y="-18"
          width="65.1666"
          height="65.168"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_331_7135" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_331_7135"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default BackButtonIcon;
