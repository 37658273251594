import { useCallback, useEffect, useMemo } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import ErrorProvider from '@common/components/error-provider/ErrorProvider';
import {
  useApplicationFlowInstance,
  useApplicationRoutingPageMap,
  useDispatch,
  useLocale,
  useSelector,
} from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import useFlowIdFromUrl from '@common/hooks/useFlowIdFromUrl';
import AccountPage from '@common/pages/account-page/AccountPage';
import { RootDispatch } from '@common/redux';
import { fetchApplicationFlowInstance } from '@common/redux/thunks/application';
import ApplicationRouteHandler from '@common/router/ApplicationRouteHandler';
import { FlowSteps } from '@common/services/application';
import { PageNotFoundError } from '@common/utils';
import { getInstanceIdFromUrl } from '@common/utils/instanceIdFromUrl';
import { DocumentsProvider } from '@monefit-es/hooks/useDocuments';
import { ErrorBoundaryPage, SingupPage } from '@monefit-es/pages';
import CreditDetailsPage from '@monefit-es/pages/credit-details-page/CreditDetailsPage';
import DashboardPage from '@monefit-es/pages/dashboard-page/DashboardPage';
import FlowSelectPage from '@monefit-es/pages/flow-select-page/FlowSelectPage';
import InvoicePage from '@monefit-es/pages/invoice-page/InvoicePage';
import LoginPage from '@monefit-es/pages/login-page/LoginPage';
import PaymentErrorPage from '@monefit-es/pages/payment-error-page/PaymentErrorPage';
import PaymentPage from '@monefit-es/pages/payment-page/PaymentPage';
import PaymentSuccessPage from '@monefit-es/pages/payment-success-page/PaymentSuccessPage';
import WaitingListPage from '@monefit-es/pages/waiting-list-page/WaitingListPage';

import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';

const DRAWDOWN_FLOW_STEPS = [
  FlowSteps.AMOUNT,
  FlowSteps.CONFIRMAITON,
  FlowSteps.DECISION,
  FlowSteps.PROCESSING,
  FlowSteps.END,
  FlowSteps.DECLINED,
];

const ONBOARDING_FLOW_STEPS = [
  FlowSteps.PLAN,
  FlowSteps.PHONE,
  FlowSteps.PHONE_OTP,
  FlowSteps.ADDRESS,
  FlowSteps.IDENTIFY_VERIFICATION,
  FlowSteps.DECISION,
  FlowSteps.DECLINED,
  FlowSteps.BANK,
  FlowSteps.QUESTIONNAIRE,
  FlowSteps.DOCUMENTS,
  FlowSteps.END,
  FlowSteps.REVIEW,
];

const FLOW_STEPS = [...DRAWDOWN_FLOW_STEPS, ...ONBOARDING_FLOW_STEPS];

const Router = () => {
  const { hasFlowId } = useFlowIdFromUrl();
  const pageNotFoundError = useMemo(() => new PageNotFoundError(), []);
  const { allowedLocales, localeWithCountry } = useLocale();
  const [drawdownPages] = useApplicationRoutingPageMap(DRAWDOWN_FLOW_STEPS);
  const [onboardingPages] = useApplicationRoutingPageMap(ONBOARDING_FLOW_STEPS);
  const dispatch = useDispatch<RootDispatch>();

  const {
    loading,
    meta: { enableGetRequest },
  } = useSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const location = useLocation();
  const { isDevOrStagingEnv } = useEnvironment();

  const fetchApplicationData = useCallback(async () => {
    if (!hasFlowId || loading) {
      return;
    }
    const instanceIdToQuery = getInstanceIdFromUrl(location.pathname) ?? instanceId ?? null;
    if (instanceIdToQuery && enableGetRequest) {
      await dispatch(fetchApplicationFlowInstance(instanceIdToQuery))
        .unwrap()
        .catch((e) => console.error(e));
    }
  }, [dispatch, instanceId, enableGetRequest, location.pathname, hasFlowId, loading]);

  // const handleHotJarIdentify = useCallback(() => {
  //   if (window?.hj && typeof window?.hj === 'function') {
  //     window.hj('identify', instanceId, {
  //       session_id: data?.sessionId ?? null,
  //       instance_id: instanceId,
  //     });
  //   }
  // }, [instanceId, data]);

  // useEffect(() => {
  //   handleHotJarIdentify();
  // }, [handleHotJarIdentify]);

  useEffect(() => {
    fetchApplicationData();
  }, []);

  if (!allowedLocales) {
    // Show blank page until allowedLocales (or fallback locales) are defined
    return <></>;
  }

  return (
    <Routes>
      <Route
        path={`${localeWithCountry}/sign-up`}
        element={
          <PublicRoute>
            <SingupPage />
          </PublicRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/login`}
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path={`${localeWithCountry}/user`}
        element={
          <PrivateRoute hasMainLayout={false} hasOnboardingProvider={!!isDevOrStagingEnv}>
            {isDevOrStagingEnv ? <DashboardPage /> : <WaitingListPage />}
          </PrivateRoute>
        }
      />

      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/user/credit-details`}
          element={
            <PrivateRoute hasMainLayout={false} hasOnboardingProvider={!!isDevOrStagingEnv}>
              <CreditDetailsPage />
            </PrivateRoute>
          }
        />
      )}
      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/account`}
          element={
            <PrivateRoute hasOnboardingProvider hasMainLayout={false}>
              <AccountPage />
            </PrivateRoute>
          }
        />
      )}

      <Route
        path={`${localeWithCountry}/invoices`}
        element={
          <PrivateRoute hasOnboardingProvider hasMainLayout={false}>
            <InvoicePage />
          </PrivateRoute>
        }
      />

      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/test/flow-select`}
          element={
            <PrivateRoute>
              <FlowSelectPage />
            </PrivateRoute>
          }
        />
      )}

      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/payment`}
          element={
            <PrivateRoute hasMainLayout={false}>
              <PaymentPage />
            </PrivateRoute>
          }
        />
      )}

      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/payment/success`}
          element={
            <PrivateRoute hasMainLayout={false}>
              <PaymentSuccessPage />
            </PrivateRoute>
          }
        />
      )}

      {isDevOrStagingEnv && (
        <Route
          path={`${localeWithCountry}/payment/error`}
          element={
            <PrivateRoute hasMainLayout={false}>
              <PaymentErrorPage />
            </PrivateRoute>
          }
        />
      )}

      {!!instanceId &&
        isDevOrStagingEnv &&
        hasFlowId &&
        drawdownPages
          .map((rc) => {
            const PageElement = rc.element;
            return PageElement ? (
              <Route
                key={rc.flowStepId}
                path={`${localeWithCountry}/:flowIdOrType/${rc.path}`}
                element={
                  <ErrorProvider>
                    <ApplicationRouteHandler
                      localePath={localeWithCountry ?? ''}
                      flowSteps={FLOW_STEPS}
                    >
                      {/* TODO: Wrap with OnboradingHandler before going live */}
                      <PrivateRoute hasMainLayout={false}>
                        <PageElement />
                      </PrivateRoute>
                    </ApplicationRouteHandler>
                  </ErrorProvider>
                }
              />
            ) : null;
          })
          .filter((r) => r !== null)}
      <Route path="*" element={<ErrorBoundaryPage error={pageNotFoundError} />} />

      {!!instanceId &&
        isDevOrStagingEnv &&
        hasFlowId &&
        onboardingPages
          .map((rc) => {
            const PageElement = rc.element;
            return PageElement ? (
              <Route
                key={rc.flowStepId}
                path={`${localeWithCountry}/:flowIdOrType/${rc.path}`}
                element={
                  <ErrorProvider>
                    <DocumentsProvider>
                      <ApplicationRouteHandler
                        localePath={localeWithCountry ?? ''}
                        flowSteps={FLOW_STEPS}
                      >
                        <PrivateRoute hasMainLayout={false}>
                          <PageElement />
                        </PrivateRoute>
                      </ApplicationRouteHandler>
                    </DocumentsProvider>
                  </ErrorProvider>
                }
              />
            ) : null;
          })
          .filter((r) => r !== null)}
      <Route path="*" element={<ErrorBoundaryPage error={pageNotFoundError} />} />
    </Routes>
  );
};

export default Router;
