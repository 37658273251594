import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';

import m from '../dashboard/Dashboard.messages';

const DashboardFAQ = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex h-[240px] w-full flex-col items-start justify-between gap-14 rounded-3xl bg-white px-6 py-7 sm:h-[350px] sm:p-12 ">
      <div className="flex h-20 flex-col items-start justify-start gap-5 self-stretch">
        <div className="inline-flex items-start justify-start gap-5 self-stretch">
          <div className="inline-flex flex-col items-start justify-start gap-2.5">
            <div className="text-lg font-semibold leading-normal text-black">
              {formatMessage(m.gotMoreQuestions)}
            </div>
            <div className="h-11 text-base font-normal text-black">
              {formatMessage(m.faqDescription)}
            </div>
          </div>
        </div>
      </div>
      {/* TODO:  LINK! */}
      <Button
        extraClassNames="text-lg"
        color="gray-alt"
        fullWidth
        text={formatMessage(m.openFAQbtnText)}
      />
    </div>
  );
};

export default DashboardFAQ;
