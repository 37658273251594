import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { toast } from 'react-toastify';

import BackButton from '@common/components/button/BackButton';
import Button, { ButtonSpacing } from '@common/components/button/Button';
import OtpCodeInput from '@common/components/otp-code-input/OtpCodeInput';
import Timer from '@common/components/otp-code-input/Timer';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import useEnvironment, { Environment } from '@common/hooks/useEnvironment';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';

import { getOtpRemaingTimeFromStorage, saveOtpRemaingTimeToStorage } from './OtpCodeForm.helpers';
import m from './OtpCodeForm.messages';

const RESEND_TIME = 5 * 60; // 5 min

export enum OtpFormType {
  EMAIL = 'email',
  PHONE = 'phone',
}
const SUBMIT_OPTIONS = {
  [OtpFormType.EMAIL]: {
    key: 'emailOtpCode',
    submitTrackEvent: TrackEvent.EMAIL_OTP_PAGE_SUBMIT,
    resendTrackEvent: TrackEvent.EMAIL_OTP_PAGE_CODE_RESEND,
    errorEvent: TrackEvent.EMAIL_OTP_PAGE_ERROR,
    codeEnteredEvent: TrackEvent.EMAIL_OTP_PAGE_CODE_ENTERED,
  },
  [OtpFormType.PHONE]: {
    key: 'phoneOtpCode',
    submitTrackEvent: TrackEvent.PHONE_OTP_PAGE_SUBMIT,
    resendTrackEvent: TrackEvent.PHONE_OTP_PAGE_CODE_RESEND,
    errorEvent: TrackEvent.PHONE_OTP_PAGE_ERROR,
    codeEnteredEvent: TrackEvent.PHONE_OTP_PAGE_CODE_ENTERED,
  },
};

interface IProps {
  emailOrPhone: string;
  type: OtpFormType;
  resendTime?: number;
  hasTimer?: boolean;
  codeLength?: number;
  afterSubmitHandler?: () => Promise<void>;
  submitOnCodeEnter?: boolean;
}

const OtpCodeForm: FC<IProps> = ({
  emailOrPhone,
  type,
  resendTime = RESEND_TIME,
  hasTimer = true,
  codeLength: codeLengthProp,
  submitOnCodeEnter,
  afterSubmitHandler,
}) => {
  const { environment } = useEnvironment();
  const { data } = useSelector((st) => st.application.flowInstance);
  const { trackEvent } = useTracker({});
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();
  const submitOptions = SUBMIT_OPTIONS[type];
  const { key, submitTrackEvent, resendTrackEvent, errorEvent, codeEnteredEvent } = submitOptions;

  const [otpCode, setOtpCode] = useState('');
  const [resendTimeRemaining, setResendTimeRemaining] = useState(0);
  const dispatch = useDispatch<RootDispatch>();
  const storedTime = getOtpRemaingTimeFromStorage(type);
  const codeLength = useMemo(
    () => codeLengthProp ?? (type === OtpFormType.EMAIL ? 4 : 5),
    [type, codeLengthProp]
  );

  const prefilledOtpCode = useMemo(() => {
    if (data?.responseData?.otpCode) {
      return data.responseData?.otpCode;
    }

    return type === OtpFormType.EMAIL
      ? (data?.prefilledData as any)?.emailOtpCode
      : (data?.prefilledData as any)?.phoneOtpCode;
  }, [data?.prefilledData, data?.responseData, type]);

  const handleCodeSubmit = useCallback(async () => {
    try {
      await dispatch(
        performApplicationFlowAction({
          instanceId,
          data: { [key]: otpCode },
          action: FlowActions.SUBMIT,
        })
      );
      setResendTimeRemaining(resendTime);
      saveOtpRemaingTimeToStorage(resendTime, type);
      trackEvent(submitTrackEvent);
    } catch (err) {
      trackEvent(errorEvent);
    }

    afterSubmitHandler && (await afterSubmitHandler());
  }, [
    otpCode,
    dispatch,
    errorEvent,
    submitTrackEvent,
    instanceId,
    resendTime,
    key,
    type,
    trackEvent,
    afterSubmitHandler,
  ]);

  const handleResend = useCallback(async () => {
    try {
      await dispatch(
        performApplicationFlowAction({
          instanceId,
          action: FlowActions.RESEND,
        })
      )
        .unwrap()
        .then(() => {
          const typeName = formatMessage(type === OtpFormType.EMAIL ? m.email : m.phone);
          toast(formatMessage(m.otpResent, { type: typeName }), { type: 'success' });
        });
      trackEvent(resendTrackEvent);
    } catch (err) {
      trackEvent(errorEvent);
    }
  }, [dispatch, instanceId, trackEvent, resendTrackEvent, formatMessage, type, errorEvent]);

  const handleCodeChange = useCallback(
    (val: string) => {
      setOtpCode(val);
      if (val.length === codeLength) {
        trackEvent(codeEnteredEvent);
      }
    },
    [trackEvent, codeEnteredEvent, codeLength]
  );

  useEffect(() => {
    const time = storedTime === undefined ? resendTime : storedTime;
    setResendTimeRemaining(time);
  }, [storedTime, resendTime]);

  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleCodeSubmit();
      }
    };

    document.addEventListener('keydown', handleKeyPress);

    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleCodeSubmit]);

  useEffect(() => {
    if (submitOnCodeEnter && codeLength === otpCode.length) {
      handleCodeSubmit();
    }
  }, [codeLength, otpCode.length, handleCodeSubmit, submitOnCodeEnter]);

  return (
    <SignupBaseCard
      headerText={formatMessage(m.cardTitle)}
      subHeaderText={formatMessage(
        type === OtpFormType.PHONE ? m.cardSubtitleMobile : m.cardSubtitleEmail,
        { value: emailOrPhone, codeLength }
      )}
    >
      {environment &&
        [Environment.DEVELOPMENT, Environment.STAGING].includes(environment) &&
        prefilledOtpCode && <pre className="text-sm">Code: {prefilledOtpCode}</pre>}
      <div className="flex w-full flex-col gap-3">
        <OtpCodeInput {...{ otpCode, handleCodeChange }} codeLength={codeLength} />
        {hasTimer ? (
          <Timer
            limit={resendTime}
            timeRemaining={resendTimeRemaining}
            setTimeRemaining={setResendTimeRemaining}
            handleResend={handleResend}
            otpFormType={type}
          />
        ) : (
          <div className="flex w-full justify-center">
            <Button
              spacing={ButtonSpacing.NONE}
              color="underlined-black"
              text={formatMessage({
                defaultMessage: 'Resend',
                description: 'OtpCode: Resend button text',
              })}
              onClick={handleResend}
            />
          </div>
        )}
      </div>

      <div className="flex w-full gap-3">
        <BackButton />
        <Button
          text={formatMessage(m.continue)}
          disabled={otpCode.length < codeLength}
          fullWidth
          onClick={handleCodeSubmit}
        />
      </div>
    </SignupBaseCard>
  );
};

export default OtpCodeForm;
