import React, { FC, JSX, useCallback } from 'react';

import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { FALLBACK_LOCALE_EE, useLocale } from '@common/hooks';
import { useApplicationFlowInstance } from '@common/hooks/useApplicationFlowInstance';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { ApiError } from '@common/utils';

import ApplicationFlowStartForm, { FormValues } from './ApplicationFlowStartPage.form';

/**
 * This page is meant for development purposes- to simulate product slider page
 */
const ApplicationFlowStartPage: FC = (): JSX.Element => {
  const dispatch = useDispatch<RootDispatch>();
  const [, setInstanceId] = useApplicationFlowInstance();
  const { localeWithCountry: localeParamWithCountry } = useLocale();

  const onSubmit = useCallback(
    (values: FormValues) => {
      dispatch(
        performApplicationFlowAction({
          data: { ...values, locale: localeParamWithCountry ?? FALLBACK_LOCALE_EE },
          action: FlowActions.SUBMIT,
        })
      )
        .unwrap()
        .then((res) => {
          if (res?.id) {
            setInstanceId(res.id);
            window.location.reload();
          }
        })
        .catch((e) => {
          throw new ApiError(String(e));
        });
    },
    [dispatch, setInstanceId, localeParamWithCountry]
  );

  return (
    <SignupLayout>
      <SignupBaseCard headerText="Select product/offer">
        <div className="flex w-full flex-col">
          <ApplicationFlowStartForm onSubmit={onSubmit} />
        </div>
      </SignupBaseCard>
    </SignupLayout>
  );
};

export default ApplicationFlowStartPage;
