import { FC } from 'react';
import { useIntl } from 'react-intl';

import LoadingCard from './LoadingCard';

const MainLoader: FC = () => {
  const { formatMessage } = useIntl();
  return (
    <div className="flex h-96 w-full items-center justify-center text-black">
      <div>
        <LoadingCard
          text={formatMessage({
            defaultMessage: 'Almost there, stitching things together!',
            description: 'Main loader text',
          })}
        />
      </div>
    </div>
  );
};

export default MainLoader;
