import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { formatPhoneValue } from '@common/components/form/Form.helpers';
import PhoneInput from '@common/components/phone-input/PhoneInput';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import { Country, useApplicationFlowInstance, useDispatch } from '@common/hooks';
import useUserSessionData from '@common/hooks/useUserSessionData';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { yupResolver } from '@hookform/resolvers/yup';
import { CountryCode } from 'libphonenumber-js/types.cjs';

import m from './PhoneSubmitForm.messages';
import { getFormSchema } from './PhoneSubmitForm.schema';

interface FormValues {
  phone: string;
}

const PhoneSubmitForm = () => {
  const { formatMessage } = useIntl();
  const { saveToStorage } = useUserSessionData();

  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const schema = useMemo(() => getFormSchema({ formatMessage }), [formatMessage]);

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    getValues,
    formState: { isSubmitting, isValid },
  } = ctx;

  const handleFormSubmit = useCallback(
    async (values: FormValues) => {
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: { phone: formatPhoneValue({ phone: values.phone, country: Country.ES }) },
        })
      )
        .unwrap()
        .then(() => saveToStorage({ phone: values.phone }));
    },
    [dispatch, saveToStorage, instanceId]
  );

  return (
    <SignupBaseCard headerText={formatMessage(m.headerText)}>
      <form
        autoComplete="off"
        className="flex size-full"
        onSubmit={ctx.handleSubmit(async (v: FormValues) => {
          try {
            await handleFormSubmit(v);
          } catch (_) {}
        })}
      >
        <FormProvider {...ctx}>
          <div className="flex size-full flex-col gap-8 sm:gap-12">
            <PhoneInput
              country={Country.ES.toUpperCase() as CountryCode}
              name="phone"
              label={formatMessage(m.labelText)}
              descriptionNode={
                <div className="mt-2 rounded-2xl bg-stone-50 px-7 py-3 text-sm font-medium">
                  {formatMessage(m.inputDescriptionText)}
                </div>
              }
            />
            <Button
              type="button"
              onClick={() => handleFormSubmit(getValues())}
              fullWidth
              text={formatMessage(m.submitButtonText)}
              disabled={isSubmitting || !isValid}
            />
          </div>
        </FormProvider>
      </form>
    </SignupBaseCard>
  );
};

export default PhoneSubmitForm;
