const ArrowRightIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="7" height="15" viewBox="0 0 7 15" fill="none">
      <path
        d="M0.874999 13.8398L5.53929 8.39817C5.87633 8.00496 5.87633 7.42473 5.53929 7.03151L0.874998 1.58984"
        stroke="black"
        strokeWidth="1.3125"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowRightIcon;
