import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';

const AccountLoader = () => {
  return (
    <div className="flex h-40 w-full items-center justify-center">
      <BlackDotsLoader style={{ height: 40 }} />
    </div>
  );
};

export default AccountLoader;
