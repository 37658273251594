const ArrowDownIcon = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.5 16.168L11.7929 19.4609C12.1834 19.8514 12.8166 19.8514 13.2071 19.4609L16.5 16.168M12.5 19.168L12.5 5.16797"
        stroke="#2B3F6C"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ArrowDownIcon;
