import { useSearchParams } from 'react-router-dom';

const useMonthioUrlParams = () => {
  const [searchParams] = useSearchParams();
  const paymentSessionId = searchParams.get('cko-payment-session-id');
  const paymentId = searchParams.get('cko-payment-id');

  return {
    paymentSessionId,
    paymentId,
  };
};

export default useMonthioUrlParams;
