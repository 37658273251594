export const LINKS = {
  ES_SMARTSAVER: 'https://monefit.com/es-es/smartsaver/',
  ES_FACEBOOK: 'https://www.facebook.com/monefitespana/',
  ES_MAILTO: 'mailto:spain@monefit.com',
  INSTAGRAM: 'https://www.instagram.com/monefit_official/',
  YOUTUBE: 'https://www.youtube.com/@monefit',
  FACEBOOK: 'https://www.facebook.com/monefit/',
  LINKEDIN: 'https://www.linkedin.com/company/monefitglobal/',
  X: 'https://x.com/monefit_com',
  ABOUT: 'https://monefit.com/about/',
  ES_ABOUT: 'https://monefit.com/es-es/sobre-nosotros/',
  SELF_SERVICE_URL: 'https://monefit.com/',
};
