import { isUUID } from '@common/utils';

const useFlowIdFromUrl = () => {
  const urlPathComponents = window.location.pathname.split('/'); // e.g. ['', et-ee, {flowId}, ...rest]
  const flowId = urlPathComponents?.[2];
  const hasFlowId = isUUID(flowId);

  return { hasFlowId, flowId: hasFlowId ? flowId : null };
};

export default useFlowIdFromUrl;
