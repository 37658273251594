import { bankStatementService } from '@common/services/bank-statement';
import {
  CreateInvitationRequest,
  CreateInvitationResponse,
  SubmitCallbackRequest,
  SubmitCallbackResponse,
} from '@common/services/bank-statement.types';
import { ApiError } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export const createBankStatementInvitation = createAsyncThunk<
  CreateInvitationResponse,
  CreateInvitationRequest
>('bank-statement/invitation/create', async (data): Promise<CreateInvitationResponse> => {
  try {
    const response = await bankStatementService.post<
      CreateInvitationRequest,
      AxiosResponse<CreateInvitationResponse>
    >('/invitation/create', {
      ...data,
    });
    return response.data;
  } catch (e) {
    throw new ApiError();
  }
});

export const handleBankStatementCallback = createAsyncThunk<
  SubmitCallbackResponse,
  SubmitCallbackRequest
>('bank-statement/callback', async ({ provider }): Promise<SubmitCallbackResponse> => {
  try {
    const response = await bankStatementService.post<
      SubmitCallbackRequest,
      AxiosResponse<SubmitCallbackResponse>
    >(`/callback?provider=${provider}`, {});
    return response.data;
  } catch (e) {
    throw new ApiError();
  }
});

// TODO: implementation for placeholder below when needed
export const createBankStatementCategorization = () => {};

// TODO: implementation for placeholder below when needed
export const getBankStatementInvitation = () => {};

// TODO: implementation for placeholder below when needed
export const getBankStatement = () => {};

// TODO: implementation for placeholder below when needed
export const getBankStatementCategorization = () => {};

// TODO: implementation for placeholder below when needed
export const getBankStatements = () => {};

// TODO: implementation for placeholder below when needed
export const getBankStatementCategorizations = () => {};
