import { FC, useCallback, useMemo } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { MainLayoutProps } from '@common/components/layout/Layout.Main';
import MetaTags from '@common/components/meta-tags/MetaTags';
import { useSelector } from '@common/hooks/useSelector';
import {
  selectAuthenticationLoading,
  selectFlowInstanceLoading,
  selectMeta,
} from '@common/redux/selectors';
import { selectMainLoading } from '@common/redux/selectors/banking';
import clsx from 'clsx';

import Footer from '../footer/Footer';
import Header from '../header/Header';
import MainLoader from '../loaders/MainLoader';
import WaitingListFooter from '../waiting-list-footer/WaitingListFooter';

/**
 * TODO: delete this and replace usages with common/components/layout/Layout.Main
 */
const MainLayout: FC<MainLayoutProps> = ({
  children,
  userDisplayNameNode,
  hideHeaderAndFooter = false,
  hideHeader = false,
  showLoader: showLoaderProp = true,
  isWaitingList = false,
  hideProgressBar = false,
  type,
  loadingProp,
  childrenWrapperAlign = 'center',
}) => {
  const authenticationLoading = useSelector(selectAuthenticationLoading);
  const applicationLoading = useSelector(selectFlowInstanceLoading);
  const bankingLoading = useSelector(selectMainLoading);
  const applicationMeta = useSelector(selectMeta);
  const { formatMessage } = useIntl();
  const isLoading = useMemo(
    () => authenticationLoading || applicationLoading || bankingLoading || loadingProp,
    [authenticationLoading, applicationLoading, bankingLoading, loadingProp]
  );

  const renderFooter = useCallback(() => {
    if (isWaitingList) {
      return <WaitingListFooter />;
    } else {
      return <Footer type={type} />;
    }
  }, [isWaitingList, type]);

  const showLoader = showLoaderProp && applicationMeta.showLoader;

  return (
    <>
      <HelmetProvider>
        <MetaTags
          title="Monefit Credit"
          description={formatMessage({
            defaultMessage: 'Interest-free creditline',
            description: 'Interest-free creditline',
          })}
        />
      </HelmetProvider>

      <div
        className={clsx('flex min-h-svh flex-col gap-6 bg-stone-50 p-2 md:px-12 md:py-6', {
          'justify-center': hideHeaderAndFooter || isLoading,
          'justify-between': !(hideHeaderAndFooter || isLoading),
        })}
      >
        {isLoading && showLoader ? (
          <MainLoader />
        ) : (
          <>
            {!(hideHeaderAndFooter || hideHeader) && (
              <Header
                userDisplayNameNode={userDisplayNameNode}
                isWaitingList={isWaitingList}
                type={type}
                hideProgressBar={hideProgressBar}
                loadingProp={isLoading}
              />
            )}

            <div
              className={clsx('flex size-full flex-1 grow flex-col items-center', {
                'justify-center': childrenWrapperAlign === 'center',
                'justify-start': childrenWrapperAlign === 'start',
              })}
            >
              {children}
            </div>

            {!hideHeaderAndFooter && renderFooter()}
          </>
        )}
      </div>
    </>
  );
};

export default MainLayout;
