import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import ButtonGroupInput from '@common/components/button-group-input/ButtonGroupInput';
import BackButton from '@common/components/button/BackButton';
import Button from '@common/components/button/Button';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import { handleFormFieldsEventTracking } from '@common/components/form/Form.helpers';
import Modal from '@common/components/modal/Modal';
import SelectInput from '@common/components/select-input/SelectInput';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import TextInput from '@common/components/text-input/TextInput';
import YesNoInput from '@common/components/yes-no-input/YesNoInput';
import { LocalStorageKeys } from '@common/constants';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, SubStep } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import { yupResolver } from '@hookform/resolvers/yup';
import ActiveCreditsInputGroup from '@monefit-ee/components/active-credits-input-group/ActiveCreditsInputGroup';
import useFinancesForm from '@monefit-ee/hooks/useFinancesForm';
import { saveBankSubStepToStorage } from '@monefit-ee/pages/bank-page/bankPageHelpers';

import { ActiveCredit, EmploymentStatus, FinancesFormValues } from './FinancesForm.types';

const FIELDS_TO_TRACK_ON_CHANGE = [
  {
    fieldName: 'loanPurpose',
    event: TrackEvent.FINANCES_PAGE_LOAN_PURPOSE_SELECT,
  },
  {
    fieldName: 'monthlyExpenses',
    event: TrackEvent.FINANCES_PAGE_EXPENSES_SELECT,
  },
  {
    fieldName: 'monthlyExpenses',
    event: TrackEvent.FINANCES_PAGE_EXPENSES_SELECT,
  },
  {
    fieldName: 'monthlyNetIncome',
    event: TrackEvent.FINANCES_PAGE_INCOME_SELECT,
  },
  {
    fieldName: 'maritalStatus',
    event: TrackEvent.FINANCES_PAGE_MARITAL_SELECT,
  },
  {
    fieldName: 'hasCreditStory',
    event: TrackEvent.FINANCES_PAGE_USED_CL_SELECT,
  },
  {
    fieldName: 'hasActiveCredits',
    event: TrackEvent.FINANCES_PAGE_ACTIVE_LOANS_SELECT,
  },
  {
    fieldName: 'isPep',
    event: TrackEvent.FINANCES_PAGE_IS_PEP_SELECTED,
  },
] as { fieldName: keyof FinancesFormValues; event: TrackEvent }[];

const FinancesForm = () => {
  const { trackEvent } = useTracker({});
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();
  const [showPEPModal, setShowPEPModal] = useState(false);

  const getDefaultValues = useCallback(() => {
    const defaultValue = {
      activeCredits: [],
    };
    const valuesFromSessionStorageRaw =
      sessionStorage.getItem(`${LocalStorageKeys.FINANCES_FORM_DATA}_${instanceId}`) || null;
    if (!valuesFromSessionStorageRaw) {
      return defaultValue;
    }
    const parsed = JSON.parse(valuesFromSessionStorageRaw);
    return parsed ?? defaultValue;
  }, [instanceId]);

  const {
    loanPurposeOptions,
    educationOptions,
    familyStatusOptions,
    employmentTypeOptions,
    employmentDurationOptions,
    dependetsOptions,
    schema,
    pepExplanationText,
    propertyOptions,
    fieldOfActivityOptions,
  } = useFinancesForm();

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: getDefaultValues(),
  });

  const {
    watch,
    formState: { isSubmitting, isValid },
  } = ctx;

  const hasActiveCredits = watch('hasActiveCredit');
  const hasCreditHistory = watch('hasCreditHistory');
  const propertyOwner = watch('propertyOwner');
  const employmentType = watch('employmentType');
  const activeCredits = watch('activeCredits') as ActiveCredit[];

  const hasToFillExtraJobInputs = !![
    EmploymentStatus.Salary,
    EmploymentStatus.Self_employed,
  ].includes(employmentType);

  const { fields, append, remove } = useFieldArray({
    control: ctx.control,
    name: 'activeCredits',
  });

  const resetHasActiveCredits = useCallback(() => {
    ctx.resetField('hasActiveCredit');
    ctx.setValue('hasActiveCredit' as any, undefined);
  }, [ctx]);

  const removeAllCredits = useCallback(() => {
    for (let i = fields.length - 1; i >= 0; i--) {
      remove(i);
    }
  }, [remove, fields]);

  const handleDeselectPropertyOwner = useCallback(() => {
    ctx.setValue('propertyTypes', []);
  }, [ctx]);

  const handleCreditHistoryDeselect = useCallback(() => {
    removeAllCredits();
    ctx.setValue('hasActiveCredit' as any, false);
  }, [removeAllCredits, ctx]);

  const handleSubmit = useCallback(
    async (values: FinancesFormValues) => {
      await dispatch(
        performApplicationFlowAction({
          data: { ...values },
          action: FlowActions.SUBMIT,
          instanceId,
        })
      )
        .unwrap()
        .then(() => {
          dispatch(setCurrentSubstepId(SubStep.BANK_START));
          saveBankSubStepToStorage(instanceId, SubStep.BANK_START);
        });
    },
    [dispatch, instanceId]
  );

  useEffect(() => {
    if (hasActiveCredits && !activeCredits.length) {
      append({
        activeCreditType: null,
        activeCreditAmount: null,
      });
    }
  }, [hasActiveCredits, append, activeCredits]);

  useEffect(() => {
    watch((values: FinancesFormValues) => {
      for (const field of FIELDS_TO_TRACK_ON_CHANGE) {
        const key = field.fieldName;
        const value = values[key];
        value && trackEvent(field.event);
      }
    });
  }, [watch, trackEvent]);

  const handleRemoveCredit = useCallback(
    (i: number) => {
      remove(i);
      const { activeCredits } = ctx.getValues();
      ctx.setValue('hasActiveCredit' as any, activeCredits?.length !== 0);
    },
    [remove, ctx]
  );

  const currentFormValues = watch();

  // Save entered data to sessionStorage
  useEffect(() => {
    const sessionStorageObject = { ...currentFormValues };
    sessionStorage.setItem(
      `${LocalStorageKeys.FINANCES_FORM_DATA}_${instanceId}`,
      JSON.stringify(sessionStorageObject)
    );
  }, [currentFormValues, instanceId]);

  useEffect(() => {
    if (!hasToFillExtraJobInputs) {
      ctx.setValue('employerName', undefined);
      ctx.setValue('fieldOfActivity', undefined);
      ctx.setValue('employmentDuration', undefined);
    }

    if (employmentType !== EmploymentStatus.Salary) {
      ctx.setValue('jobTitle', undefined);
    }
  }, [hasToFillExtraJobInputs, ctx, employmentType]);

  const employerNameLabel = useMemo(() => {
    if (employmentType === EmploymentStatus.Salary) {
      return formatMessage({ defaultMessage: 'Employer name' });
    }
    if (employmentType === EmploymentStatus.Self_employed) {
      return formatMessage({ defaultMessage: 'FIE/Company name' });
    }

    return '';
  }, [employmentType, formatMessage]);

  useEffect(() => {
    const subscription = watch((values) => {
      handleFormFieldsEventTracking(values, FIELDS_TO_TRACK_ON_CHANGE, trackEvent);
    });
    return () => subscription.unsubscribe();
  }, [watch, trackEvent]);

  return (
    <SignupBaseCard
      headerText={formatMessage({
        defaultMessage: 'Your finances',
        description: 'Finances: "Your finances"',
      })}
      subHeaderText={formatMessage({
        defaultMessage:
          'Please complete the sections below with your employment and financial information.',
        description:
          'Finances: "Please complete the sections below with your employment and financial information."',
      })}
    >
      <div className="flex w-full flex-col">
        <FormProvider {...ctx}>
          <form
            onSubmit={ctx.handleSubmit(async (v: FinancesFormValues) => {
              try {
                await handleSubmit(v);
                sessionStorage.removeItem(`${LocalStorageKeys.FINANCES_FORM_DATA}_${instanceId}`);
                trackEvent(TrackEvent.FINANCES_PAGE_SUBMIT);
              } catch (_) {
                trackEvent(TrackEvent.FINANCES_PAGE_ERROR);
              }
            })}
          >
            <div className={`flex size-full flex-col justify-center gap-12`}>
              <div className="flex w-full flex-col gap-2">
                <SelectInput
                  name="loanPurpose"
                  options={loanPurposeOptions}
                  label={formatMessage({
                    defaultMessage: 'Loan purpose',
                    description: 'Finances: "Loan purpose"',
                  })}
                />
                <SelectInput
                  name="education"
                  options={educationOptions}
                  label={formatMessage({
                    defaultMessage: 'Education',
                    description: 'Finances: Education',
                  })}
                />
              </div>
              <div className="flex w-full flex-col gap-2">
                <div className=" text-lg font-normal leading-normal text-black">
                  <FormattedMessage
                    defaultMessage="Your employment status"
                    description="Finances: 'Your employment status'"
                  />
                </div>
                <SelectInput
                  name="employmentType"
                  options={employmentTypeOptions}
                  label={formatMessage({
                    defaultMessage: 'Source of income',
                    description: 'Finances: "Source of income"',
                  })}
                />
                {hasToFillExtraJobInputs && (
                  <TextInput name="employerName" label={employerNameLabel} />
                )}
                {hasToFillExtraJobInputs && (
                  <SelectInput
                    name="employmentDuration"
                    options={employmentDurationOptions}
                    label={formatMessage({
                      defaultMessage: 'Years of employment',
                      description: 'Finances: "Years of employment"',
                    })}
                  />
                )}
                {employmentType === EmploymentStatus.Salary && (
                  <TextInput
                    name="jobTitle"
                    label={formatMessage({
                      defaultMessage: 'Job title',
                      description: 'Finances: Job title',
                    })}
                  />
                )}
                {hasToFillExtraJobInputs && (
                  <SelectInput
                    name="fieldOfActivity"
                    options={fieldOfActivityOptions}
                    label={formatMessage({
                      defaultMessage: 'Field of activity',
                      description: 'Finances: Field of activity',
                    })}
                  />
                )}
              </div>

              <div className="flex w-full flex-col gap-2">
                <div className=" text-lg font-normal leading-normal text-black">
                  <FormattedMessage
                    defaultMessage="Your income status"
                    description="Finances: 'Your income status'"
                  />
                </div>
                <CurrencyInput
                  name="monthlyNetIncome"
                  label={formatMessage({
                    defaultMessage: 'Net per month',
                    description: 'Finances: "Net per month"',
                  })}
                />
                <CurrencyInput
                  name="monthlyExpenses"
                  label={formatMessage({
                    defaultMessage: 'Home expenses per month',
                    description: 'Finances: "Home expenses per month"',
                  })}
                />
                <YesNoInput
                  name="propertyOwner"
                  externalNoHandler={handleDeselectPropertyOwner}
                  label={formatMessage({
                    defaultMessage: 'Do you own property?',
                    description: 'Finances: Do you own property?',
                  })}
                />
                {!!propertyOwner && (
                  <ButtonGroupInput
                    name="propertyTypes"
                    options={propertyOptions}
                    label={formatMessage({ defaultMessage: 'Type of property' })}
                  />
                )}
              </div>

              <div className="flex w-full flex-col gap-2">
                <div className=" text-lg font-normal leading-normal text-black">
                  <FormattedMessage
                    defaultMessage="Your family status"
                    description="Finances: 'Your family status'"
                  />
                </div>
                <SelectInput
                  name="maritalStatus"
                  options={familyStatusOptions}
                  label={formatMessage({
                    defaultMessage: 'Marital status',
                    description: 'Finances: "Marital status"',
                  })}
                />
                <SelectInput
                  name="financialDependents"
                  options={dependetsOptions}
                  label={formatMessage({
                    defaultMessage: 'Financial dependents',
                    description: 'Finances: "Financial dependents"',
                  })}
                />
              </div>

              <div className="flex w-full flex-col gap-2">
                <div className=" text-lg font-normal leading-normal text-black">
                  <FormattedMessage
                    defaultMessage="Your credit status"
                    description="Finances: 'Your credit status'"
                  />
                </div>
                <YesNoInput
                  name="hasCreditHistory"
                  externalNoHandler={handleCreditHistoryDeselect}
                  externalYesHandler={resetHasActiveCredits}
                  label={formatMessage({
                    defaultMessage: 'Have you used credit services before?',
                    description: 'Finances: "Have you used credit services before?"',
                  })}
                />
                {hasCreditHistory && (
                  <YesNoInput
                    name="hasActiveCredit"
                    externalNoHandler={removeAllCredits}
                    label={formatMessage({
                      defaultMessage: 'Do you have any active loan?',
                      description: 'Finances: "Do you have any active loan?"',
                    })}
                  />
                )}
              </div>
              {!!activeCredits?.length && (
                <ActiveCreditsInputGroup {...{ fields, append, handleRemoveCredit }} />
              )}
              <div className="flex w-full flex-col gap-2">
                <YesNoInput
                  labelComponent={
                    <div className="text-sm font-medium leading-normal text-black text-opacity-100">
                      {formatMessage({
                        defaultMessage: 'Are you or an immediate family member a',
                        description: 'Finances: Are you or an immediate family member a',
                      })}{' '}
                      <span
                        role="presentation"
                        className="cursor-pointer underline"
                        onClick={() => setShowPEPModal(true)}
                      >
                        {formatMessage({
                          defaultMessage: 'Politically Exposed Person (PEP).',
                          description: 'Finances: Politically Exposed Person (PEP).',
                        })}
                      </span>
                    </div>
                  }
                  name="isPep"
                />
              </div>
              <div className="flex w-full gap-3">
                <BackButton />
                <Button
                  disabled={isSubmitting || !isValid}
                  color="black"
                  fullWidth
                  text={formatMessage({
                    defaultMessage: 'Continue',
                    description: 'Finances: Continue button',
                  })}
                  type="submit"
                />
              </div>
            </div>
          </form>
        </FormProvider>
      </div>
      <Modal isOpen={showPEPModal} onBackClick={() => setShowPEPModal(false)}>
        <div>{pepExplanationText}</div>
      </Modal>
    </SignupBaseCard>
  );
};
export default FinancesForm;
