import { FC, JSX, useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '@common/components/form/Form';
import SelectInput from '@common/components/select-input/SelectInput';
import TextInput from '@common/components/text-input/TextInput';
import * as yup from 'yup';

export interface FormValues {
  amount: number;
}

interface ApplicationFlowStartFormProps {
  onSubmit: (values: FormValues) => void;
}

const OFFERS = ['short_term_new_customer_v1', 'long_term_new_customer_v1'];

const ApplicationFlowStartForm: FC<ApplicationFlowStartFormProps> = ({ onSubmit }): JSX.Element => {
  const { formatMessage } = useIntl();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        amount: yup.number().required(
          formatMessage({
            defaultMessage: 'The field is mandatory',
            description: 'Start: "The field is mandatory" (amount field)',
          })
        ),
        duration: yup.number(),
        offer: yup.string(),
      }),
    [formatMessage]
  );

  return (
    <Form gapClass="gap-3" schema={schema} onSubmit={onSubmit}>
      <TextInput type="number" name="amount" label="Amount" step="1" defaultValue={1000} />
      <TextInput type="number" name="duration" label="Duration" step="1" />
      <SelectInput
        name="offer"
        label="Offer"
        options={OFFERS.map((offer) => ({ label: offer, value: offer }))}
      />
    </Form>
  );
};

export default ApplicationFlowStartForm;
