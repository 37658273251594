import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/documents';

export const makeSelectDocuments = (state: RootState) =>
  'documents' in state ? state.documents : initialState;

export const selectDocumentsLoading = createSelector(makeSelectDocuments, (b) => b.loading);
export const selectDocumentsError = createSelector(makeSelectDocuments, (b) => b.error);
export const selectDocuments = createSelector(makeSelectDocuments, (b) => b.documents);
