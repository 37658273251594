import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import MainLoader from '@common/components/loader/MainLoader';
import { useApplicationFlowInstance, useDispatch } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { ErrorBoundaryPage as ErrorBoundaryEE } from '@monefit-ee/pages';
import ESLoader from '@monefit-es/components/loaders/MainLoader';
import { ErrorBoundaryPage as ErrorBoundaryES } from '@monefit-es/pages';

import useCountry, { Country } from './useCountry';

export enum ActionStatus {
  LOADING = 'loading',
  ERRORED = 'errored',
  FETCHED = 'fetched',
}

const useInitialFlowActionPerform = ({
  successComponent,
  flowAction,
}: {
  successComponent?: ReactNode;
  flowAction?: FlowActions;
}) => {
  const [status, setStatus] = useState(ActionStatus.LOADING);
  const dispatch = useDispatch<RootDispatch>();
  const { country } = useCountry();
  const [instanceId] = useApplicationFlowInstance();

  const loader = useMemo(() => {
    switch (country) {
      case Country.ES:
        return <ESLoader />;
      default:
        return <MainLoader />;
    }
  }, [country]);

  const errorBoundary = useMemo(() => {
    switch (country) {
      case Country.ES:
        return <ErrorBoundaryES hasSignupLayout={false} />;
      case Country.EE:
        return <ErrorBoundaryEE hasSignupLayout={false} />;
    }
  }, [country]);

  const performAction = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({ action: flowAction ?? FlowActions.FETCH, instanceId })
    )
      .unwrap()
      .then(() => {
        setStatus(ActionStatus.FETCHED);
      })
      .catch(() => {
        setStatus(ActionStatus.ERRORED);
      });
  }, [instanceId, flowAction, dispatch]);

  const renderView = useCallback(() => {
    switch (status) {
      case ActionStatus.LOADING:
        return loader;
      case ActionStatus.FETCHED:
        return successComponent;
      case ActionStatus.ERRORED:
        return errorBoundary;
    }
  }, [status, loader, errorBoundary, successComponent]);

  useEffect(() => {
    performAction();
  }, []);

  return { renderView, status };
};

export default useInitialFlowActionPerform;
