import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  buttonText: {
    defaultMessage: 'Log out',
  },
  descriptionText: {
    defaultMessage: 'Are you sure you want to log out from Monefit?',
  },
});
