// TODO: move to common enums
export enum AppId {
  CREDITSTAR = 'creditstar',
  MONEFIT = 'monefit',
}

/**
 * Hook to get the brand of currently built application
 */
const useApp = () => {
  const { REACT_APP_ID } = process.env;

  const appId = Object.values(AppId).includes(REACT_APP_ID as AppId)
    ? (REACT_APP_ID as AppId)
    : null;

  return { appId };
};

export default useApp;
