import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/bank-statement';

export const makeSelectBankStatement = (state: RootState) =>
  'bankStatement' in state ? state.bankStatement : initialState;

export const selectLoading = createSelector(makeSelectBankStatement, (bs) => bs.loading);

export const selectError = createSelector(makeSelectBankStatement, (bs) => bs.error);

export const selectInvitationRedirectUrl = createSelector(
  makeSelectBankStatement,
  (bs) => bs.invitation.redirectUrl
);

export const selectInvitationReferenceId = createSelector(
  makeSelectBankStatement,
  (bs) => bs.invitation.referenceId
);

export const selectInvitationId = createSelector(makeSelectBankStatement, (bs) => bs.invitation.id);

export const selectCallbackStatus = createSelector(
  makeSelectBankStatement,
  (bs) => bs.callback.status
);
