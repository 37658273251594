import { User } from '@common/services/user.types';
import { createSlice } from '@reduxjs/toolkit';

import { fetchUser } from '../thunks/user';

interface UserInitialState {
  loading: boolean;
  error: boolean;
  data: {
    user: User | null;
  };
}

export const initialState: UserInitialState = {
  loading: false,
  error: false,
  data: {
    user: null,
  },
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: () => ({ ...initialState }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUser.pending, (state) => ({
      ...state,
      loading: true,
      error: false,
    }));

    builder.addCase(fetchUser.rejected, (state) => ({
      ...state,
      loading: false,
      error: true,
    }));

    builder.addCase(fetchUser.fulfilled, (state, { payload }) => ({
      ...state,
      data: { ...state.data, user: payload },
      loading: false,
      error: false,
    }));
  },
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;
