import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/banking';

export const makeSelectBanking = (state: RootState) =>
  'banking' in state ? state.banking : initialState;

// loading state
export const selectMainLoading = createSelector(makeSelectBanking, (b) => b.loading);
export const selectLimitLoading = createSelector(makeSelectBanking, (b) => b.userLimit.loading);

export const selectBalanceLoading = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalance.loading
);
// error gstate
export const selectMainError = createSelector(makeSelectBanking, (b) => b.error);

export const selectBalanceError = createSelector(
  makeSelectBanking,
  (b) => b.agreementBalance.error
);

export const selectLimitError = createSelector(makeSelectBanking, (b) => b.userLimit.error);

// data
export const selectActiveAgreement = createSelector(makeSelectBanking, (b) => b.activeAgreement);
export const selectUserLimit = createSelector(makeSelectBanking, (b) => b.userLimit.data);
export const selectBalance = createSelector(makeSelectBanking, (b) => b.agreementBalance.data);
