import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headTitle: {
    defaultMessage: 'Pay 0% interest and borrow up to 10.000 € with Credit+',
    description: 'Pay 0% interest and borrow up to 10.000 € with Credit+',
  },
  upgadeBtnText: {
    defaultMessage: 'Upgrade',
    description: 'Upgrade',
  },
  availableCredit: {
    defaultMessage: 'AVAILABLE CREDIT',
    description: 'AVAILABLE CREDIT',
  },
  detailsBtnText: {
    defaultMessage: 'Details',
    description: 'Details',
  },
  withdrawBtnText: {
    defaultMessage: 'Withdraw',
    description: 'Withdraw',
  },
  paymentOptionsBtnText: {
    defaultMessage: 'See payment options',
    description: 'See payment options',
  },
  openFAQbtnText: {
    defaultMessage: 'Open FAQ',
    description: 'Open FAQ',
  },
  gotMoreQuestions: {
    defaultMessage: 'Got more questions?',
    description: 'Got more questions?',
  },
  faqDescription: {
    defaultMessage: 'We have a section for frequently asked questions you can check out.',
    description: 'We have a section for frequently asked questions you can check out.',
  },
  upgradeToPremium: {
    defaultMessage: 'Upgrade to Credit+',
    description: 'Upgrade to Credit+',
  },
  premiumDescription: {
    defaultMessage: 'Get premium for zero interest on timely payments and more benefits.',
    description: '"Get premium for zero interest on timely payments and more benefits.',
  },
  noInvoiceHeaderText: {
    defaultMessage: 'All settled',
    description: '@monefit-es All settled',
  },
  noInvoiceDescriptionText: {
    defaultMessage: "You've got no outstanding invoices",
    description: "@monefit-es You've got no outstanding invoices",
  },
  agreementTerminatedHeaderText: {
    defaultMessage: 'Your case is now handled by Heimondo',
    description: '@monefit-es Your case is now handled by Heimondo',
  },
  agreementTerminatedDescriptionText: {
    defaultMessage:
      'Check your email for next steps in handling the payments.We hope you get it settled in time',
    description:
      '@monefit-es Check your email for next steps in handling the payments.We hope you get it settled in time',
  },
  noInvoiceAndHasBalanceHeaderText: {
    defaultMessage: "There's still time for the due date",
    description: "@monefit-es There's still time for the due date",
  },
  noInvoiceAndHasBalanceDescriptionText: {
    defaultMessage: 'Pay {amount} by {date} to enjoy 0% interest',
    description: '@monefit-es Pay {amount} € by {date} to enjoy 0% interest',
  },
  invoiceNotOverdueHeaderText: {
    defaultMessage: '{monthName} invoice is ready',
    description: '@monefit-es {monthName} invoice is ready',
  },
  invoiceNotOverdueDescriptionText: {
    defaultMessage: 'Pay {amount} € by {date} to enjoy 0% interest',
    description: '@monefit-es Pay {amount} € by {date} to enjoy 0% interest',
  },
  oneOrTwoInvoicesOverdueHeaderText: {
    defaultMessage: '{monthName} invoice is overdue',
    description: '@monefit-es {monthName} invoice is overdue',
  },
  oneOrTwoInvoicesOverdueDescriptionText: {
    defaultMessage: 'Pay as early as you can to reduce further changes on your rolled over balance',
    description:
      '@monefit-es Pay as early as you can to reduce further changes on your rolled over balance',
  },
  noInvoiceAndHasBalanceDescriptionTextNoSubscription: {
    defaultMessage: 'Your invoice amount is {amount} €',
    description: '@monefit-es Your invoice amount is {amount} €',
  },
  invoiceNotOverdueDescriptionTextNoSubscription: {
    defaultMessage: 'Your invoice amount is {amount} €',
    description: '@monefit-es Your invoice amount is {amount} €',
  },
});
