import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import NoInvoiceIcon from '@common/components/icons/NoInvoice';
import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
import clsx from 'clsx';

import m from './InvoicePage.Messages';

const InvoicePage = () => {
  const breakpoint = useTailwindBreakpoints();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();
  const isMobile = useMemo(
    () => !!(breakpoint && [TWBreakPoint.XS, TWBreakPoint.SM].includes(breakpoint)),
    [breakpoint]
  );

  const handleBackClick = useCallback(() => {
    navigate(`/${localeWithCountry}/user`);
  }, [localeWithCountry, navigate]);

  return (
    <Layout.WithNavigation
      childrenWrapperAlign="start"
      maxWDesktop={700}
      maxWMobile={550}
      hideProgressBar
      onBackButtonClick={handleBackClick}
      backButtonText={formatMessage(m.previousInvoices)}
    >
      <div className="p-8"></div>
      <div
        className={clsx(
          'box-border flex flex-col items-center justify-center rounded-2xl bg-white p-8',
          {
            'w-full': isMobile,
            'flex-[0.65]': !isMobile,
          }
        )}
      >
        <NoInvoiceIcon />
        <div className="text-2xl font-semibold">{formatMessage(m.noInvoicesYet)}</div>
        <div className="self-stretch p-4 text-center">{formatMessage(m.noInvoicesMessage)}</div>
      </div>
    </Layout.WithNavigation>
  );
};

export default InvoicePage;
