import { useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccountLogout from '@common/components/account-logout/AccountLogout';
import AccountNavBar from '@common/components/account-nav-bar/AccountNavBar';
import { NavBarItem } from '@common/components/account-nav-bar/AccoutNavBar.types';
import AccountPersonalDetails from '@common/components/account-personal-details/AccountPersonalDetails';
import AccountSettings from '@common/components/account-settings/AccountSettings';
import Layout from '@common/components/layout/Layout';
import { useLocale } from '@common/hooks';
import useTailwindBreakpoints, { TWBreakPoint } from '@common/hooks/useTailwindBreakpoints';
import clsx from 'clsx';

import m from './AccountPage.messages';

const AccountPage = () => {
  const breakpoint = useTailwindBreakpoints();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();
  const isMobile = useMemo(
    () => !!(breakpoint && [TWBreakPoint.XS, TWBreakPoint.SM].includes(breakpoint)),
    [breakpoint]
  );

  const [selectedMenuItem, setSelectedMenuItem] = useState<NavBarItem | null>(null);

  const menuComponent = useMemo(() => {
    switch (selectedMenuItem) {
      case null:
        return null;
      case NavBarItem.PERSONAL_DETAILS:
        return <AccountPersonalDetails />;
      case NavBarItem.ACCOUNT_SETTINGS:
        return <AccountSettings />;
      case NavBarItem.LOG_OUT:
        return <AccountLogout />;
      default:
        return null;
    }
  }, [selectedMenuItem]);

  const handleBackClick = useCallback(() => {
    if (isMobile && selectedMenuItem) {
      setSelectedMenuItem(null);
    } else {
      navigate(`/${localeWithCountry}/user`);
    }
  }, [localeWithCountry, selectedMenuItem, isMobile, navigate]);

  useEffect(() => {
    setSelectedMenuItem(isMobile ? null : NavBarItem.PERSONAL_DETAILS);
  }, [isMobile]);

  return (
    <Layout.WithNavigation
      childrenWrapperAlign="start"
      maxWDesktop={800}
      maxWMobile={550}
      hideHeader={isMobile}
      hideProgressBar
      onBackButtonClick={handleBackClick}
      backButtonText={formatMessage(
        selectedMenuItem && isMobile ? m.personalDetails : m.backToDashboard
      )}
    >
      <div className={clsx('flex w-full', { 'gap-3': !isMobile })}>
        <div
          className={clsx('flex h-fit', {
            'w-full': isMobile && !selectedMenuItem,
            'flex-[0.35]': !isMobile,
          })}
        >
          <AccountNavBar selectNavbarItem={setSelectedMenuItem} selectedItem={selectedMenuItem} />
        </div>
        {!!menuComponent && (
          <div
            className={clsx('flex h-fit w-full', { 'w-full': isMobile, 'flex-[0.65]': !isMobile })}
          >
            {menuComponent}
          </div>
        )}
      </div>
    </Layout.WithNavigation>
  );
};

export default AccountPage;
