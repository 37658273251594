import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Form from '@common/components/form/Form';
import { formatPhoneValue } from '@common/components/form/Form.helpers';
import PhoneInput from '@common/components/phone-input/PhoneInput';
import TextInput from '@common/components/text-input/TextInput';
import { Country, useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import {
  FlowActions,
  IdentificationProvider,
  IdentificationReason,
  SubStep,
} from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import * as yup from 'yup';

interface FormValues {
  phone: string;
  personalCode: string;
}

const FIELDS_TO_LOG_ON_CHANGE = [
  {
    fieldName: 'phone',
    event: TrackEvent.SIGNUP_PAGE_DIGIT_ENTETERED,
  },
  {
    fieldName: 'personalCode',
    event: TrackEvent.SIGNUP_PAGE_DIGIT_ENTETERED,
  },
];

const MobileIdForm = () => {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();

  const schema: yup.ObjectSchema<FormValues> = useMemo(
    () =>
      yup.object({
        phone: yup.string().required(
          formatMessage({
            defaultMessage: 'Mobile phone is required',
            description: 'Identification: "Mobile phone is required"',
          })
        ),
        personalCode: yup.string().required(
          formatMessage({
            defaultMessage: 'Personal code is required',
            description: 'Identification: "Personal code is required"',
          })
        ),
      }),
    [formatMessage]
  );

  return (
    <Form
      submitTrackEventName={TrackEvent.SIGNUP_PAGE_SUBMIT}
      fieldsToTrackOnChange={FIELDS_TO_LOG_ON_CHANGE}
      schema={schema}
      onSubmit={({ personalCode, phone }: FormValues) => {
        dispatch(
          performApplicationFlowAction({
            instanceId,
            action: FlowActions.SUBMIT,
            data: {
              personalCode,
              phone: formatPhoneValue({ phone, country: Country.EE }),
              provider: IdentificationProvider.MOBILE_ID,
              reason: IdentificationReason.REGISTER,
            },
          })
        )
          .unwrap()
          .then(() => dispatch(setCurrentSubstepId(SubStep.MOBILE_ID_PENDING)));
      }}
      gapClass="gap-3"
    >
      <PhoneInput
        country="EE"
        required
        name="phone"
        label={formatMessage({
          defaultMessage: 'Phone',
          description: 'Identification: "Phone (input label)"',
        })}
      />
      <TextInput
        type="number"
        step="1"
        name="personalCode"
        label={formatMessage({
          defaultMessage: 'Personal code',
          description: 'Identification: "Personal code (input label)"',
        })}
      />
    </Form>
  );
};

export default MobileIdForm;
