import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerText: {
    defaultMessage: 'Confirm the amount',
    description: 'Confirm the amount',
  },
  subTitle: {
    defaultMessage: 'Withdraw',
    description: 'Withdraw',
  },
  validationText: {
    defaultMessage: 'Bank account is required',
    description: 'Bank account is required',
  },
  cancel: {
    defaultMessage: 'Cancel',
    description: 'Cancel',
  },
  inputLabel: {
    defaultMessage: 'Bank account',
    description: 'Bank account',
  },
});
