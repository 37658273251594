import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  previousInvoices: {
    defaultMessage: 'Previous invoices',
  },
  noInvoicesYet: {
    defaultMessage: 'No invoices yet',
  },
  noInvoicesMessage: {
    defaultMessage:
      'Once you make a withdrawal, all your transaction details and invoices will appear here.',
  },
});
