import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerSubText: {
    defaultMessage: 'Looking good (so far)',
    description: '@monefit-es  Looking good (so far)',
  },
  headerText: {
    defaultMessage: 'You could be eligible for up to {amount} {currency}',
    description: '@monefit-es You could be eligible for up to {amount} {currency}',
  },
  mainText: {
    defaultMessage:
      'To confirm your income and financial details, we just need to verify your bank records. This will help us calculate your final approval limit. For this, we’ll use our trusted partner, Monthio, to securely process your bank information.',
    description:
      '@monefit-es To confirm your income and financial details, we just need to verify your bank records. This will help us calculate your final approval limit. For this, we’ll use our trusted partner, Monthio, to securely process your bank information.',
  },
  buttonText: {
    defaultMessage: 'Continue',
  },
  monthioPrivacyText: {
    defaultMessage:
      'Monthio is a trusted service that securely processes your bank data to calculate your income and expenses. They’ll share this information with us to complete your credit application.',
    description:
      '@monefit-es Monthio is a trusted service that securely processes your bank data to calculate your income and expenses. They’ll share this information with us to complete your credit application.',
  },
});
