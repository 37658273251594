import { format } from 'date-fns';
import { enUS, es } from 'date-fns/locale';

const getDateFnsLocale = (locale: string) => {
  switch (locale) {
    case 'es':
      return es;
    default:
      return enUS;
  }
};

export const getMonthNameByDate = ({
  date,
  locale,
  shouldCapitilizeFirstLetter = false,
}: {
  date: Date | null;
  locale: string;
  shouldCapitilizeFirstLetter?: boolean;
}) => {
  if (!date) {
    return null;
  }
  const dateFnsLocale = getDateFnsLocale(locale);
  const monthName = format(date, 'MMMM', { locale: dateFnsLocale });
  return shouldCapitilizeFirstLetter
    ? monthName.charAt(0).toUpperCase() + monthName.slice(1)
    : monthName;
};
