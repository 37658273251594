import { ReactNode, useCallback } from 'react';
import { Navigate } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import { getIsLoggedIn } from '@common/utils';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import useAuth from '@monefit-es/hooks/useAuth';

import OnboardingProvider from '../onboarding-provider/OnboardingProvider';

// import PrivateRouteDebug from './PrivateRouteDebug';

interface PrivateRouteProps {
  children: ReactNode;
  hasMainLayout?: boolean;
  hasOnboardingProvider?: boolean;
}

const PrivateRoute = ({
  children,
  hasMainLayout = true,
  hasOnboardingProvider = false,
}: PrivateRouteProps) => {
  const { localeWithCountry } = useLocale();
  const isLoggedIn = !!getIsLoggedIn();
  useAuth();

  const renderLayout = useCallback(
    () => (hasMainLayout ? <MainLayout>{children}</MainLayout> : children),
    [children, hasMainLayout]
  );

  if (!isLoggedIn) {
    return <Navigate replace to={`/${localeWithCountry}/login`} />;
  }

  return (
    <>
      {hasOnboardingProvider ? (
        <OnboardingProvider>{renderLayout()}</OnboardingProvider>
      ) : (
        renderLayout()
      )}
    </>
  );
};

export default PrivateRoute;
