import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import EyeIcon from '@common/components/icons/EyeIcon';
import { useDocuments } from '@monefit-es/hooks/useDocuments';

import { DocumentType } from '../documents/Document.types';
import m from './DocumentsItem.messages';

interface DocumentsItemProps {
  headerText: string;
  subText: string;
  onSignClick: () => void;
  type: DocumentType;
}

const DocumentsItem = ({ headerText, subText, onSignClick, type }: DocumentsItemProps) => {
  const { formatMessage } = useIntl();
  const [searchParams] = useSearchParams();
  const documentId = searchParams.get('documentId');
  const isSigned = searchParams.get('isSigned');
  const typeParam = searchParams.get('type');
  const { saveDocumentToStorage, documents } = useDocuments();

  useEffect(() => {
    if (documentId && isSigned && type === typeParam) {
      saveDocumentToStorage({ type, id: documentId, isSigned: isSigned === '1' });
    }
  }, [documentId, isSigned, type, typeParam]);

  const documentSigned = documents.find((x) => x.type === type)?.isSigned === true;

  return (
    <div className="flex flex-col justify-between gap-6 md:flex-row md:items-center md:gap-3">
      <div className="inline-flex shrink grow basis-0 flex-col items-start justify-start gap-5">
        <div className="inline-flex items-start justify-start gap-5 self-stretch">
          <div className="inline-flex flex-col items-start justify-start gap-2.5">
            <div className=" text-lg font-semibold leading-normal text-black">{headerText}</div>
            <div className="text-base font-normal text-black sm:max-w-60">{subText}</div>
          </div>
        </div>
      </div>
      <Button
        icon={documentSigned ? null : <EyeIcon width="19" height="19" />}
        spacing={ButtonSpacing.SM}
        color={documentSigned ? 'blue-alt' : 'gray-alt'}
        disabled={documentSigned}
        text={
          documentSigned
            ? formatMessage(type === DocumentType.SECCI ? m.viewed : m.signed)
            : formatMessage(type === DocumentType.SECCI ? m.view : m.sign)
        }
        onClick={documentSigned ? () => {} : () => onSignClick()}
      />
    </div>
  );
};

export default DocumentsItem;
