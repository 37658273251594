const NoInvoiceIcon = () => (
  <svg
    width="189"
    height="189"
    viewBox="0 0 189 189"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="94.5"
      cy="94.168"
      r="94"
      transform="rotate(-180 94.5 94.168)"
      fill="url(#paint0_linear_4664_893)"
    />
    <path
      d="M51.5002 53.5965C51.5002 46.1801 57.5124 40.168 64.9288 40.168H100.738C125.459 40.168 145.5 60.2085 145.5 84.9298V145.358C145.5 152.775 139.488 158.787 132.071 158.787H64.9288C57.5124 158.787 51.5002 152.775 51.5002 145.358V53.5965Z"
      fill="black"
    />
    <path d="M120.881 40.168L145.5 64.787V88.2869L97.3809 40.168H120.881Z" fill="black" />
    <path
      d="M129.833 64.787H145.5L120.881 40.168V55.8346C120.881 60.3108 125.357 64.787 129.833 64.787Z"
      fill="#E7E6E1"
    />
    <rect x="73.8811" y="96.1211" width="46.9999" height="4.47618" rx="2.23809" fill="white" />
    <rect x="73.8811" y="105.07" width="46.9999" height="4.47618" rx="2.23809" fill="white" />
    <rect x="73.8811" y="114.027" width="46.9999" height="4.47618" rx="2.23809" fill="white" />
    <rect x="73.8811" y="122.977" width="35.8094" height="4.47618" rx="2.23809" fill="white" />
    <circle cx="60.332" cy="51.332" r="16.668" fill="#A5D2DC" />
    <path
      d="M60.7122 67.9921C51.289 68.3 45.8934 59.1413 44.3735 54.5234C58.0524 73.3796 70.9714 62.2198 76.2909 54.5234C75.0244 58.8847 70.1354 67.6842 60.7122 67.9921Z"
      fill="white"
      fillOpacity="0.3"
      stroke="#A5D2DC"
      strokeWidth="0.709275"
    />
    <path
      d="M65.2933 50.0964C65.2933 51.3164 65.1728 52.4157 64.9316 53.3945C64.7046 54.3733 64.3642 55.2032 63.9103 55.8841C63.4705 56.565 62.9173 57.0899 62.2506 57.4587C61.598 57.8275 60.8462 58.0119 59.9951 58.0119C59.1439 58.0119 58.385 57.8275 57.7183 57.4587C57.0516 57.0899 56.4912 56.565 56.0373 55.8841C55.5834 55.2032 55.2358 54.3733 54.9947 53.3945C54.7677 52.4157 54.6542 51.3164 54.6542 50.0964C54.6542 48.8764 54.7677 47.7771 54.9947 46.7983C55.2358 45.8195 55.5834 44.9896 56.0373 44.3087C56.4912 43.6278 57.0516 43.1029 57.7183 42.7341C58.385 42.3653 59.1439 42.1809 59.9951 42.1809C60.8462 42.1809 61.598 42.3653 62.2506 42.7341C62.9173 43.1029 63.4705 43.6278 63.9103 44.3087C64.3642 44.9896 64.7046 45.8195 64.9316 46.7983C65.1728 47.7771 65.2933 48.8764 65.2933 50.0964ZM62.6761 50.0964C62.6761 49.5715 62.6407 48.9757 62.5697 48.309C62.513 47.6423 62.3853 47.0111 62.1867 46.4153C62.0023 45.8195 61.7328 45.323 61.3781 44.9258C61.0235 44.5144 60.5625 44.3087 59.9951 44.3087C59.4135 44.3087 58.9453 44.5144 58.5907 44.9258C58.2361 45.323 57.9594 45.8195 57.7608 46.4153C57.5764 47.0111 57.4488 47.6423 57.3778 48.309C57.3069 48.9757 57.2714 49.5715 57.2714 50.0964C57.2714 50.6213 57.3069 51.2171 57.3778 51.8838C57.4488 52.5505 57.5764 53.1747 57.7608 53.7563C57.9594 54.3379 58.2361 54.8344 58.5907 55.2457C58.9453 55.6429 59.4135 55.8415 59.9951 55.8415C60.5625 55.8415 61.0235 55.6429 61.3781 55.2457C61.7328 54.8344 62.0023 54.3379 62.1867 53.7563C62.3853 53.1747 62.513 52.5505 62.5697 51.8838C62.6407 51.2171 62.6761 50.6213 62.6761 50.0964Z"
      fill="black"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4664_893"
        x1="94.5"
        y1="0.167961"
        x2="94.5"
        y2="188.168"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8F5F2" stop-opacity="0" />
        <stop offset="1" stop-color="#F8F5F2" />
      </linearGradient>
    </defs>
  </svg>
);

export default NoInvoiceIcon;
