import { useCallback, useEffect, useMemo, useState } from 'react';

import { PRODUCTION_PUBLIC_URL, STAGING_PUBLIC_URL } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useLocale } from '@common/hooks';
import useEnvironment, { Environment } from '@common/hooks/useEnvironment';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';

import ErrorBoundaryPage from '../error-boundary-page/ErrorBoundaryPage';

export enum VerificationStatus {
  NEW = 'new',
  SUMBITTED = 'submitted',
  COMPLETED = 'completed',
  ERRORED = 'errored',
}

const IdentityVerificationPage = () => {
  const [verificationStatus, setVerificationStatus] = useState(VerificationStatus.NEW);
  const { localeWithCountry } = useLocale();
  const { environment } = useEnvironment();

  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();

  const baseUrl = useMemo(
    () => (environment === Environment.PRODUCTION ? PRODUCTION_PUBLIC_URL : STAGING_PUBLIC_URL),
    [environment]
  );

  const renderView = useCallback(() => {
    switch (verificationStatus) {
      case VerificationStatus.ERRORED:
        return <ErrorBoundaryPage hasSignupLayout={false} />;

      default:
        return <MainLoader />;
    }
  }, [verificationStatus]);

  const submitVerification = useCallback(async () => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        shouldRefreshToken: true,
        data: {
          provider: 'veriff',
          redirectUrl: `${baseUrl}/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/identity-verification`,
        },
      })
    )
      .unwrap()
      .then((r) => {
        setVerificationStatus(VerificationStatus.SUMBITTED);
        window.location.href = r.responseData.url;
      })
      .catch(() => {
        setVerificationStatus(VerificationStatus.ERRORED);
      });
  }, [dispatch, instanceId, baseUrl, localeWithCountry]);

  const checkVerification = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.CHECK,
      })
    )
      .unwrap()
      .then((r) => {
        if (r?.responseData?.url) {
          window.location.href = r.responseData.url;
        }
      })
      .catch(() => {
        setVerificationStatus(VerificationStatus.ERRORED);
      });
  }, [dispatch, instanceId]);

  const handleVerificationStart = useCallback(async () => {
    await dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }))
      .unwrap()
      .then(async (r) => {
        if (!r.responseData.isIdentityVerificationCompleted) {
          await submitVerification();
        } else {
          setVerificationStatus(VerificationStatus.COMPLETED);
        }
      })
      .catch(() => {
        setVerificationStatus(VerificationStatus.ERRORED);
      });
  }, [dispatch, instanceId, submitVerification, setVerificationStatus]);

  useEffect(() => {
    handleVerificationStart();
  }, []);

  useEffect(() => {
    if (verificationStatus !== VerificationStatus.COMPLETED) {
      return;
    }
    const intervalId = setInterval(checkVerification, 5000);
    return () => clearInterval(intervalId);
  }, [checkVerification, verificationStatus]);

  return (
    <MainLayout
      hideHeaderAndFooter={verificationStatus !== VerificationStatus.ERRORED}
      hideProgressBar
    >
      {renderView()}
    </MainLayout>
  );
};

export default IdentityVerificationPage;
