import { useCallback, useEffect } from 'react';

import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setCurrentSubstepId } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { BankSubmitResponse, FlowActions, SubStep } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';
import ConsentForm from '@monefit-ee/components/consent-form/ConsentForm';

import AccountScoringView from './AccountScoringView';
import DecisionLoadingView from './DecisionLoadingView';
import { getBankSubStepFromStorage, saveBankSubStepToStorage } from './bankPageHelpers';

const BankPage = () => {
  useTracker({
    initEventName: TrackEvent.CONSENT_PAGE_INIT,
    errorTrackEventName: TrackEvent.CONSENT_PAGE_ERROR,
  });
  const { data, meta, loading } = useSelector((st) => st.application.flowInstance);
  const typedData = data?.responseData as BankSubmitResponse;
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();

  const renderView = useCallback(() => {
    const substepId = meta.currentSubStepId || getBankSubStepFromStorage(instanceId) || null;
    switch (substepId) {
      case SubStep.BANK_START:
        return <ConsentForm />;
      case SubStep.BANK_IN_PROGRESS:
        return typedData?.invitationId && typedData?.clientId ? (
          <AccountScoringView invitationId={typedData.invitationId} clientId={typedData.clientId} />
        ) : (
          <ConsentForm />
        );
      case SubStep.BANK_PENDING:
        return <DecisionLoadingView />;

      default:
        return <ConsentForm />;
    }
  }, [typedData?.clientId, typedData?.invitationId, meta.currentSubStepId, instanceId]);

  useEffect(() => {
    if (!data?.isResumed) {
      return;
    }
    // Make extra FETCH action request to check if user's bank statement was sent.
    dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }))
      .unwrap()
      .then(({ responseData }) => {
        if (!responseData.isBankStatementSent) {
          return;
        }

        dispatch(performApplicationFlowAction({ action: FlowActions.CHECK, instanceId }))
          .unwrap()
          .then(({ responseData }) => {
            if (
              !(
                Array.isArray(responseData) &&
                responseData.indexOf('Bank statement is pending.') !== -1
              )
            ) {
              return;
            }

            // If user's bank statement is Pending manually switch subStepId and start polling
            dispatch(setCurrentSubstepId(SubStep.BANK_PENDING));
            saveBankSubStepToStorage(instanceId, SubStep.BANK_PENDING);
          });
      });
  }, []);

  return <SignupLayout>{renderView()}</SignupLayout>;
};

export default BankPage;
