import { FormattedMessage } from 'react-intl';

import { IconButtonProps } from '@rjsf/utils';

interface AddButtonProps extends IconButtonProps {
  buttonText?: string;
}

const AddButtonTemplate = ({ buttonText, ...props }: AddButtonProps) => {
  return (
    <div className="flex w-full items-center justify-center">
      <button
        {...props}
        onMouseDown={(e) => {
          e.preventDefault();
        }}
        type="button"
        className="mb-4 mt-2 rounded-full bg-[#EBF6F9] px-4 py-2 text-black"
      >
        {buttonText ?? <FormattedMessage defaultMessage="Add more" />}
      </button>
    </div>
  );
};

export default AddButtonTemplate;
