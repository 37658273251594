import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import CheckIconOutlined from '@common/components/icons/CheckIconOutlined';
import { PlanFetch } from '@common/services/application';

import m from './PlanSelectCard.messages';

interface ProductSelectItemProps {
  type: 'credit' | 'subscription';
  texts: string[];
  onApplyClick: () => Promise<void>;
  planFetchData?: PlanFetch;
}

const Divider = () => {
  return (
    <div
      className="flex h-px w-full"
      style={{
        background:
          'linear-gradient(to right, transparent, rgba(0, 0, 0, 0.28) 51.5%, transparent)',
        opacity: '0.28',
      }}
    ></div>
  );
};

const PlanSelectItem = ({ type, texts, onApplyClick, planFetchData }: ProductSelectItemProps) => {
  const isCredit = type === 'credit';
  const { formatMessage, formatNumber } = useIntl();

  const limit: number | undefined = useMemo(
    () => (isCredit ? planFetchData?.freeProductLimit : planFetchData?.subscriptionProductLimit),
    [isCredit, planFetchData]
  );

  const percentage: string | null = useMemo(
    () =>
      planFetchData?.interestPercentage
        ? (planFetchData?.interestPercentage * 100).toFixed(2)
        : null,
    [planFetchData?.interestPercentage]
  );

  const subscriptionFee = useMemo(() => {
    const subscription = planFetchData?.data?.[0];
    return subscription?.fee ?? null;
  }, [planFetchData?.data]);

  return (
    <div className="relative flex h-fit w-full flex-col justify-start gap-5 overflow-hidden rounded-2xl border border-black/50 p-6 sm:p-12 lg:w-1/2">
      {!isCredit && (
        <div className="absolute right-[-110px] top-[-45px] flex h-[35px] w-72 origin-top-left rotate-[37.13deg] items-center justify-center bg-[#a5d1db] text-center text-xl font-bold tracking-tight text-black">
          {formatMessage(m.bestValue)}
        </div>
      )}
      <div className="inline-flex flex-col items-start justify-center gap-4 py-2.5">
        <div className="text-center text-3xl font-semibold leading-10 text-black sm:text-5xl">
          {formatMessage(isCredit ? m.standard : m.subscription)}
        </div>
        {!!limit && (
          <div className="text-2xl font-semibold leading-relaxed text-black">
            {formatMessage(m.withdrawText, {
              amount: formatNumber(limit, {
                maximumFractionDigits: 0,
                minimumFractionDigits: 0,
              }),
            })}
          </div>
        )}

        {!!percentage && (
          <div className="flex flex-col items-center justify-center gap-2.5 rounded-2xl bg-white p-2">
            <div className="text-center">
              <span className="text-lg font-semibold leading-tight tracking-wide text-black">
                {formatMessage(m.percentageText, { percentage })}
              </span>
            </div>
          </div>
        )}
      </div>
      <Divider />
      <div className=" text-3xl font-semibold text-black ">{formatMessage(m.included)}</div>
      <div>
        {texts.map((text) => (
          <div key={`${type}_${text}`} className="flex min-h-16 items-center gap-3">
            <div>
              <CheckIconOutlined />
            </div>
            <div className="text-lg"> {text}</div>
          </div>
        ))}
      </div>
      <Button
        onClick={onApplyClick}
        text={formatMessage(isCredit ? m.buttonTextStandard : m.buttonTextSubscription)}
        color={isCredit ? 'gray-alt' : 'black'}
      />
      {!isCredit && (
        <div className="flex w-full flex-col justify-center gap-1">
          {!!subscriptionFee && (
            <div className="text-center">
              {formatMessage(m.subscriptionFeeText, { fee: subscriptionFee })}
            </div>
          )}
          <div className="text-center">
            {/* TODO: LINK */}
            <a
              className="text-black underline"
              rel="noreferrer"
              target="_blank"
              href="https://monefit.com"
            >
              {formatMessage(m.termsText)}
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlanSelectItem;
