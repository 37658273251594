import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Layout from '@common/components/layout/Layout';
import { useLocale, useSelector } from '@common/hooks';
import { selectBalance, selectUserLimit } from '@common/redux/selectors/banking';
import PaymentCheckout from '@monefit-es/components/payment-checkout/PaymentCheckout';
import PaymentStartForm from '@monefit-es/components/payment-start-form/PaymentStartForm';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';

import m from './PaymentPage.messages';
import { PaymentStep } from './PaymentPage.types';

const PaymentPage = () => {
  const limitData = useSelector(selectUserLimit);
  const balanceData = useSelector(selectBalance);
  useLimitAndBalance({ disableLimitFetch: !!limitData, disableBalanceFetch: !!balanceData });
  const [paymentStep, setPaymentStep] = useState(PaymentStep.START);
  const [amount, setAmount] = useState<number | null>(null);
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();

  const backButtonText = useMemo(() => {
    switch (paymentStep) {
      case PaymentStep.START:
        return formatMessage(m.backButtonTextStart);
      case PaymentStep.CONRIRMATION:
        return formatMessage(m.backButtonTextConfirmation);
      case PaymentStep.CHECKOUT:
        return formatMessage(m.backButtonTextCheckout);
    }
  }, [paymentStep, formatMessage]);

  const handleBackClick = useCallback(() => {
    switch (paymentStep) {
      case PaymentStep.START:
        navigate(`/${localeWithCountry}/user`);
        break;
      case PaymentStep.CONRIRMATION:
        setPaymentStep(PaymentStep.START);
        break;
      case PaymentStep.CHECKOUT:
        setPaymentStep(PaymentStep.CONRIRMATION);
        break;
    }
  }, [navigate, paymentStep, localeWithCountry]);

  const componentToRender = useMemo(() => {
    switch (paymentStep) {
      case PaymentStep.START:
      case PaymentStep.CONRIRMATION:
        return (
          <PaymentStartForm
            step={paymentStep}
            setStep={setPaymentStep}
            amount={amount ?? 0}
            setAmount={setAmount}
          />
        );
      case PaymentStep.CHECKOUT:
        return <PaymentCheckout amount={amount ?? 0} />;
    }
  }, [amount, paymentStep]);

  return (
    <>
      <Layout.WithNavigation
        maxWDesktop={600}
        hideProgressBar
        backButtonText={backButtonText}
        onBackButtonClick={handleBackClick}
      >
        {componentToRender}
      </Layout.WithNavigation>
    </>
  );
};

export default PaymentPage;
