import { ReactNode, useState } from 'react';

interface WaitingListFooterButtonProps {
  defatultIcon: ReactNode;
  coloredIcon: ReactNode;
  link: string;
}

const WaitingListFooterButton = ({
  defatultIcon,
  coloredIcon,
  link,
}: WaitingListFooterButtonProps) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered ? coloredIcon : defatultIcon}
    </a>
  );
};

export default WaitingListFooterButton;
