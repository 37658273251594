import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { AppId } from '@common/hooks';
import dots from '@src/assets/animations/Dots-animation.json';
import CreditstarCirclingPulsing from '@src/assets/animations/creditstar/loader-circling-pulsing.json';
import CreditstarCirclingTick from '@src/assets/animations/creditstar/loader-circling-tick.json';
import CreditstarCircling from '@src/assets/animations/creditstar/loader-circling.json';
import Lottie from 'lottie-react';

export interface LoadingIndicatorProps {
  appId?: AppId;
  height?: number;
  loop?: boolean;
  type?: 'dots' | 'circling' | 'circling-pulsing' | 'circling-tick'; // Currently available only if appId is Creditstar
}

const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
  appId = AppId.MONEFIT,
  height = 40,
  loop = true,
  type = 'dots',
}) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const location = useLocation();

  const animationData = useMemo(() => {
    if (appId === AppId.CREDITSTAR) {
      switch (type) {
        case 'circling-pulsing':
          return CreditstarCirclingPulsing;
        case 'circling-tick':
          return CreditstarCirclingTick;
        case 'circling':
        default:
          return CreditstarCircling;
      }
    }

    return dots;
  }, [appId, type]);

  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 100);
  }, []);

  // Lottie animation sometimes crashes when google oauth widget is shown, workaround to prevent crashing
  if (
    !isLoaded &&
    (location.pathname.includes('/login') || location.pathname.includes('/sign-up'))
  ) {
    return <></>;
  }

  return (
    <div>
      <Lottie loop={loop} animationData={animationData} style={{ height }} />
    </div>
  );
};
export default LoadingIndicator;
