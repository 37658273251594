import { FC, useEffect, useState } from 'react';

import useSubApp from './useSubApp';

const PlaceholderComponent: FC<any> = () => <div></div>;

type UseComponent = <P>(path: string) => [FC<P>];

/**
 * Hook to get a component node from the current subapp or fallback to common
 *
 * @param path - example 'header/Header' to require 'src/monefit-es/components/header/Header' with fallback to 'src/common/components/header/Header'
 */
export const useComponent: UseComponent = (path: string) => {
  const { subAppId } = useSubApp();
  const [component, setComponent] = useState(() => PlaceholderComponent);

  useEffect(() => {
    const loadComponent = async () => {
      let subAppComponent: FC | null = null;

      try {
        const loadedSubAppComponent = await import(`@${subAppId}/components/${path}`);
        subAppComponent = () => loadedSubAppComponent.default;
      } catch (e) {}

      let commonComponent: FC | null = null;

      if (!subAppComponent) {
        try {
          const loadedCommonComponent = await import(`@common/components/${path}`);
          commonComponent = () => loadedCommonComponent.default;
        } catch (e) {}
      }

      const loadedComponent = subAppComponent ?? commonComponent ?? (() => PlaceholderComponent);
      setComponent(loadedComponent);
    };

    loadComponent();
  }, [subAppId, path]);

  return [component];
};
