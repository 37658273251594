import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { LocalStorageKeys } from '@common/constants';
import { useLocale } from '@common/hooks';
import { useSelector } from '@common/hooks/useSelector';
import { setLoading } from '@common/redux/slices/authentication';
import { AuthorizeAction, AuthorizeResponse, authService } from '@common/services/authentication';
import LoginForm from '@monefit-es/components/forms/LoginForm';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import AnimatedWelcomeLoaderStart from '@monefit-es/components/loaders/AnimatedWelcomeLoaderStart';
import MainLoader from '@monefit-es/components/loaders/MainLoader';
import useAuthenticationServiceErrorHandler from '@monefit-es/hooks/useAuthenticationServiceErrorHandler';

const LoginPage = () => {
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const identifier = searchParams.get('identifier');
  const challenge = searchParams.get('challenge');
  const action = searchParams.get('action');

  const navigate = useNavigate();
  const { localeWithCountry } = useLocale();

  const { loading } = useSelector((st) => st.authentication);

  const memoizedCode = useMemo(() => code, [code]);
  const memoizedState = useMemo(() => state, [state]);
  const memoizedIdentifier = useMemo(() => identifier, [identifier]);
  const memoizedChallenge = useMemo(() => challenge, [challenge]);
  const memoizedAction = useMemo(() => action, [action]);
  const [welcomeAnimationComplete, setWelcomeAnimationComplete] = useState(false);

  const shouldShowLoader = useMemo(
    () => (!!memoizedCode && !!memoizedState) || (!!memoizedIdentifier && !!memoizedChallenge),
    [memoizedCode, memoizedState, memoizedChallenge, memoizedIdentifier]
  );

  const shouldShowAnimation = useMemo(
    () => !welcomeAnimationComplete && shouldShowLoader,
    [welcomeAnimationComplete, shouldShowLoader]
  );

  const { handleError } = useAuthenticationServiceErrorHandler();

  const handleLoginError = useCallback(
    (e: any) => {
      handleError(e);

      let redirectPath = 'login';

      if (memoizedAction === AuthorizeAction.REGISTER) {
        redirectPath = 'sign-up';
      }

      navigate(`/${localeWithCountry}/${redirectPath}`, { replace: true });
    },
    [navigate, localeWithCountry, memoizedAction, handleError]
  );

  const handleOauthLogin = useCallback(
    async (code: string | null, state: string | null) => {
      if (!code || !state) {
        return;
      }
      if (!welcomeAnimationComplete) {
        return;
      }
      if (loading) {
        return;
      }
      try {
        setLoading(true);
        const res = await authService.post<AuthorizeResponse>('/oauth2/authorize', {
          code: encodeURIComponent(code),
          state: encodeURIComponent(state),
        });

        sessionStorage.setItem(LocalStorageKeys.USER, JSON.stringify(res.data));
        navigate(`/${localeWithCountry}/user`, { replace: true });
      } catch (e) {
        handleLoginError(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, localeWithCountry, handleLoginError, navigate, welcomeAnimationComplete]
  );

  const handleLinkBasedLogin = useCallback(
    async (identifier: string | null, challenge: string | null) => {
      if (!identifier || !challenge) {
        return;
      }
      if (!welcomeAnimationComplete) {
        return;
      }
      if (loading) {
        return;
      }

      try {
        setLoading(true);
        const res = await authService.post<AuthorizeResponse>('/auth/verify', {
          identifier,
          challenge,
        });

        sessionStorage.setItem(LocalStorageKeys.USER, JSON.stringify(res.data));
        navigate(`/${localeWithCountry}/user`, { replace: true });
      } catch (e) {
        handleLoginError(e);
      } finally {
        setLoading(false);
      }
    },
    [loading, localeWithCountry, navigate, welcomeAnimationComplete, handleLoginError]
  );

  useEffect(() => {
    handleOauthLogin(memoizedCode, memoizedState);
  }, [handleOauthLogin, memoizedCode, memoizedState]);

  useEffect(() => {
    handleLinkBasedLogin(memoizedIdentifier, memoizedChallenge);
  }, [handleLinkBasedLogin, memoizedIdentifier, memoizedChallenge]);

  const handleAnimationEnd = useCallback(() => {
    setWelcomeAnimationComplete(true);
  }, []);

  return (
    <>
      {shouldShowAnimation ? (
        <AnimatedWelcomeLoaderStart onAnimationEnd={handleAnimationEnd} />
      ) : (
        <>
          {shouldShowLoader ? (
            <MainLayout hideProgressBar hideHeaderAndFooter={loading || shouldShowLoader}>
              <MainLoader />
            </MainLayout>
          ) : (
            <LoginForm />
          )}
        </>
      )}
    </>
  );
};

export default LoginPage;
