import { useMemo } from 'react';
import { useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import ImageCard from '@common/components/image-card/ImageCard';
import { useSelector } from '@common/hooks';
import useIntercomChat from '@common/hooks/useIntercomChat';
import { selectFlowInstance } from '@common/redux/selectors';
import { FlowSteps } from '@common/services/application';
import declinedImg from '@src/assets/img/monefit-es/declined.webp';

import m from './DeclinedCard.messages';

const DeclinedCard = () => {
  const { formatMessage } = useIntl();
  const flowInstanceData = useSelector(selectFlowInstance);
  const isDeclinedVerification = useMemo(
    () =>
      flowInstanceData.data?.responseData?.preDeclinedStepId === FlowSteps.IDENTIFY_VERIFICATION,
    [flowInstanceData.data?.responseData?.preDeclinedStepId]
  );

  const { openChat } = useIntercomChat();
  return (
    <ImageCard
      imageSrc={declinedImg}
      smHeightClassName={isDeclinedVerification ? 'sm:h-[600px]' : 'sm:h-[720px]'}
      lgHeightClassName={isDeclinedVerification ? 'lg:h-[550px]' : 'lg:h-[600px]'}
      maxWClassName="max-w-[1100px]"
    >
      <div className="flex size-full flex-col justify-normal gap-5 sm:justify-between">
        <div className="flex flex-col items-start justify-start gap-2">
          <div className="font-heavy text-4xl text-black">{formatMessage(m.headerMainText)}</div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2.5">
          <div className="text-base leading-normal text-black">
            {formatMessage(isDeclinedVerification ? m.failedVerificationText : m.headerDescription)}
          </div>
          {!isDeclinedVerification && (
            <ul className="list-inside list-disc text-base sm:list-outside">
              <li>{formatMessage(m.declineReason1)}</li>
              <li>{formatMessage(m.declineReason2)}</li>
              <li>{formatMessage(m.declineReason3)}</li>
            </ul>
          )}
        </div>
        <div className=" text-base font-medium text-black">{formatMessage(m.getInTouchText)}</div>
        <Button
          extraClassNames="mt-5 sm:mt-0"
          type="button"
          onClick={() => openChat()}
          fullWidth
          text={formatMessage(m.chatWithUs)}
        />
      </div>
    </ImageCard>
  );
};

export default DeclinedCard;
