import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CurrencyInputField, {
  CurrencyInputProps as BaseCurrencyInputProps,
} from 'react-currency-input-field';
import { useController, useFormContext } from 'react-hook-form';

import InfoIconCircle from '@common/components/icons/InfoIconCircle';
import InputLabel from '@common/components/input-label/InputLabel';
import classNames from 'classnames';

export interface CurrencyInputProps extends BaseCurrencyInputProps {
  name: string;
  label?: string;
  description?: string;
  placeholder?: string;
  value?: string;
  limit?: number;
  hideError?: boolean;
  externalHandler?: () => void;
  customClassName?: string;
  customSuffix?: {
    // Suffix styles
    suffixClassName: string;
    // Filler span classNames to match input's font-size ..etc
    fillerClassName: string;
    // Suffix wrapper element positoning classes (e.g. 'top-[50%], left-[50%]')
    wrapperClassName: string;
    // suffix (e.g EUR, USD ..etc)
    suffix: string;
  };
}

const CurrencyInput: FC<CurrencyInputProps> = ({
  name,
  label,
  description,
  limit,
  hideError = false,
  externalHandler,
  customClassName,
  customSuffix,
  suffix = ' €',
  ...props
}) => {
  const { formState, trigger } = useFormContext();
  const { isSubmitting, errors } = formState;

  const {
    field: { onChange, value, ...registration },
    fieldState: { isTouched, error },
  } = useController({
    name,
    rules: {},
  });

  const [isFocused, setIsFocused] = useState(false);
  const fillerSpanRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (isTouched) {
      trigger(name);
    }
  }, [isTouched, name, trigger]);

  const hasValue = props.value !== undefined || value !== undefined;

  useEffect(() => {
    externalHandler && externalHandler();
  }, [externalHandler]);

  const className = useMemo(() => {
    return (
      customClassName ??
      classNames(
        label ? 'pt-3' : '',
        'h-16 w-full rounded-xl border border-gray-300 px-7 font-semibold placeholder:font-normal placeholder:text-gray-400 focus:outline-none'
      )
    );
  }, [customClassName, label]);

  // Custom input's styled suffix solution -> https://stackoverflow.com/questions/49796934/add-a-text-suffix-to-input-type-number
  const fillSpanBuffer = useCallback(() => {
    if (!customSuffix) {
      return;
    }
    if (!value && fillerSpanRef?.current) {
      fillerSpanRef.current.textContent = '';
      return;
    }
    const extraFiller = value ? '  ' : '';

    if (fillerSpanRef?.current) {
      fillerSpanRef.current.textContent = value + extraFiller;
    }
  }, [fillerSpanRef, value, customSuffix]);

  useEffect(() => {
    fillSpanBuffer();
  }, [fillSpanBuffer]);

  const handleChange = useCallback(
    (v?: string | number) => {
      onChange(v ? Number(v) : null);
    },
    [onChange]
  );

  return (
    <div className="relative overflow-hidden">
      <CurrencyInputField
        allowNegativeValue={false}
        allowDecimals={false}
        decimalsLimit={0}
        autoComplete="off"
        disableGroupSeparators
        className={className}
        suffix={suffix}
        disabled={isSubmitting}
        {...registration}
        {...props}
        max={limit}
        value={value}
        onValueChange={(e) => {
          if (limit && Number(e) > limit) {
            handleChange(limit);
          } else {
            handleChange(e);
          }
        }}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          trigger(name);
        }}
        placeholder={label ? '' : (props.placeholder ?? '')}
      />
      {!!customSuffix && !!value && (
        <div
          className={classNames(
            'absolute w-full pointer-events-none',
            customSuffix?.wrapperClassName
          )}
        >
          <span
            ref={fillerSpanRef}
            style={{
              color: 'rgba(0, 0, 0, 0.0)',
              userSelect: 'none',
              pointerEvents: 'none',
              display: 'inline-block',
            }}
            className={classNames(customSuffix.fillerClassName, 'whitespace-pre')}
          ></span>
          <span className={customSuffix.suffixClassName}>{customSuffix.suffix}</span>
        </div>
      )}
      {!!label && <InputLabel name={name} text={label} shouldMinimize={isFocused || hasValue} />}
      {!!description && (
        <div className="mt-2 flex gap-2 text-[14px] font-normal text-black">
          <div>
            <InfoIconCircle />
          </div>
          {description}
        </div>
      )}
      {!!(error?.message || errors?.[name]?.message) && !hideError && (
        <div className="mt-2 text-xs font-semibold text-rose-400">
          {error?.message ?? errors?.[name]?.message?.toString()}
        </div>
      )}
    </div>
  );
};

export default CurrencyInput;
