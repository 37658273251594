export const getClassNames = () => ({
  card: 'flex w-full min-h-[300px] flex-col items-start justify-between gap-16 rounded-3xl px-6 py-7 sm:min-h-[350px] sm:p-12',
  button: 'flex items-center justify-center rounded-full border',
  buttonDisabledBg: 'bg-white opacity-50',
  balanceButtonsHover: 'hover:border hover:border-white hover:bg-black hover:text-white',
  invoiceButtonsHover: 'hover:border hover:bg-black hover:text-white bg-white',
  disabledInvoiceButtonText: 'text-black text-opacity-50',
  balanceDisplayContainer:
    'font-heavy flex items-baseline text-3xl font-black leading-10 text-white sm:text-7xl',
});

export type DashboardCalsses = ReturnType<typeof getClassNames>;
