import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headerSubText: {
    defaultMessage: "It's us, not you",
    description: "@monefit-es It's us, not you",
  },
  headerMainText: {
    defaultMessage: ' We’re unable to approve your loan (right now)',
    description: '@monefit-es  We’re unable to approve your loan (right now)',
  },
  headerDescription: {
    defaultMessage:
      'Unfortunately, we can’t proceed with your application at this time. Here are a few common reasons why applications may be declined:',
    description:
      '@monefit-es Unfortunately, we can’t proceed with your application at this time. Here are a few common reasons why applications may be declined:',
  },
  declineReason1: {
    defaultMessage: 'Credit history showing missed or late payments',
    description: '@monefit-es  Credit history showing missed or late payments',
  },
  declineReason2: {
    defaultMessage: 'Existing debts that affect your eligibility',
    description: '@monefit-es  Existing debts that affect your eligibility',
  },
  declineReason3: {
    defaultMessage: 'Financial details not meeting our lending criteria',
    description: '@monefit-es Financial details not meeting our lending criteria',
  },
  getInTouchText: {
    defaultMessage:
      'If you’d like more information or want to discuss other options, feel free to get in touch with us.',
    description:
      '@monefit-es If you’d like more information or want to discuss other options, feel free to get in touch with us.',
  },
  chatWithUs: {
    defaultMessage: 'Chat with us',
  },
  failedVerificationText: {
    defaultMessage:
      "Unfortunately, we can't proceed with your application until we have completed your Identity verification.",
    description:
      "@monefit-es Unfortunately, we can't proceed with your application until we have completed your Identity verification.",
  },
});
