import { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import { FooterProps } from '@common/components/footer/Footer';
import FooterLogo from '@common/components/icons/FooterLogo';
import { LINKS } from '@common/constants';
import { useLocale } from '@common/hooks';
import { getIsLoggedIn, handleLogout } from '@common/utils';

import WaitingListFooterLinks from '../waiting-list-footer-links/WaitingListFooterLinks';

const WaitingListFooter: FC<FooterProps> = () => {
  const isLoggedIn = getIsLoggedIn();
  const { localeWithCountry } = useLocale();
  const { formatMessage } = useIntl();

  return (
    <div>
      {isLoggedIn && (
        <div className="mt-12 flex flex-col items-center justify-center gap-12">
          <div className="">
            <div className="font-secondary text-center text-[32px] font-semibold text-black">
              <FormattedMessage
                defaultMessage="Got more questions?"
                description="Monefit-ES: Got more questions?"
              />
            </div>
            <div className="flex flex-col justify-center gap-3">
              <Button
                roundedFull={false}
                text={formatMessage({
                  defaultMessage: 'Get in touch',
                  description: 'Monefit-ES: Get in touch',
                })}
                extraClassNames="mt-3 px-14 font-bold font-secondary text-lg"
                onClick={() => {
                  window.location.href = LINKS.ES_MAILTO;
                }}
              />
              <Button
                roundedFull={false}
                text={formatMessage({
                  defaultMessage: 'Log out',
                  description: 'Monefit-ES: Log out',
                })}
                color="dark-gray"
                extraClassNames="flex lg:hidden font-bold font-secondary text-lg"
                onClick={() => handleLogout(localeWithCountry)}
              />
            </div>
          </div>
          <WaitingListFooterLinks />
        </div>
      )}
      <div className="mb-10 flex flex-col items-center p-2 opacity-50 sm:h-9 sm:flex-row sm:justify-center">
        <div className="flex flex-col items-center gap-2.5 sm:flex-row sm:gap-5">
          <FooterLogo />
          <span className="text-center font-normal text-black text-opacity-50 sm:text-base">
            <FormattedMessage defaultMessage="© Creditstar Group. All Rights Reserved." />
          </span>
        </div>
      </div>
    </div>
  );
};

export default WaitingListFooter;
