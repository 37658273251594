import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import { emailValidationRegex } from '@common/components/form/Form.helpers';
import GoogleIcon from '@common/components/icons/GoogleIcon';
import SignupBaseCard from '@common/components/sign-up-layout/SignupBaseCard';
import TextInput from '@common/components/text-input/TextInput';
import { Country, useLocale } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import { RootDispatch } from '@common/redux';
import { linkBasedLogin, oAuthGetAuthorizationUrl } from '@common/redux/thunks/authentication';
import {
  AuthorizeAction,
  LinkBasedLoginResponse,
  OAuthProvider,
  RegisterChannel,
} from '@common/services/authentication';
import { getPublicUrl } from '@common/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import useAuthenticationServiceErrorHandler from '@monefit-es/hooks/useAuthenticationServiceErrorHandler';
import * as yup from 'yup';

import MainLayout from '../layout/MainLayout';
import CheckEmailCard from '../loaders/CheckEmailCard';

interface FormValues {
  identifier: string;
}

const LoginForm = () => {
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const { data } = useSelector((st) => st.authentication);
  const dispatch = useDispatch<RootDispatch>();
  const { handleError } = useAuthenticationServiceErrorHandler();

  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      identifier: yup
        .string()
        .required(
          formatMessage({
            defaultMessage: 'Email is required',
            description: 'Email:  "Email is required"',
          })
        )
        .matches(
          emailValidationRegex,
          formatMessage({
            defaultMessage: "Invalid email format. Include '@' and a valid domain, like '.com'",
            description: 'Email: "Invalid email format"',
          })
        ),
    });
  }, [formatMessage]);

  const ctx = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const {
    getValues,
    formState: { isSubmitting, isValid },
  } = ctx;

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      dispatch(
        linkBasedLogin({
          ...v,
          channel: RegisterChannel.EMAIL,
          region: Country.ES.toUpperCase(),
          locale: localeWithCountry,
        })
      )
        .unwrap()
        .catch((e) => {
          handleError(e);
        });
    },
    [dispatch, handleError, localeWithCountry]
  );

  const handleGoogleSignup = useCallback(() => {
    const { REACT_APP_ENVIRONMENT } = process.env;

    const basePublicUrl = getPublicUrl(REACT_APP_ENVIRONMENT);
    const redirectUrl = `${basePublicUrl}/${localeWithCountry}/login?action=${AuthorizeAction.LOGIN}`;
    dispatch(
      oAuthGetAuthorizationUrl({
        provider: OAuthProvider.GOOGLE,
        redirectUrl: redirectUrl,
        region: Country.ES.toUpperCase(),
      })
    )
      .unwrap()
      .then((res) => {
        window.location.href = res.url;
      })
      .catch((e) => {
        handleError(e);
      });
  }, [dispatch, localeWithCountry, handleError]);

  const hanleLinkBasedResend = useCallback(async () => {
    if (!(data as LinkBasedLoginResponse)?.identifier) {
      return;
    }
    await dispatch(
      linkBasedLogin({
        identifier: (data as LinkBasedLoginResponse)?.identifier,
        channel: RegisterChannel.EMAIL,
        region: Country.ES.toUpperCase(),
        locale: localeWithCountry,
      })
    ).unwrap();
  }, [data, dispatch, localeWithCountry]);

  return (
    <>
      {(data as LinkBasedLoginResponse)?.outcome === 'success' ? (
        <MainLayout hideProgressBar showLoader={false}>
          <CheckEmailCard
            email={(data as LinkBasedLoginResponse).identifier}
            handleResend={hanleLinkBasedResend}
          />
        </MainLayout>
      ) : (
        <MainLayout hideProgressBar>
          <SignupBaseCard
            headerText={formatMessage({
              defaultMessage: 'Sign in',
              description: 'Monefit-ES: Sign in',
            })}
            subHeaderText={formatMessage({
              defaultMessage: 'Sign in with your email',
              description: 'Monefit-ES: Sign in with your email',
            })}
          >
            <form
              autoComplete="off"
              className="flex size-full"
              onSubmit={ctx.handleSubmit(async (v: FormValues) => {
                try {
                  await handleFormSubmit(v);
                } catch (_) {}
              })}
            >
              <FormProvider {...ctx}>
                <div className="flex size-full flex-col gap-8 sm:gap-12">
                  <TextInput
                    name="identifier"
                    required
                    label={formatMessage({
                      defaultMessage: 'E-mail',
                      description: 'Monefit-ES: E-mail',
                    })}
                  />
                  <div className="flex flex-col gap-2 sm:flex-row sm:gap-2">
                    <Button
                      type="button"
                      onClick={() => handleFormSubmit(getValues())}
                      fullWidth
                      text={formatMessage({ defaultMessage: 'Continue' })}
                      disabled={isSubmitting || !isValid}
                    />
                    <Button
                      type="button"
                      disabled={isSubmitting}
                      color="gray"
                      fullWidth
                      spacing={ButtonSpacing.UNDEFINED}
                      extraClassNames="px-2 py-4 "
                      onClick={handleGoogleSignup}
                      icon={<GoogleIcon />}
                      text={formatMessage({
                        defaultMessage: 'Sign in with Google',
                        description: 'Monefit-ES: Sign in with Google',
                      })}
                    />
                  </div>
                </div>
              </FormProvider>
            </form>
          </SignupBaseCard>
        </MainLayout>
      )}
    </>
  );
};

export default LoginForm;
