import { PartialDesignTokens } from '@checkout.com/checkout-web-components';

export const APPEARANCE: PartialDesignTokens = {
  colorAction: 'black',
  colorSuccess: '#2ECC71',
  button: {
    fontFamily: 'Avenir Next',
    fontSize: '1rem',
  },
  footnote: {
    fontFamily: 'Avenir Next',
    fontSize: '1rem',
  },
  label: {
    fontFamily: 'Avenir Next Demibold, Avenir Next',
    fontSize: '1rem',
  },
  subheading: {
    fontFamily: 'Avenir Next Demibold, Avenir Next',
    fontSize: '1rem',
  },
  input: {
    fontFamily: 'Avenir Next',
    fontSize: '1rem',
  },
  borderRadius: ['10px', '28px'],
};
