import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headTitle: {
    defaultMessage: 'Final step',
    description: '@monefit-es Final step',
  },
  subtitle: {
    defaultMessage:
      'Review and sign the documents to finalise your agreement and access your funds',
    description:
      '@monefit-es Review and sign the documents to finalise your agreement and access your funds',
  },
  secci: {
    defaultMessage: 'SECCI',
    description: 'SECCI',
  },
  secciText: {
    defaultMessage: 'Key terms and costs of your credit',
    description: '@monefit-es Key terms and costs of your credit',
  },
  loadAgreement: {
    defaultMessage: 'Loan agreement',
    description: '@monefit-es Loan agreement',
  },
  loadAgreementText: {
    defaultMessage: 'Your binding contract',
    description: '@monefit-es Your binding contract',
  },
});
