import useInitialFlowActionPerform, {
  ActionStatus,
} from '@common/hooks/useInitialFlowActionPerform';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import PlanSelect from '@monefit-es/components/plan-select/PlanSelect';

const PlanPage = () => {
  const { renderView, status } = useInitialFlowActionPerform({ successComponent: <PlanSelect /> });

  return (
    <MainLayout hideHeaderAndFooter={status === ActionStatus.LOADING}>{renderView()}</MainLayout>
  );
};

export default PlanPage;
