import { FC } from 'react';

export interface InputLabelProps {
  shouldMinimize: boolean;
  name?: string; // TODO: Remove this prop, not used
  text: string;
}

const InputLabel: FC<InputLabelProps> = ({
  shouldMinimize,
  name,
  text,
}: {
  shouldMinimize: boolean;
  name?: string;
  text: string;
}) => {
  return (
    <span
      className={`floating-label absolute left-7 inline-block w-[calc(80%)] truncate transition-all duration-300  ${
        shouldMinimize ? 'top-3 text-xs text-gray-500' : 'top-5 h-full text-base text-gray-400'
      }`}
    >
      {text}
    </span>
  );
};

export default InputLabel;
