import { useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import DrawdownPendingIcon from '@common/components/icons/DrawdownPendingIcon';
import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import useIntercomChat from '@common/hooks/useIntercomChat';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';

const DrawdownPendingCard = () => {
  const { formatMessage } = useIntl();
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const { loading } = useSelector((st) => st.application.flowInstance);
  const { openChat } = useIntercomChat();
  const handleCheck = useCallback(async () => {
    if (loading) {
      return;
    }
    dispatch(setShowLoader(false));
    await dispatch(performApplicationFlowAction({ action: FlowActions.CHECK, instanceId }))
      .unwrap()
      .then((res) => {
        if (res.currentStepId !== FlowSteps.PROCESSING) {
          dispatch(setShowLoader(true));
        }
      })
      .catch((err) => {
        // TODO: should show toast and redirect to dashboard (?)
        console.error(err);
      });
  }, [dispatch, instanceId, loading]);

  useEffect(() => {
    const intervalId = setInterval(handleCheck, 3000);
    return () => clearInterval(intervalId);
  }, [handleCheck]);

  return (
    <div className="inline-flex w-full flex-col items-start justify-between rounded-3xl bg-white p-7 backdrop-blur-lg">
      <div className="mt-10 flex flex-col items-center justify-end gap-5 self-stretch">
        <DrawdownPendingIcon />
        <div className="self-stretch text-center text-base font-medium leading-none tracking-tight text-black">
          <FormattedMessage
            defaultMessage="We’re processing your withdrawal request."
            description="Monefit-ES: We’re processing your withdrawal request."
          />
        </div>
      </div>
      <div className="mt-24 w-full">
        <Button
          fullWidth
          onClick={() => openChat()}
          text={formatMessage({
            defaultMessage: 'Chat with us',
            description: 'Monefit-ES: Chat with us',
          })}
        />
      </div>
    </div>
  );
};

export default DrawdownPendingCard;
