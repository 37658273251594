import { useState } from 'react';
import { Navigate } from 'react-router-dom';

import { useLocale } from '@common/hooks';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import WelcomeTextLoader from '@monefit-es/components/loaders/WelcomeTextLoader';

const OnboardingFlowEndPage = () => {
  useInitialFlowActionPerform({});
  const [welcomeAnimationComplete, setWelcomeAnimationComplete] = useState(false);
  const { localeWithCountry } = useLocale();
  return welcomeAnimationComplete ? (
    <Navigate to={`/${localeWithCountry}/user`} replace />
  ) : (
    <WelcomeTextLoader onComplete={() => setWelcomeAnimationComplete(true)} />
  );
};

export default OnboardingFlowEndPage;
