const AlertIcon = () => {
  return (
    <svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.95987"
        y="2.35245"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        fill="#F3BAC8"
        fillOpacity="0.07"
      />
      <rect
        x="1.95987"
        y="2.35245"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        stroke="#F3BAC1"
        strokeWidth="3.67364"
        strokeLinecap="square"
      />
      <path
        d="M34.5 26.5456L34.5 35.4453"
        stroke="#F3BAC1"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M34.5 42.1219L34.5 43.2344"
        stroke="#F3BAC1"
        strokeWidth="2.4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
