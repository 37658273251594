import { userService } from '@common/services/user';
import { User } from '@common/services/user.types';
import { ApiError, getUserData } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const fetchUser = createAsyncThunk<User>('user/get', async (_, { rejectWithValue }) => {
  const { user } = getUserData();
  if (!user.id) {
    return rejectWithValue('User ID is missing');
  }
  try {
    const response = await userService.get<User>(
      `/user/${user.id}?expand=activeAddress,activePhone,tags`
    );
    return response.data;
  } catch (e) {
    throw new ApiError(String(e));
  }
});
