import { RootState } from '@common/redux';
import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '../slices/subscription';

export const makeSelectSubscription = (state: RootState) =>
  'subscription' in state ? state.subscription : initialState;

export const selectSubscriptionLoading = createSelector(makeSelectSubscription, (s) => s.loading);
export const selectSubscriptionError = createSelector(makeSelectSubscription, (s) => s.error);
export const selectUserSubscriptions = createSelector(
  makeSelectSubscription,
  (s) => s.userSubscriptions
);
