import { useMemo, useState } from 'react';
import Select, { MenuListProps, OptionProps, components } from 'react-select';

import InputLabel from '@common/components/input-label/InputLabel';
import { WidgetProps } from '@rjsf/utils';

import IconDown from '../icons/IconDown';
import IconUp from '../icons/IconUp';

interface ExtendedWidgetProps extends WidgetProps {
  enumMapper?: (value: any) => string;
}

const MenuList = ({ label, props }: { label: string; props: MenuListProps<any, false> }) => {
  return (
    <components.MenuList {...props}>
      <div className="flex justify-between text-sm opacity-50">
        <div className="text-sm font-medium text-black text-opacity-50">{label}</div>
        <IconUp />
      </div>
      <div className="mb-2 mt-3 h-px w-full bg-gray-200" />
      {props.children}
    </components.MenuList>
  );
};

const Option = (props: OptionProps<any>) => {
  return (
    <div className="text-left">
      <components.Option {...props} />
    </div>
  );
};

const SelectWidget = ({
  disabled,
  options,
  onChange,
  name,
  value,
  schema,
  enumMapper,
  ...rest
}: ExtendedWidgetProps) => {
  const [isFocused, setIsFocused] = useState(false);

  const selectOptions = useMemo(() => {
    return (options.enumOptions ?? []).map((enumItem) => ({
      label: enumMapper ? enumMapper(enumItem.value) : enumItem.value,
      value: enumItem.value,
    }));
  }, [enumMapper, options.enumOptions]);

  const selectedValue = useMemo(
    () => selectOptions.find((x) => x.value === value),
    [selectOptions, value]
  );

  return (
    <div className="relative">
      <Select
        components={{
          IndicatorSeparator: () => null,
          MenuList: (props) => MenuList({ label: rest.label ?? '', props }),
          DropdownIndicator: () => <IconDown />,
          Menu: (props) => <components.Menu {...props} />,
          IndicatorsContainer: (props) => (
            <components.IndicatorsContainer {...props} className="pr-2 sm:pr-8" />
          ),
          Option,
        }}
        {...rest}
        onFocus={() => setIsFocused(true)}
        onBlur={() => {
          setIsFocused(false);
          rest.onBlur(rest.id, value);
        }}
        isDisabled={disabled}
        menuPlacement="auto"
        value={selectedValue}
        defaultValue={selectedValue}
        options={selectOptions}
        placeholder=""
        onChange={(newValue: any) => {
          onChange(newValue.value);
        }}
        className="peer focus:border-blue-600"
        classNames={{
          control: () => 'h-16',
          menu: () => 'px-3 py-2 sm:px-7 sm:py-4',
          valueContainer: () => 'px-8 py-7 mt-0.5 font-semibold',
        }}
        styles={{
          control: (base, props): any => ({
            ...base,
            borderColor: '#d1d5db',
            boxShadow: 'none',
            borderRadius: '0.75rem', // rounded-xl
            '&:hover': {
              borderColor: '#d1d5db', // gray-300
            },
          }),
          option: (base, state): any => ({
            ...base,
            cursor: 'pointer',
            color: 'rgba(0, 0, 0, 0.48)',
            padding: '5px 1px',
            backgroundColor: 'transparent',
            fontWeight: state.isFocused ? 'bold' : 'normal',
            '&:active': {
              backgroundColor: 'white',
            },
            '&:hover': {
              backgroundColor: '#F3F4F6', // gray-100
              borderRadius: '0.125rem', // rounded-sm,
            },
          }),
          input: (base) => ({
            ...base,
          }),
          menu: (base): any => ({
            ...base,
            borderRadius: '0.75rem', // rounded-xl,
            border: '1px solid #d1d5db', // border-gray-300
            boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
            marginTop: '4px',
            marginBottom: '4px',
          }),
          valueContainer: (base): any => ({
            ...base,
            display: 'flex',
            paddingTop: '13px', // pt-3
            paddingLeft: '24px', // pl-6
            fontWeight: '600',
            flexWrap: 'no-wrap',
          }),
        }}
      />
      <div>
        <InputLabel text={rest.label} shouldMinimize={isFocused || value !== undefined} />
      </div>
    </div>
  );
};

export default SelectWidget;
