import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectFlowInstance } from '@common/redux/selectors';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';

import Button from './Button';

const BackButton = () => {
  const dispatch = useDispatch<RootDispatch>();
  const { loading, data } = useSelector(selectFlowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const { formatMessage } = useIntl();

  const handleBackClick = useCallback(() => {
    dispatch(performApplicationFlowAction({ action: FlowActions.BACK, instanceId }));
  }, [dispatch, instanceId]);

  return data?.allowedBackStepId ? (
    <Button
      fullWidth
      type="button"
      disabled={loading}
      color="transparent"
      hoverClassName="hover:bg-black hover:text-white"
      borderClassName="border-2 border-black"
      text={formatMessage({ defaultMessage: 'Go Back', description: 'Back button text' })}
      onClick={handleBackClick}
    />
  ) : null;
};

export default BackButton;
