import { useCallback, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { useApplicationFlowInstance, useDispatch, useLocale } from '@common/hooks';
import useFlowIdFromUrl from '@common/hooks/useFlowIdFromUrl';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';

import DrawdownEndPage from '../drawdown-end-page/DrawdownEndPage';
import OnboardingFlowEndPage from '../onboarding-flow-end-page/OnboardingFlowEndPage';

const EndPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();
  const { flowId } = useFlowIdFromUrl();
  const { localeWithCountry } = useLocale();

  const renderEndPage = useCallback(() => {
    const { REACT_APP_ES_APPLICATION_FLOW_ID, REACT_APP_ES_DRAWDOWN_FLOW_ID } = process.env;

    switch (flowId) {
      case REACT_APP_ES_APPLICATION_FLOW_ID:
        return <OnboardingFlowEndPage />;
      case REACT_APP_ES_DRAWDOWN_FLOW_ID:
        return <DrawdownEndPage />;
      default:
        return <Navigate replace to={`/${localeWithCountry}/user`} />;
    }
  }, [flowId, localeWithCountry]);

  const fetchEnd = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({ action: FlowActions.FETCH, instanceId })
    ).unwrap();
  }, [dispatch, instanceId]);

  useEffect(() => {
    fetchEnd();
  }, []);

  return renderEndPage();
};

export default EndPage;
