import { useIntl } from 'react-intl';

import { ArrayFieldTemplateItemType } from '@rjsf/utils';

import Button, { ButtonSpacing } from '../button/Button';

const ArrayFieldItemTemplate = (props: ArrayFieldTemplateItemType & { totalItems: number }) => {
  const { formatMessage } = useIntl();
  return (
    <div>
      <div>{props.children}</div>
      {props.hasRemove && props.totalItems > 1 && (
        <Button
          onClick={props.onDropIndexClick(props.index)}
          color="underlined-black"
          type="button"
          extraClassNames="text-red-300 no-underline"
          spacing={ButtonSpacing.NONE}
          text={formatMessage({ defaultMessage: 'Remove', description: 'Remove' })}
        />
      )}
    </div>
  );
};

export default ArrayFieldItemTemplate;
