import { FormatMessage } from '@common/types';
import * as yup from 'yup';

import m from './PhoneSubmitForm.messages';

export const getFormSchema = ({ formatMessage }: { formatMessage: FormatMessage }) => {
  return yup.object({
    phone: yup
      .string()
      .required(formatMessage(m.formRequiredValidationText))
      .test(
        'is-valid-length',
        formatMessage(m.formMinValidationText),
        (value) => value?.length === 12
      )
      .test('is-valid-format', formatMessage(m.formMatchValidationText), (value) =>
        /^\+34[67]\d{8}$/.test(value)
      ),
  });
};

export type PhoneSubmitFormSchema = ReturnType<typeof getFormSchema>;
