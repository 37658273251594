import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { LocalStorageKeys } from '@common/constants';
import { useSelector } from '@common/hooks/useSelector';
import { TrackEvent } from '@common/utils/amplitude/events';
import amplitude from 'amplitude-js';

import { useApplicationFlowInstance } from './useApplicationFlowInstance';
import { useCookieConsents } from './useCookieConsents';
import useError from './useError';

interface AmplitudeTrackData {
  loggedEvents: TrackEvent[];
  triggerAnalyticsEvents?: boolean;
}

const useTracker = ({
  initEventName,
  errorTrackEventName,
}: {
  initEventName?: TrackEvent;
  errorTrackEventName?: TrackEvent;
}) => {
  const { REACT_APP_AMPLITUDE_API_KEY, REACT_APP_ENVIRONMENT } = process.env;
  const [searchParams] = useSearchParams();
  const [instanceId] = useApplicationFlowInstance();
  const { errors } = useError();
  const { error, data } = useSelector((st) => st.application.flowInstance);
  const { loaded: consentsLoaded, consents } = useCookieConsents();

  if (consentsLoaded && consents.statistics && REACT_APP_AMPLITUDE_API_KEY) {
    amplitude.getInstance().init(REACT_APP_AMPLITUDE_API_KEY);
  }

  const storageKey = useMemo(() => `${LocalStorageKeys.AMPLITUDE}_${instanceId}`, [instanceId]);

  const parseDataFromStorage = useCallback(() => {
    if (!instanceId) {
      return null;
    }
    const rawData = sessionStorage.getItem(storageKey);
    const parsedData = rawData ? (JSON.parse(rawData) as AmplitudeTrackData) : null;
    return parsedData;
  }, [instanceId, storageKey]);

  const saveDataToStorage = useCallback(
    (data: AmplitudeTrackData) => {
      if (!instanceId) {
        return;
      }
      sessionStorage.setItem(storageKey, JSON.stringify(data));
    },
    [instanceId, storageKey]
  );

  const trackEvent = useCallback(
    (event: TrackEvent, properties?: Record<string, any>) => {
      if (!instanceId) {
        return;
      }

      if (!consentsLoaded || (consentsLoaded && !consents.statistics)) {
        return;
      }

      const parsedData = parseDataFromStorage();
      const loggedEvents: TrackEvent[] = parsedData?.loggedEvents ?? [];
      const hasLoggedThisSession = loggedEvents.indexOf(event) !== -1;

      if (
        hasLoggedThisSession ||
        !REACT_APP_AMPLITUDE_API_KEY ||
        parsedData?.triggerAnalyticsEvents === false ||
        REACT_APP_ENVIRONMENT !== 'production'
      ) {
        return;
      }

      const deviceIdParam = searchParams.get('deviceId');
      const deviceId = deviceIdParam ?? amplitude.getInstance().getDeviceId();

      amplitude.getInstance().logEvent(event, {
        ...properties,
        device_id: deviceId,
        session_id: data?.sessionId,
        instance_id: instanceId,
      });
      saveDataToStorage({ ...parsedData, loggedEvents: [...loggedEvents, event] });
    },

    [
      instanceId,
      consentsLoaded,
      consents.statistics,
      parseDataFromStorage,
      REACT_APP_AMPLITUDE_API_KEY,
      REACT_APP_ENVIRONMENT,
      searchParams,
      data?.sessionId,
      saveDataToStorage,
    ]
  );

  useEffect(() => {
    if (initEventName) {
      trackEvent(initEventName);
    }
  }, [
    initEventName,
    trackEvent,
    storageKey,
    data?.currentStepId,
    data?.isResumed,
    parseDataFromStorage,
  ]);

  useEffect(() => {
    const parsedAmplitudeData = parseDataFromStorage();
    const responseData = data?.responseData ?? {};
    // Save triggerAnalyticsEvents to session storage for further checks (if tracking needed or not)
    if ('triggerAnalyticsEvents' in responseData) {
      saveDataToStorage({
        ...parsedAmplitudeData,
        loggedEvents: parsedAmplitudeData?.loggedEvents ?? [],
        triggerAnalyticsEvents: responseData?.triggerAnalyticsEvents,
      });
    }
  }, [data?.responseData, parseDataFromStorage, saveDataToStorage]);

  useEffect(() => {
    if (errorTrackEventName && (error || errors.length)) {
      trackEvent(errorTrackEventName);
    }
  }, [error, errors, errorTrackEventName, trackEvent]);

  return {
    trackEvent,
  };
};

export default useTracker;
