import application from '@common/redux/slices/application';
import authentication from '@common/redux/slices/authentication';
import bankStatement from '@common/redux/slices/bank-statement';
import banking from '@common/redux/slices/banking';
import documents from '@common/redux/slices/documents';
import payment from '@common/redux/slices/payment';
import subscription from '@common/redux/slices/subscription';
import user from '@common/redux/slices/user';
import { combineReducers } from '@reduxjs/toolkit';

export const reducers = {
  application,
  authentication,
  bankStatement,
  banking,
  documents,
  subscription,
  payment,
  user,
};

export const rootReducer = combineReducers(reducers);
