import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { SubscriptionName } from '@common/enums';
import { RootDispatch } from '@common/redux';
import {
  selectSubscriptionError,
  selectSubscriptionLoading,
  selectUserSubscriptions,
} from '@common/redux/selectors/subscription';
import { getUserSubscriptions } from '@common/redux/thunks/subscription';

import { useDispatch } from './useDispatch';

const useSubsciptions = () => {
  const dispatch = useDispatch<RootDispatch>();
  const loading = useSelector(selectSubscriptionLoading);
  const error = useSelector(selectSubscriptionError);
  const userSubscriptions = useSelector(selectUserSubscriptions);

  const fetchUserSubscriptions = useCallback(async () => {
    await dispatch(getUserSubscriptions());
  }, [dispatch]);

  const getHasActiveSubsctiption = useCallback(
    (subscriptionName: SubscriptionName) => {
      return !!userSubscriptions?.find((s) => s.subscription.name === subscriptionName);
    },
    [userSubscriptions]
  );

  return {
    fetchUserSubscriptions,
    getHasActiveSubsctiption,
    loading,
    error,
    userSubscriptions,
  };
};

export default useSubsciptions;
