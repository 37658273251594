import { paymentService } from '@common/services/payment';
import { PaymentSessionCreateRequest, PaymentSessionCreateResponse } from '@common/types/payment';
import { ApiError } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

export const createPaymentSession = createAsyncThunk<
  PaymentSessionCreateResponse,
  PaymentSessionCreateRequest
>('/payment/session', async (data): Promise<PaymentSessionCreateResponse> => {
  try {
    const response = await paymentService.post<
      PaymentSessionCreateRequest,
      AxiosResponse<PaymentSessionCreateResponse>
    >('/payment/session', {
      ...data,
    });
    return response.data;
  } catch (e) {
    throw new ApiError();
  }
});
