import { useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import { useApplicationFlowInstance } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import useTracker from '@common/hooks/useTracker';
import { RootDispatch } from '@common/redux';
import { setShowLoader } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { DocumentsFetch, FlowActions } from '@common/services/application';
import { TrackEvent } from '@common/utils/amplitude/events';

import ApprovedLoanCard from './ApprovedLoanCard';
import DocumentCard from './DocumentCard';

const DocumentsPage = () => {
  const { trackEvent } = useTracker({
    initEventName: TrackEvent.DOCUMENTS_PAGE_INIT,
    errorTrackEventName: TrackEvent.DOCUMENTS_PAGE_ERROR,
  });
  const { data } = useSelector((state) => state.application.flowInstance);
  const typedResponseData = data?.responseData as DocumentsFetch;
  const { formatMessage } = useIntl();
  const dispatch = useDispatch<RootDispatch>();
  const [documentsConsent, setDocumentsConsent] = useState({
    agreementConsent: false,
    secciConsent: false,
  });

  const [canProceedToNextStep, setCanProceedToNextStep] = useState(false);

  const handleDocumentsConsent = useCallback(async () => {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setCanProceedToNextStep(true);
  }, []);

  const [instanceId] = useApplicationFlowInstance();

  useEffect(() => {
    const { agreementConsent, secciConsent } = documentsConsent;

    if (agreementConsent && secciConsent) {
      handleDocumentsConsent();
    }
  }, [handleDocumentsConsent, documentsConsent]);

  const handleContinueClick = useCallback(async () => {
    const { agreementConsent, secciConsent } = documentsConsent;
    if (!agreementConsent || !secciConsent) {
      return;
    }
    dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        instanceId,
        data: { ...{ ...documentsConsent } },
      })
    )
      .unwrap()
      .catch(() => trackEvent(TrackEvent.DOCUMENTS_PAGE_ERROR));
  }, [documentsConsent, dispatch, instanceId, trackEvent]);

  useEffect(() => {
    dispatch(setShowLoader(true));
    dispatch(performApplicationFlowAction({ action: FlowActions.FETCH, instanceId }));
  }, []);

  return (
    <SignupLayout>
      <div className="flex w-full max-w-[740px] flex-col gap-2">
        <ApprovedLoanCard
          handleContinueClick={handleContinueClick}
          canProceedToNextStep={canProceedToNextStep}
          approvedAmount={typedResponseData?.limit}
          firstPayment={typedResponseData?.firstRepaymentAmount}
          firstWithDraw={typedResponseData?.amount}
          paymentDate={new Date(typedResponseData?.firstRepaymentDate ?? new Date())}
        />
        {!canProceedToNextStep && (
          <div className="flex flex-col gap-2 sm:flex-row">
            <DocumentCard
              isDocumentAccepted={documentsConsent.secciConsent}
              onDocumentAccept={() =>
                setDocumentsConsent((prev) => ({ ...prev, secciConsent: true }))
              }
              title={formatMessage({ defaultMessage: 'SECCI', description: 'SECCI translation' })}
              documentContent={new TextDecoder().decode(
                Uint8Array.from(atob(typedResponseData?.secci ?? ''), (c) => c.charCodeAt(0))
              )}
              trackEventRead={TrackEvent.DOCUMENTS_PAGE_SECCI_READ}
              trackEventAccept={TrackEvent.DOCUMENTS_PAGE_SECCI_ACCEPT}
            />
            <DocumentCard
              onDocumentAccept={() =>
                setDocumentsConsent((prev) => ({ ...prev, agreementConsent: true }))
              }
              isDocumentAccepted={documentsConsent.agreementConsent}
              title={formatMessage({
                defaultMessage: 'Loan agreement',
                description: 'Documents: Loan agreement',
              })}
              documentContent={new TextDecoder().decode(
                Uint8Array.from(atob(typedResponseData?.loan_agreement ?? ''), (c) =>
                  c.charCodeAt(0)
                )
              )}
              trackEventRead={TrackEvent.DOCUMENTS_PAGE_CONTRACT_READ}
              trackEventAccept={TrackEvent.DOCUMENTS_PAGE_CONTRACT_ACCEPT}
            />
          </div>
        )}
      </div>
    </SignupLayout>
  );
};

export default DocumentsPage;
