import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  heading1: {
    defaultMessage: 'Good news {name}!',
    description: '@monefit-es Good news {name}!',
  },
  heading2: {
    defaultMessage: 'Your approved credit limit is {limit} €',
    description: '@monefit-es Your approved credit limit is {limit} €',
  },
  heading3: {
    defaultMessage: 'Choose Credit+ to proceed with the full approved limit',
    description: '@monefit-es Choose Credit+ to proceed with the full approved limit',
  },
  creditText1: {
    defaultMessage: 'Minimum payment',
    description: '@monefit-es Minimum payment',
  },
  creditText2: {
    defaultMessage: 'SmartSaver cashback',
    description: '@monefit-es SmartSaver cashback',
  },
  creditText3: {
    defaultMessage: 'SmartSaver bonus',
    description: '@monefit-es SmartSaver bonus',
  },
  subscriptionText1: {
    defaultMessage: 'Pay as much or as little as you want, get 0% p.a. credit if paid on time',
    description:
      '@monefit-es Pay as much or as little as you want, get 0% p.a. credit if paid on time',
  },
  subscriptionText2: {
    defaultMessage: 'SmartSaver cashback',
    description: '@monefit-es SmartSaver cashback',
  },
  subscriptionText3: {
    defaultMessage: 'Double SmartSaver bonus',
    description: '@monefit-es Double SmartSaver bonus',
  },
});
