import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  usedCredit: {
    defaultMessage: 'Used credit',
  },
  invoicePeriod: {
    defaultMessage: 'Invoice period',
  },
  due: {
    defaultMessage: 'Due',
  },
  invoice: {
    defaultMessage: 'Invoice',
  },
  amountSelect: {
    defaultMessage: "I'd like to pay:",
    description: "@monefit-es I'd like to pay:",
  },
  leftToPayAfterThisPayment: {
    defaultMessage: 'Left to pay after this payment:',
    description: '@monefit-es Left to pay after this payment:',
  },
  payLesss: {
    defaultMessage: 'I want to pay less',
    description: '@monefit-es I want to pay less',
  },
  payFullAmount: {
    defaultMessage: 'Pay full amount:',
    description: '@monefit-es Pay full amount:',
  },
  continue: {
    defaultMessage: 'Continue',
  },
  download: {
    defaultMessage: 'Download',
  },
});
