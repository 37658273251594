import { FC, useMemo } from 'react';

import { getClassNames } from './Loader.Skeleton.classes';

export interface LoaderSkeletonProps {
  containerClassNames?: string;
  loaderClassNames?: string;
  cursorLoader?: boolean;
}

const Skeleton: FC<LoaderSkeletonProps> = ({
  containerClassNames = '',
  loaderClassNames = '',
  cursorLoader = false,
}) => {
  const c = useMemo(
    () => getClassNames({ containerClassNames, loaderClassNames, cursorLoader }),
    [containerClassNames, cursorLoader, loaderClassNames]
  );

  return (
    <div className={c.container}>
      <div className={c.loader}></div>
    </div>
  );
};

export default Skeleton;
