import { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import { Currency } from '@common/constants';
import { useLocale, useSelector } from '@common/hooks';
import useEnvironment from '@common/hooks/useEnvironment';
import {
  selectActiveAgreement,
  selectBalance,
  selectBalanceError,
  selectLimitError,
  selectMainError,
  selectUserLimit,
} from '@common/redux/selectors/banking';
import { AgreementStatus } from '@common/types/agreement';
import { getMonthNameByDate } from '@common/utils/getMonthNameByDate';
import clsx from 'clsx';
import { add, endOfDay, format, subMonths } from 'date-fns';

import { getClassNames } from '../dashboard/Dashboard.classes';
import m from '../dashboard/Dashboard.messages';

interface InvoiceInformationItemProps {
  headerText: string;
  isWarning?: boolean;
  descriptionText: string;
  disableDetailsButton: boolean;
}

interface DashboardInvoiceInformationProps {
  hasSubscription: boolean;
  disableDetailsButton: boolean;
}

const DashboardInvoiceInformation: FC<DashboardInvoiceInformationProps> = ({
  hasSubscription,
  disableDetailsButton,
}) => {
  const { formatMessage, formatNumber } = useIntl();
  const { locale } = useLocale();
  const { isDevOrStagingEnv } = useEnvironment();

  const balanceData = useSelector(selectBalance);
  const limitData = useSelector(selectUserLimit);
  const agreement = useSelector(selectActiveAgreement);
  const limitError = useSelector(selectLimitError);
  const balanceError = useSelector(selectBalanceError);
  const agreementError = useSelector(selectMainError);

  const isErrored = useMemo(
    () => limitError || balanceError || agreementError,
    [limitError, agreementError, balanceError]
  );

  const fallBackMessage = useMemo(
    () => (isDevOrStagingEnv ? 'Fallback message, amount or payment date missing' : ''),
    [isDevOrStagingEnv]
  );

  const formatAmount = useCallback(
    (amount: number) => {
      return formatNumber(amount, {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        currencyDisplay: 'symbol',
        currency: Currency.EUR,
        style: 'currency',
      });
    },
    [formatNumber]
  );

  // Possible scenarios
  const noInvoice = useMemo(() => limitData?.balance === 0, [limitData?.balance]);
  const noInvoiceAndHasBalance = useMemo(
    () => (limitData?.balance ?? 0) > 0 && balanceData?.unpaidInvoicedTotalBalance === 0,
    [limitData?.balance, balanceData?.unpaidInvoicedTotalBalance]
  );
  const invoiceNotOverdue = useMemo(
    () => balanceData?.firstUnpaidInvoiceDueDate === null && balanceData.invoiceDueDate !== null,
    [balanceData?.firstUnpaidInvoiceDueDate, balanceData?.invoiceDueDate]
  );
  const oneOrTwoInvoicesOverdue = useMemo(
    () => balanceData?.firstUnpaidInvoiceDueDate !== null,
    [balanceData?.firstUnpaidInvoiceDueDate]
  );
  const agreementTerminated = useMemo(
    () => agreement?.status === AgreementStatus.TERMINATED,
    [agreement?.status]
  );

  // Render view based on scenario
  const renderView = useCallback(() => {
    const paymentDate = balanceData?.nextInvoiceTargetDate ?? null;
    const nextPaymentDateNotFormatted = paymentDate
      ? add(endOfDay(new Date(paymentDate)), { days: balanceData?.invoiceDueDateTerm ?? 14 })
      : null;
    const nextPaymentDate = nextPaymentDateNotFormatted
      ? format(nextPaymentDateNotFormatted, 'dd.MM.yyyy')
      : null;

    const invoiceMonthName = getMonthNameByDate({
      date: nextPaymentDateNotFormatted ? subMonths(nextPaymentDateNotFormatted, 1) : null,
      locale,
      shouldCapitilizeFirstLetter: true,
    });

    const balance = limitData?.balance ?? null;

    if (isErrored) {
      // TODO: message
      return <>N/A</>;
    }

    /**
     *  We will show this when the balance is 0, either because the customer didn’t make any drawdown just yet or because they paid off the whole credit.
     */
    if (noInvoice) {
      return (
        <InvoiceInformationItem
          headerText={formatMessage(m.noInvoiceHeaderText)}
          descriptionText={formatMessage(m.noInvoiceDescriptionText)}
          disableDetailsButton={disableDetailsButton}
        />
      );
    }
    /**
     *  We will show this when the customer made their first drawdown and still has time until we issue an invoice
     *  OR when the customer just paid the minimum amount, so there’s no invoice to pay, but at the same there’s still some balance left
     */
    if (noInvoiceAndHasBalance) {
      return (
        <InvoiceInformationItem
          disableDetailsButton={disableDetailsButton}
          headerText={formatMessage(m.noInvoiceAndHasBalanceHeaderText)}
          descriptionText={
            nextPaymentDate && balance
              ? formatMessage(
                  hasSubscription
                    ? m.noInvoiceAndHasBalanceDescriptionText
                    : m.noInvoiceAndHasBalanceDescriptionTextNoSubscription,
                  {
                    amount: formatAmount(balance),
                    date: nextPaymentDate,
                  }
                )
              : fallBackMessage
          }
        />
      );
    }
    /**
     *  We show this between the day when we issue an invoice and the day it is due (so in case of Spain between the 1st and the 15th day of the month)
     */
    if (invoiceNotOverdue) {
      const headerText = nextPaymentDate
        ? formatMessage(m.invoiceNotOverdueHeaderText, { monthName: invoiceMonthName })
        : fallBackMessage;
      const descriptionText =
        balance && nextPaymentDate
          ? formatMessage(
              hasSubscription
                ? m.invoiceNotOverdueDescriptionText
                : m.invoiceNotOverdueDescriptionTextNoSubscription,
              {
                date: nextPaymentDate,
                amount: formatAmount(balance),
              }
            )
          : fallBackMessage;

      return (
        <InvoiceInformationItem
          disableDetailsButton={disableDetailsButton}
          headerText={headerText}
          descriptionText={descriptionText}
        />
      );
    }
    /**
     *  We show this when the invoice goes overdue and continue showing when 1 invoice is overdue and we’re issuing the next one
     */
    if (oneOrTwoInvoicesOverdue) {
      const headerText = nextPaymentDate
        ? formatMessage(m.oneOrTwoInvoicesOverdueHeaderText, { monthName: invoiceMonthName })
        : fallBackMessage;

      return (
        <InvoiceInformationItem
          disableDetailsButton={disableDetailsButton}
          isWarning
          headerText={headerText}
          descriptionText={formatMessage(m.oneOrTwoInvoicesOverdueDescriptionText)}
        />
      );
    }
    /**
     *  We show this once we terminate the agreement and pas the claim to an external collection.
     */
    if (agreementTerminated) {
      return (
        <InvoiceInformationItem
          disableDetailsButton={disableDetailsButton}
          isWarning
          headerText={formatMessage(m.agreementTerminatedHeaderText)}
          descriptionText={formatMessage(m.agreementTerminatedDescriptionText)}
        />
      );
    }

    return <></>;
  }, [
    formatMessage,
    noInvoice,
    invoiceNotOverdue,
    noInvoiceAndHasBalance,
    agreementTerminated,
    oneOrTwoInvoicesOverdue,
    balanceData?.nextInvoiceTargetDate,
    balanceData?.invoiceDueDateTerm,
    fallBackMessage,
    limitData?.balance,
    locale,
    isErrored,
    hasSubscription,
    formatAmount,
    disableDetailsButton,
  ]);

  return <>{renderView()}</>;
};

export default DashboardInvoiceInformation;

const InvoiceInformationItem: FC<InvoiceInformationItemProps> = ({
  headerText,
  isWarning,
  descriptionText,
  disableDetailsButton,
}) => {
  const classNames = useMemo(() => getClassNames(), []);
  const { formatMessage } = useIntl();
  const { localeWithCountry } = useLocale();
  const navigate = useNavigate();

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2.5 sm:gap-0">
      <div className="flex w-full justify-between gap-2">
        <div
          className={clsx('text-lg font-semibold', {
            'text-black': !isWarning,
            'text-red-600': isWarning,
          })}
        >
          {headerText}{' '}
        </div>
        <Button
          disabled={disableDetailsButton}
          onClick={() => navigate(`/${localeWithCountry}/invoices`)}
          className={clsx('bg-white px-5 py-3 text-black', classNames.button, {
            [classNames.invoiceButtonsHover]: !disableDetailsButton,
            [classNames.disabledInvoiceButtonText]: disableDetailsButton,
          })}
          buttonNode={<>{formatMessage(m.detailsBtnText)}</>}
        />
      </div>
      <div className="w-full text-base font-normal text-black ">{descriptionText}</div>
    </div>
  );
};
