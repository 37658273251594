import SignupLayout from '@common/components/sign-up-layout/SignupLayout';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import FinancesForm from '@monefit-ee/components/finances-form/FinancesForm';

const FinancesPage = () => {
  useTracker({
    initEventName: TrackEvent.FINANCES_PAGE_INIT,
    errorTrackEventName: TrackEvent.FINANCES_PAGE_ERROR,
  });

  return (
    <SignupLayout>
      <FinancesForm />
    </SignupLayout>
  );
};

export default FinancesPage;
