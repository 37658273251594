import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from '@common/components/button/Button';
import { useApplicationFlowInstance, useLocale } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { RootDispatch } from '@common/redux';
import { reset } from '@common/redux/slices/application';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { getUserData, stepIdToPath } from '@common/utils';

const FlowSelectPage = () => {
  const dispatch = useDispatch<RootDispatch>();
  const navigate = useNavigate();
  const [, setStoredInstanceId] = useApplicationFlowInstance();

  const { REACT_APP_ES_DRAWDOWN_FLOW_ID, REACT_APP_ES_APPLICATION_FLOW_ID } = process.env;

  const {
    user: { id },
  } = getUserData();
  const { localeWithCountry } = useLocale();

  useEffect(() => {
    dispatch(reset());
  }, []);

  const handleDrawdownStart = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        flowId: REACT_APP_ES_DRAWDOWN_FLOW_ID,

        data: {
          userId: id,
          locale: localeWithCountry,
        },
      })
    )
      .unwrap()
      .then((r) => {
        setStoredInstanceId(r.id);
        navigate(
          `/${localeWithCountry}/${REACT_APP_ES_DRAWDOWN_FLOW_ID}/${stepIdToPath(r.currentStepId)}`
        );
      });
  }, [
    REACT_APP_ES_DRAWDOWN_FLOW_ID,
    id,
    localeWithCountry,
    navigate,
    dispatch,
    setStoredInstanceId,
  ]);

  const handleOnboardingFlowStart = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        action: FlowActions.SUBMIT,
        flowId: REACT_APP_ES_APPLICATION_FLOW_ID,
        data: {
          userId: id,
          locale: localeWithCountry,
        },
      })
    )
      .unwrap()
      .then(async (r) => {
        setStoredInstanceId(r.id);
        navigate(
          `/${localeWithCountry}/${REACT_APP_ES_APPLICATION_FLOW_ID}/${stepIdToPath(r.currentStepId)}`
        );
      });
  }, [
    setStoredInstanceId,
    dispatch,
    id,
    localeWithCountry,
    REACT_APP_ES_APPLICATION_FLOW_ID,
    navigate,
  ]);

  const handlePaymentStart = useCallback(async () => {
    navigate(`/${localeWithCountry}/payment`);
  }, [localeWithCountry, navigate]);

  return (
    <div className="flex flex-col gap-2">
      <Button text="Drawdown" onClick={handleDrawdownStart} />
      <Button text="Onboarding" onClick={handleOnboardingFlowStart} />
      <Button text="Payment" onClick={handlePaymentStart} />
    </div>
  );
};

export default FlowSelectPage;
