import {
  AuthorizeResponse,
  LinkBasedAuthorizeRequest,
  LinkBasedLoginRequest,
  LinkBasedLoginResponse,
  LinkBasedRegisterRequest,
  LinkBasedRegisterResponse,
  OAuthGetAuthUrlRequest,
  OAuthGetAuthUrlResponse,
  OauthAutorizeRequest,
  authService,
} from '@common/services/authentication';
import { ApiError } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

export const linkBasedRegister = createAsyncThunk<
  LinkBasedRegisterResponse,
  LinkBasedRegisterRequest
>('auth/link-based/register', async (data): Promise<LinkBasedRegisterResponse> => {
  try {
    const response = await authService.post<
      LinkBasedRegisterRequest,
      AxiosResponse<LinkBasedRegisterResponse>
    >(`/auth/register`, data);
    return response.data;
  } catch (e) {
    const typedError = e as AxiosError;
    throw new AxiosError(typedError.message, String(typedError.status));
  }
});

export const oAuthGetAuthorizationUrl = createAsyncThunk<
  OAuthGetAuthUrlResponse,
  OAuthGetAuthUrlRequest
>('auth/oauth/get-authorization-url', async (data): Promise<OAuthGetAuthUrlResponse> => {
  try {
    const response = await authService.post<
      LinkBasedRegisterRequest,
      AxiosResponse<OAuthGetAuthUrlResponse>
    >(`/oauth2/get-authorization-url`, data);
    return response.data;
  } catch (e) {
    const typedError = e as AxiosError;
    throw new AxiosError(typedError.message, String(typedError.status));
  }
});

export const oAuthAuthorize = createAsyncThunk<AuthorizeResponse, OauthAutorizeRequest>(
  'auth/oauth/authorize',
  async (data): Promise<AuthorizeResponse> => {
    try {
      const response = await authService.post<
        OauthAutorizeRequest,
        AxiosResponse<AuthorizeResponse>
      >(`/oauth2/authorize`, data);
      return response.data;
    } catch (e) {
      const typedError = e as AxiosError;
      throw new AxiosError(typedError.message, String(typedError.status));
    }
  }
);

export const linkBasedLogin = createAsyncThunk<LinkBasedLoginResponse, LinkBasedLoginRequest>(
  'auth/login',
  async (data): Promise<LinkBasedLoginResponse> => {
    try {
      const response = await authService.post<
        OauthAutorizeRequest,
        AxiosResponse<LinkBasedLoginResponse>
      >(`/auth/login`, data);
      return response.data;
    } catch (e) {
      const typedError = e as AxiosError;
      throw new AxiosError(typedError.message, String(typedError.status));
    }
  }
);

export const linkBasedAuthorize = createAsyncThunk<AuthorizeResponse, LinkBasedAuthorizeRequest>(
  'auth/verify',
  async (data): Promise<AuthorizeResponse> => {
    try {
      const response = await authService.post<
        OauthAutorizeRequest,
        AxiosResponse<AuthorizeResponse>
      >(`/auth/verify`, data);
      return response.data;
    } catch (e) {
      const typedError = e as AxiosError;
      throw new AxiosError(typedError.message, String(typedError.status));
    }
  }
);
