import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  headingText: {
    defaultMessage: 'Invoice settled',
    description: '@monefit-es Invoice settled',
  },
  descriptionText: {
    defaultMessage: 'Deposit successful',
    description: '@monefit-es Deposit successful',
  },
  buttonText: {
    defaultMessage: 'Done',
  },
});
