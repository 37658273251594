import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import BlackDotsLoader from '@common/components/loader/BlackDotsLoader';
import Slider from '@common/components/slider/Slider';
import { Currency } from '@common/constants';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIN_WITHDRAWAL_AMOUNT } from '@monefit-es/constants/withdrawal-limit';
import useLimitAndBalance from '@monefit-es/hooks/useLimitAndBalance';
import { PaymentStep } from '@monefit-es/pages/payment-page/PaymentPage.types';
import { add, endOfDay, format } from 'date-fns';
import * as yup from 'yup';

import { getClassNames } from './PaymentStartForm.classes';
import m from './PaymentStartForm.messages';

interface InfoSectionItemProps {
  title: string;
  value: any;
  loading?: boolean;
}

interface FormValues {
  amount: number;
}

interface PaymentStartFormProps {
  step: PaymentStep;
  setStep: (v: PaymentStep) => void;
  setAmount: (v: number) => void;
  amount?: number;
}

const PaymentStartForm = ({
  step,
  setStep,
  setAmount,
  amount: defaultAmount,
}: PaymentStartFormProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { limitData, balanceData, balanceLoading, limitLoading } = useLimitAndBalance({
    disableAgreementFetch: true,
    disableBalanceFetch: true,
    disableLimitFetch: true,
  });
  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      amount: yup.number().required(),
    });
  }, []);

  const dueDate = useMemo(
    () =>
      balanceData?.nextInvoiceTargetDate
        ? add(endOfDay(new Date(balanceData.nextInvoiceTargetDate)), {
            days: balanceData?.invoiceDueDateTerm ?? 14,
          })
        : null,
    [balanceData?.nextInvoiceTargetDate, balanceData?.invoiceDueDateTerm]
  );

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: defaultAmount || limitData?.balance || MIN_WITHDRAWAL_AMOUNT,
    },
  });

  const {
    watch,
    formState: { isSubmitting, isValid },
  } = ctx;

  const amount = watch('amount');

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      if (step === PaymentStep.START) {
        setAmount(v.amount);
        setStep(PaymentStep.CONRIRMATION);
      }

      if (step === PaymentStep.CONRIRMATION) {
        setStep(PaymentStep.CHECKOUT);
      }
    },
    [setAmount, step, setStep]
  );
  const classes = useMemo(() => getClassNames(), []);

  return (
    <form
      autoComplete="off"
      className="flex size-full"
      onSubmit={ctx.handleSubmit(async (v: FormValues) => {
        try {
          await handleFormSubmit(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="flex w-full">
          <div className="flex w-full flex-col items-start justify-end self-stretch">
            <div className={classes.infoCardContainer}>
              <InfoSectionItem
                title={formatMessage(m.usedCredit)}
                value={
                  limitData?.balance
                    ? formatNumber(limitData.balance, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        currencyDisplay: 'symbol',
                        currency: Currency.EUR,
                        style: 'currency',
                      })
                    : '-'
                }
                loading={limitLoading}
              />
              <InfoSectionItem
                title={formatMessage(m.invoicePeriod)}
                // TODO: Value
                value={<div className="text-red-500">01 Oct - 31 Oct</div>}
                loading={false}
              />
              <InfoSectionItem
                title={formatMessage(m.due)}
                value={dueDate ? format(dueDate, 'dd.MM.yyyy') : 'N/A'}
                loading={balanceLoading}
              />

              <InfoSectionItem
                title={formatMessage(m.invoice)}
                value={
                  <div className="-mr-3">
                    <Button
                      text={formatMessage(m.download)}
                      spacing={ButtonSpacing.SM}
                      disabled
                      color="white"
                      hoverClassName="hover:bg-black hover:text-white"
                      borderClassName="border-1 border-white"
                    />
                  </div>
                }
                loading={false}
              />
            </div>
            <div className={classes.formContainer}>
              <div className="flex flex-col items-start justify-center gap-5 self-stretch">
                <div className="flex flex-col items-start justify-center gap-2 self-stretch">
                  <div className="text-base leading-snug tracking-tight text-black">
                    {formatMessage(step === PaymentStep.START ? m.amountSelect : m.payFullAmount)}
                  </div>
                  <div className="text-5xl font-bold leading-10 text-black">
                    {formatNumber(amount, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 0,
                      currencyDisplay: 'symbol',
                      currency: Currency.EUR,
                      style: 'currency',
                    })}
                  </div>
                </div>

                {step === PaymentStep.START && (
                  <Slider
                    color="green"
                    name="amount"
                    min={MIN_WITHDRAWAL_AMOUNT}
                    step={50}
                    max={limitData?.balance ?? 0}
                  />
                )}
                {step === PaymentStep.CONRIRMATION && (
                  <div className={classes.infoSectionItemWrapper}>
                    <div className={classes.infoSectionItemTitle}>{formatMessage(m.payLesss)}</div>
                    <div className="-mr-3">
                      <Button
                        spacing={ButtonSpacing.SM}
                        type="button"
                        color="dark-gray"
                        text="Contact support"
                      />
                    </div>
                  </div>
                )}

                <Button
                  disabled={!isValid || isSubmitting || limitLoading || balanceLoading}
                  type="submit"
                  fullWidth
                  text={formatMessage(m.continue)}
                />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default PaymentStartForm;

const InfoSectionItem: FC<InfoSectionItemProps> = ({ title, value, loading }) => {
  const classes = useMemo(() => getClassNames(), []);

  return (
    <div className={classes.infoSectionItemWrapper}>
      <div className={classes.infoSectionItemTitle}>{title}</div>
      {loading ? (
        <BlackDotsLoader style={{ height: 20 }} />
      ) : (
        <div className={classes.infoSectionItemValue}>{value}</div>
      )}
    </div>
  );
};
