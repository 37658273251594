import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

import { LocalStorageKeys } from '@common/constants';
import { getUserData } from '@common/utils';
import {
  DocumentType,
  SessionStorageDocumentItem,
} from '@monefit-es/components/documents/Document.types';

interface DocumentsContextType {
  documents: SessionStorageDocumentItem[];
  getDocumentsFromStorage: () => SessionStorageDocumentItem[];
  saveDocumentToStorage: ({ type, id, isSigned }: SessionStorageDocumentItem) => void;
}
const DocumentsContext = createContext<DocumentsContextType | undefined>(undefined);

export const useDocuments = () => {
  const context = useContext(DocumentsContext);
  if (!context) {
    throw new Error('useDocuments must be used within a DocumentsProvider');
  }
  return context;
};

export const DocumentsProvider = ({ children }: { children: ReactNode }) => {
  const getDocumentsFromStorage = (): SessionStorageDocumentItem[] => {
    const { user } = getUserData();
    const documentsRaw = sessionStorage.getItem(`${LocalStorageKeys.DOCUMENTS}_${user.id}`) ?? null;
    return documentsRaw ? JSON.parse(documentsRaw) : [];
  };

  const [documents, setDocuments] = useState(getDocumentsFromStorage() ?? []);

  const saveDocumentToStorage = ({
    type,
    id,
    isSigned,
  }: {
    type: DocumentType;
    id: string;
    isSigned: boolean;
  }) => {
    const { user } = getUserData();
    const currentDocuments = getDocumentsFromStorage() ?? [];
    const newList = currentDocuments?.filter((x) => x.type !== type);
    newList.push({ type, id, isSigned });
    sessionStorage.setItem(`${LocalStorageKeys.DOCUMENTS}_${user.id}`, JSON.stringify(newList));
    setDocuments(newList);
  };

  useEffect(() => {
    setDocuments(getDocumentsFromStorage());
  }, [setDocuments]);

  return (
    <DocumentsContext.Provider
      value={{ documents, saveDocumentToStorage, getDocumentsFromStorage }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
