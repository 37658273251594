import {
  GetFlowInstanceRequest,
  GetPostFlowInstanceResponse,
  PostFlowInstanceRequest,
  applicationService,
} from '@common/services/application';
import { refreshToken } from '@common/services/protectedHttpService';
import { ApiError } from '@common/utils';
import { getFlowId } from '@common/utils/helpers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError, AxiosResponse } from 'axios';

/**
 * Fetches current application flow state for specified instance
 */

export const fetchApplicationFlowInstance = createAsyncThunk<
  GetPostFlowInstanceResponse,
  string | undefined
>('application/flow-instance/get', async (instanceId) => {
  const flowId = getFlowId();
  try {
    const response = await applicationService.get<
      GetFlowInstanceRequest,
      AxiosResponse<GetPostFlowInstanceResponse>
    >(`/flow/${flowId}/instances${instanceId ? '/' + instanceId : ''}`, {});
    return response.data;
  } catch (e) {
    throw new ApiError(String(e));
  }
});

/**
 * Performs action on application instance specified by payload data
 */

export const performApplicationFlowAction = createAsyncThunk<
  GetPostFlowInstanceResponse,
  PostFlowInstanceRequest
>('application/flow-instance/action', async (data): Promise<GetPostFlowInstanceResponse> => {
  const { instanceId, shouldRefreshToken, flowId: flowIdProp, ...rest } = data;
  const flowId = flowIdProp ?? getFlowId();
  if (shouldRefreshToken) {
    await refreshToken();
  }
  try {
    const response = await applicationService.post<
      PostFlowInstanceRequest,
      AxiosResponse<GetPostFlowInstanceResponse>
    >(`/flow/${flowId}/instances${instanceId ? '/' + instanceId : ''}`, {
      ...rest,
    });
    return response.data;
  } catch (e) {
    const typedError = e as AxiosError;
    const typedResponseData = typedError?.response?.data as GetPostFlowInstanceResponse;
    if (typedResponseData?.currentStepId) {
      // Set new flowinstance data with latest state and show errors
      return typedResponseData;
    } else {
      throw new ApiError(String(e));
    }
  }
});
