import { FC, useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import SelectInput from '@common/components/select-input/SelectInput';
import SpinnerLoader from '@common/components/spinner-loader/SpinnerLoader';
import { useLocale } from '@common/hooks';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import axios from 'axios';
import { uniq } from 'lodash';

import {
  AddressApartmentsSearchResponse,
  InAadressSelectResponse,
} from './AddressInput.Maaamet.types';
import { AddressAttributeMap } from './AddressInput.types';

import './AddressInput.Maaamet.styles.css';

export interface AddressInputMaaametProps {
  name: string;
  attributeMap?: Partial<AddressAttributeMap>; // Not used in this component
}

const AddressInputMaaamet: FC<AddressInputMaaametProps> = ({ name }) => {
  const { trackEvent } = useTracker({});
  const { formatMessage } = useIntl();
  const [apartmentsLoading, setApartmentsLoading] = useState(false);
  const { locale } = useLocale();

  const {
    formState: { errors },
    trigger,
    setValue,
    resetField,
  } = useFormContext();

  const [apartments, setApartments] = useState<AddressApartmentsSearchResponse[]>([]);

  const loadApartments = useCallback(async (ads_oid: string) => {
    setApartmentsLoading(true);
    try {
      const res = await axios.get(
        `https://inaadress.maaamet.ee/inaadress/gazetteer?hooneoid=${ads_oid}`
      );
      return (res?.data?.appartments as AddressApartmentsSearchResponse[]) ?? [];
    } catch (_) {
      return [];
    } finally {
      setApartmentsLoading(false);
    }
  }, []);

  const handleInputChange = useCallback(
    (e: any) => {
      // reset address model if user clears address value
      if (!e.target?.value) {
        setApartments([]);
        resetField('apartmentNo');
        setValue(name, {
          region: null,
          city: null,
          street: null,
          houseNo: null,
          apartmentNo: undefined,
          postcode: null,
          hasApartments: false,
          houseName: null,
        });
        trigger(name);
      }
    },
    [setValue, trigger, name, resetField]
  );

  const handleAddressSelect = useCallback(
    async (e: any) => {
      resetField('apartmentNo');
      const details = (e?.detail as InAadressSelectResponse) ?? null;
      const apartmentsResult = await loadApartments(details.ads_oid);
      setApartments(apartmentsResult);
      const address = {
        region: details?.maakond ?? null,
        locality: details?.asustusyksus ?? null,
        city: details?.omavalitsus ?? null,
        street: details?.liikluspind ?? null,
        houseNo: details?.aadress_nr ?? null,
        postcode: details?.sihtnumber ?? null,
        apartmentNo: undefined,
        hasApartments: !!apartmentsResult.length,
        houseName: details?.nimi || details?.lahiaadress,
      };
      setValue(name, address);
      trigger(['address.city']);

      trackEvent(TrackEvent.ADDRESS_PAGE_ADDRESS_ENTERED);
    },
    [name, setValue, trigger, trackEvent, resetField, loadApartments]
  );

  // Handle address select
  useEffect(() => {
    document.addEventListener('addressSelected', handleAddressSelect);

    return () => {
      document.removeEventListener('addressSelected', handleAddressSelect);
    };
  }, []);

  // Clear values if user deleted address
  useEffect(() => {
    document.addEventListener('input', handleInputChange);

    return () => {
      document.removeEventListener('input', handleInputChange);
    };
  }, []);

  useEffect(() => {
    const mainAddressScript = document.createElement('script');
    mainAddressScript.src = 'https://inaadress.maaamet.ee/inaadress/js/inaadress.min.js';
    mainAddressScript.type = 'text/javascript';
    const addressInitScript = document.createElement('script');

    mainAddressScript.onload = () => {
      addressInitScript.text = `var inAadress = new InAadress({"container":"InAadressDiv","unHoone":true,"searchLayers":["EHITISHOONE"],"nocss":true,"hideLogo":1,"mode":"3","appartment":1,"lang":"${locale}"});`;
    };

    document.head.appendChild(mainAddressScript);
    document.body.appendChild(addressInitScript);

    return () => {
      document.head.removeChild(mainAddressScript);
      document.body.removeChild(addressInitScript);
    };
  }, []);

  const mappedErrors = Object.values(errors?.[name] ?? {}).map((e) => e.message);
  const uniqErrors = uniq(mappedErrors);

  return (
    <>
      <div id="InAadressDiv">
        {apartmentsLoading && (
          <div className="flex items-center justify-center p-2">
            <SpinnerLoader />
          </div>
        )}
        {!!apartments.length && (
          <SelectInput
            name="address.apartmentNo" // TODO: take name from props
            options={apartments.map((x) => ({ value: x.tahis, label: x.tahis }))}
            label={formatMessage({
              defaultMessage: 'Apartment number',
              description: 'Address: Apartment number',
            })}
            className="mt-2"
            hideError
            triggerOnChange
          />
        )}
        {!!mappedErrors?.length && (
          <div className="mt-1 flex flex-col gap-1 text-xs font-semibold text-rose-400">
            {uniqErrors?.map((x) => <div key={x}>{x}</div>)}
          </div>
        )}
      </div>
    </>
  );
};

export default AddressInputMaaamet;
