import { defineMessages } from '@formatjs/intl';

export default defineMessages({
  invalidOtpCode: {
    defaultMessage: 'Incorrect code. Please try again',
  },
  fallBackMessage: {
    defaultMessage:
      'An error occurred while processing the request. Please contact us if you believe this is a server error. Thank you.',
    description: 'ErrorBoundaryPage: Error fallbackMessage',
  },
});
