import { FC, useCallback } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import Button, { ButtonSpacing } from '@common/components/button/Button';

export interface ButtonGroupInputProps {
  name: string;
  label?: string;
  externalHandler?: () => void;
  options: {
    label: string;
    value: any;
  }[];
  isBoldLabel?: boolean;
  allowedSelections?: number;
}

const EXTRA_CLASS_NAMES = 'font-medium w-full basis-auto md:basis-fit-content';

const ButtonGroupInput: FC<ButtonGroupInputProps> = ({
  name,
  label,
  options,
  externalHandler,
  isBoldLabel = false,
  allowedSelections = null,
}) => {
  useController({
    name,
    rules: {},
  });

  const { getValues, setValue, trigger } = useFormContext();

  const onClickMultiValue = useCallback(
    (value: any) => {
      const currentValues = getValues(name) ?? [];
      const isSelected = currentValues.indexOf(value) !== -1;

      if (allowedSelections && currentValues.length >= allowedSelections && !isSelected) {
        return;
      }

      if (isSelected) {
        setValue(
          name,
          currentValues.filter((x: any) => x !== value)
        );
      } else {
        setValue(name, [...currentValues, value]);
      }
      if (externalHandler) {
        externalHandler();
      }
      trigger(name);
    },
    [getValues, name, allowedSelections, externalHandler, trigger, setValue]
  );

  const onClickSingleValue = useCallback(
    (value: string) => {
      const currentValue = getValues(name) ?? '';
      const isSelected = currentValue === value;

      if (isSelected) {
        setValue(name, currentValue !== value ? currentValue : '');
      } else {
        setValue(name, value);
      }
      if (externalHandler) {
        externalHandler();
      }
      trigger(name);
    },
    [getValues, name, setValue, trigger, externalHandler]
  );

  const getColor = useCallback(
    (value: string | any[]) => {
      const currentValue = getValues(name);

      if (typeof currentValue === 'string') {
        return value === currentValue ? 'blue' : 'gray';
      }
      return currentValue?.find((x: any) => x === value) ? 'blue' : 'gray';
    },
    [getValues, name]
  );

  return (
    <div className="inline-flex w-full flex-col items-start justify-start gap-2.5">
      <div
        className={`text-sm font-medium leading-normal text-black ${
          isBoldLabel ? 'text-[15px] text-opacity-100' : 'text-opacity-50'
        }`}
      >
        {label}
      </div>
      <div className="flex w-full flex-wrap gap-2 sm:gap-4">
        {options.map((opt) => (
          <Button
            type="button"
            color={getColor(opt.value)}
            text={opt.label}
            key={opt.value}
            spacing={ButtonSpacing.BUTTON_GROUP}
            roundedFull={false}
            extraClassNames={EXTRA_CLASS_NAMES}
            onClick={() =>
              allowedSelections === 1 ? onClickSingleValue(opt.value) : onClickMultiValue(opt.value)
            }
          />
        ))}
      </div>
    </div>
  );
};

export default ButtonGroupInput;
