/* eslint-disable tailwindcss/no-custom-classname */
import clsx from 'clsx';

export interface GetClassNamesProps {
  containerClassNames?: string;
  loaderClassNames?: string;
  cursorLoader?: boolean;
}

export const getClassNames = ({
  containerClassNames = '',
  loaderClassNames = '',
  cursorLoader = false,
}: GetClassNamesProps) => ({
  container: clsx('flex animate-pulse', containerClassNames),
  loader: clsx(
    'size-full rounded bg-gray-200',
    { 'cursor-loader': cursorLoader },
    loaderClassNames
  ),
});
