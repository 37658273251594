import { useIntl } from 'react-intl';

import LoadingCard from '@common/components/loader/LoadingCard';
import useInitialFlowActionPerform from '@common/hooks/useInitialFlowActionPerform';
import { FlowActions } from '@common/services/application';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import loader from '@src/assets/img/monefit-es/bank_loader.webp';

import m from './OnboardingDecisionPage.messages';

const OnboardingDecisionPage = () => {
  const { formatMessage } = useIntl();

  const { renderView } = useInitialFlowActionPerform({
    successComponent: <LoadingCard text={formatMessage(m.loadingCardText)} imgSrcProp={loader} />,
    flowAction: FlowActions.SUBMIT,
  });

  return (
    <MainLayout hideProgressBar hideHeaderAndFooter>
      {renderView()}
    </MainLayout>
  );
};

export default OnboardingDecisionPage;
