import { useCallback, useEffect, useState } from 'react';

import { useApplicationFlowInstance, useDispatch, useInterval, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { selectFlowInstanceStep } from '@common/redux/selectors';
import {
  fetchApplicationFlowInstance,
  performApplicationFlowAction,
} from '@common/redux/thunks/application';
import { FlowActions, FlowSteps } from '@common/services/application';
import { DocumentType } from '@monefit-es/components/documents/Document.types';
import Documents from '@monefit-es/components/documents/Documents';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import MainLoader from '@monefit-es/components/loaders/MainLoader';
import { useDocuments } from '@monefit-es/hooks/useDocuments';

const DocumentsPage = () => {
  const data = useSelector((st) => st.application.flowInstance);
  const [instanceId] = useApplicationFlowInstance();
  const dispatch = useDispatch<RootDispatch>();
  const [redirectUrl, setRedirectUrl] = useState<string | null>(null);
  const [verifyFailed, setVerifyFailed] = useState(false);

  const { documents } = useDocuments();

  const canDoVerifyPolling =
    documents.length === 2 && documents.every((x) => x.isSigned) && !verifyFailed;

  const currentStep = useSelector(selectFlowInstanceStep);

  const fetchDocuments = useCallback(async () => {
    if (data.flowDefinition || !instanceId) {
      return;
    }

    await dispatch(fetchApplicationFlowInstance(instanceId));
  }, [instanceId, data.flowDefinition, dispatch]);

  const verifyDocuments = useCallback(async () => {
    await dispatch(
      performApplicationFlowAction({
        instanceId,
        action: FlowActions.VERIFY,
        data: {
          documentIds: documents
            .filter((x) => x.type === DocumentType.CREDIT_AGREEMENT)
            .map((x) => x.id),
        },
      })
    )
      .unwrap()
      .catch(() => setVerifyFailed(true));
  }, [dispatch, instanceId, documents]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  useInterval(
    verifyDocuments,
    currentStep === FlowSteps.DOCUMENTS && canDoVerifyPolling ? 3000 : null
  );

  if (redirectUrl) {
    window.location.href = redirectUrl;
    return null;
  }

  if (canDoVerifyPolling) {
    return (
      <MainLayout hideProgressBar hideHeaderAndFooter>
        <MainLoader />
      </MainLayout>
    );
  }

  return (
    <MainLayout>
      <Documents setRedirectUrl={setRedirectUrl} />
    </MainLayout>
  );
};

export default DocumentsPage;
