import { useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedNumber, useIntl } from 'react-intl';

import Button from '@common/components/button/Button';
import SelectInput from '@common/components/select-input/SelectInput';
import Slider from '@common/components/slider/Slider';
import { Currency, CurrencySymbol } from '@common/constants';
import { useApplicationFlowInstance, useDispatch, useSelector } from '@common/hooks';
import { RootDispatch } from '@common/redux';
import { performApplicationFlowAction } from '@common/redux/thunks/application';
import { FlowActions } from '@common/services/application';
import { yupResolver } from '@hookform/resolvers/yup';
import { MIN_WITHDRAWAL_AMOUNT } from '@monefit-es/constants/withdrawal-limit';
import * as yup from 'yup';

import m from './DrawdownStartForm.messages';

interface FormValues {
  amount: number;
  bankAccount: string;
}

interface BankAccount {
  iban: string;
  name: string;
}

const DrawdownStarForm = () => {
  const { formatMessage } = useIntl();
  const { data } = useSelector((st) => st.application.flowInstance);

  const dispatch = useDispatch<RootDispatch>();
  const [instanceId] = useApplicationFlowInstance();

  const schema: yup.ObjectSchema<FormValues> = useMemo(() => {
    return yup.object({
      amount: yup.number().required(),
      bankAccount: yup.string().required(formatMessage(m.bankAccountRequired)),
    });
  }, [formatMessage]);

  const bankAccountOptions = useMemo(() => {
    const bankAccounts: BankAccount[] = data?.responseData?.bankAccounts ?? [];

    return bankAccounts.map((x) => ({ label: x.iban, value: x.iban }));
  }, [data?.responseData?.bankAccounts]);

  const ctx = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: {
      amount: data?.responseData?.available,
      bankAccount: data?.responseData.bankAccounts?.[0]?.iban,
    },
  });

  const {
    watch,
    formState: { isSubmitting, isValid },
  } = ctx;

  const amount = watch('amount');

  const handleFormSubmit = useCallback(
    async (v: FormValues) => {
      await dispatch(
        performApplicationFlowAction({
          action: FlowActions.SUBMIT,
          instanceId,
          data: { ...v },
        })
      );
    },
    [dispatch, instanceId]
  );

  return (
    <form
      autoComplete="off"
      className="flex size-full"
      onSubmit={ctx.handleSubmit(async (v: FormValues) => {
        try {
          await handleFormSubmit(v);
        } catch (_) {}
      })}
    >
      <FormProvider {...ctx}>
        <div className="flex w-full">
          <div className="flex w-full flex-col items-center justify-start gap-2.5">
            <div className="flex w-full flex-col items-start justify-start gap-2.5">
              <div className="flex flex-col items-start justify-end self-stretch">
                <div className="flex flex-col items-start justify-start gap-[5px] self-stretch rounded-t-[28px] bg-stone-100 px-7 pb-7 pt-4">
                  <div className="inline-flex items-center justify-between self-stretch">
                    <div className="text-base font-medium leading-normal text-black  text-opacity-60">
                      {formatMessage(m.subTitle)}
                    </div>
                    <div className="text-sm font-semibold leading-snug tracking-tight text-black">
                      <FormattedNumber
                        // eslint-disable-next-line react/style-prop-object
                        style="currency"
                        currency={Currency.EUR}
                        currencyDisplay="symbol"
                        minimumFractionDigits={2}
                        maximumFractionDigits={2}
                        value={data?.responseData?.available}
                      />
                    </div>
                  </div>
                </div>
                <div className="-mt-4 flex flex-col items-start justify-between self-stretch rounded-[28px] bg-white p-7 backdrop-blur-[18px]">
                  <div className="flex flex-col items-start justify-center gap-5 self-stretch">
                    <div className="flex h-16 flex-col items-start justify-center gap-1 self-stretch">
                      <div className="w-44 text-base font-medium leading-snug tracking-tight text-black">
                        {formatMessage(m.toWithdraw)}
                      </div>
                      <div className="inline-flex items-end justify-start gap-1 self-stretch">
                        <div className="font-heavy text-5xl font-black leading-10 text-black">
                          <FormattedNumber
                            value={amount}
                            minimumFractionDigits={0}
                            maximumFractionDigits={0}
                          />
                          {CurrencySymbol.EUR}
                        </div>
                      </div>
                    </div>

                    <Slider
                      color="green"
                      name="amount"
                      min={MIN_WITHDRAWAL_AMOUNT}
                      step={50}
                      max={data?.responseData?.available}
                    />
                    <div className="w-full">
                      <SelectInput
                        label={formatMessage(m.bankAccountInputLabel)}
                        name="bankAccount"
                        options={bankAccountOptions}
                      />
                    </div>
                    <Button
                      disabled={!isValid || isSubmitting}
                      type="submit"
                      fullWidth
                      text={formatMessage(m.confirm)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </form>
  );
};

export default DrawdownStarForm;
