import { useCallback } from 'react';
import { FieldArrayMethodProps } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button, { ButtonSpacing } from '@common/components/button/Button';
import CurrencyInput from '@common/components/currency-input/CurrencyInput';
import SelectInput from '@common/components/select-input/SelectInput';
import useTracker from '@common/hooks/useTracker';
import { TrackEvent } from '@common/utils/amplitude/events';
import useFinancesForm from '@monefit-ee/hooks/useFinancesForm';

interface IProps {
  append: (value: any, options?: FieldArrayMethodProps | undefined) => void;
  fields: any[];
  handleRemoveCredit: (index: number) => void;
}

const ACTIVE_CREDITS_LIMIT = 20;

const ActiveCreditsInputGroup = ({ append, fields, handleRemoveCredit }: IProps) => {
  const intl = useIntl();
  // TODO: add more options and descriptions when all options are provided
  const { trackEvent } = useTracker({});

  const { loanTypeOptions } = useFinancesForm();
  const handleAddCredit = useCallback(() => {
    if (fields.length >= ACTIVE_CREDITS_LIMIT) {
      return;
    }
    trackEvent(TrackEvent.FINANCES_PAGE_ADD_LOANS_CLICKED);
    append({
      activeCreditType: null,
      activeCreditAmount: null,
    });
  }, [append, fields, trackEvent]);

  return (
    <div className="flex flex-col gap-5">
      {fields.map((item, i) => (
        <div key={item.id} className="flex flex-col gap-2">
          <div className="flex justify-between text-sm font-medium leading-normal text-black text-opacity-50">
            <FormattedMessage
              defaultMessage="Loan details"
              description="Finances: 'Loan details'"
            />
            <Button
              color="underlined-black"
              type="button"
              extraClassNames="text-red-300 no-underline"
              spacing={ButtonSpacing.NONE}
              onClick={() => handleRemoveCredit(i)}
              text={intl.formatMessage({
                defaultMessage: 'Remove',
                description: 'Finances: "Remove" button text on active loan',
              })}
            />
          </div>

          <div className="flex flex-col gap-2 sm:flex-row">
            <div className="w-full">
              <SelectInput
                name={`activeCredits.${i}.activeCreditType`}
                label={intl.formatMessage({
                  defaultMessage: 'Loan type',
                  description: 'Finances: "Loan type"',
                })}
                options={loanTypeOptions}
              />
            </div>
            <div className="w-full">
              <CurrencyInput
                label={intl.formatMessage({
                  defaultMessage: 'Monthly amount',
                  description: 'Finances: "Monthly amount"',
                })}
                name={`activeCredits.${i}.activeCreditAmount`}
              />
            </div>
          </div>
        </div>
      ))}
      {fields.length < ACTIVE_CREDITS_LIMIT && (
        <div className="mt-2 flex justify-center">
          <Button
            type="button"
            color="blue-light"
            text={intl.formatMessage({
              defaultMessage: 'Add more active loans',
              description: 'Finances: "Add more active loans"',
            })}
            onClick={handleAddCredit}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveCreditsInputGroup;
