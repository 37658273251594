const SuccessIcon = () => {
  return (
    <svg width="69" height="70" viewBox="0 0 69 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="1.95987"
        y="2.17276"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        fill="#BBF3BA"
        fillOpacity="0.07"
      />
      <rect
        x="1.95987"
        y="2.17276"
        width="65.0803"
        height="65.0803"
        rx="32.5401"
        stroke="#BBF3BA"
        strokeWidth="3.67364"
        strokeLinecap="square"
      />
      <path
        d="M29.3815 33.6904L33.4763 37.7852L41.6659 29.5956"
        stroke="#BBF3BA"
        strokeWidth="3.07108"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SuccessIcon;
