const ProfileIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4995 6.94128C16.4995 9.97878 14.0371 12.4412 10.9996 12.4412C7.9621 12.4412 5.49972 9.97878 5.49972 6.94128C5.49972 3.90378 7.9621 1.44141 10.9996 1.44141C14.0371 1.44141 16.4995 3.90378 16.4995 6.94128ZM14.6662 6.94128C14.6662 8.96628 13.0246 10.6079 10.9996 10.6079C8.9746 10.6079 7.33301 8.96628 7.33301 6.94128C7.33301 4.91628 8.9746 3.2747 10.9996 3.2747C13.0246 3.2747 14.6662 4.91628 14.6662 6.94128Z"
        fill="#15171B"
        fillOpacity="0.48"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9997 13.3578C8.30919 13.3578 6.36934 13.9159 4.97782 14.7527C3.58075 15.5928 2.79605 16.6783 2.36271 17.6276C1.99744 18.4279 2.16369 19.2434 2.64899 19.8243C3.11159 20.3782 3.83087 20.691 4.58312 20.691H17.4161C18.1684 20.691 18.8876 20.3782 19.3502 19.8244C19.8355 19.2434 20.0018 18.4279 19.6366 17.6277C19.2032 16.6783 18.4186 15.5929 17.0215 14.7527C15.63 13.9159 13.6902 13.3578 10.9997 13.3578ZM4.03048 18.3889C4.33128 17.7299 4.88383 16.9485 5.92262 16.3238C6.96696 15.6958 8.56158 15.1911 10.9997 15.1911C13.4378 15.1911 15.0324 15.6958 16.0767 16.3238C17.1155 16.9485 17.668 17.7299 17.9688 18.3889C18.0043 18.4667 18.0027 18.5135 17.9992 18.5374C17.9951 18.5647 17.9817 18.603 17.9432 18.6491C17.8606 18.748 17.6764 18.8577 17.4161 18.8577H4.58312C4.32286 18.8577 4.13865 18.748 4.05601 18.6491C4.01753 18.603 4.00414 18.5647 4.00007 18.5374C3.9965 18.5135 3.99495 18.4667 4.03048 18.3889Z"
        fill="#15171B"
        fillOpacity="0.48"
      />
    </svg>
  );
};

export default ProfileIcon;
