import { getCoreServiceBaseUrls } from '@common/utils';
import axios from 'axios';

const { documentServiceBaseUrl } = getCoreServiceBaseUrls();

export const documentService = axios.create({
  baseURL: documentServiceBaseUrl,
  headers: {
    'Content-type': 'application/json',
  },
});
