import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Country, useLocale } from '@common/hooks';
import { useDispatch } from '@common/hooks/useDispatch';
import { useSelector } from '@common/hooks/useSelector';
import { RootDispatch } from '@common/redux';
import { linkBasedRegister } from '@common/redux/thunks/authentication';
import { LinkBasedRegisterResponse, RegisterChannel } from '@common/services/authentication';
import SignupForm from '@monefit-es/components/forms/SignupForm';
import MainLayout from '@monefit-es/components/layout/MainLayout';
import CheckEmailCard from '@monefit-es/components/loaders/CheckEmailCard';

const SignupPage = () => {
  const [searchParams] = useSearchParams();
  const referrer = searchParams.get('referrer') ?? null;
  const { localeWithCountry } = useLocale();
  const { data } = useSelector((st) => st.authentication);
  const dispatch = useDispatch<RootDispatch>();

  const handleResend = useCallback(async () => {
    const typedData = (data as LinkBasedRegisterResponse) ?? {};
    const { displayName, identifier } = typedData;
    await dispatch(
      linkBasedRegister({
        displayName,
        identifier,
        referrer,
        channel: RegisterChannel.EMAIL,
        region: Country.ES.toUpperCase(),
        locale: localeWithCountry,
      })
    ).unwrap();
  }, [data, dispatch, referrer, localeWithCountry]);
  return (
    <>
      {(data as LinkBasedRegisterResponse)?.outcome === 'success' ? (
        <MainLayout hideProgressBar showLoader={false}>
          <CheckEmailCard
            email={(data as LinkBasedRegisterResponse)?.identifier ?? ''}
            handleResend={handleResend}
          />
        </MainLayout>
      ) : (
        <MainLayout hideProgressBar>
          <SignupForm referrer={referrer} />
        </MainLayout>
      )}
    </>
  );
};

export default SignupPage;
